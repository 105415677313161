import { useContext, useEffect, useState } from 'react';
import { socket } from '../../../socket';
import { setupDataContext } from '../../../context';
import { BaseTeam } from '@powertrader/schema';
import { generateLink } from '../../control';
import { Button, Spin } from 'antd';
import QRCode from 'react-qr-code';

export const QuickLoginQR = () => {
  const [teamWithPasscode, setTeamWithPasscode] = useState<BaseTeam>();
  const { user, teams } = useContext(setupDataContext);
  const [showQR, setShowQR] = useState(false);
  const currentTeam = teams.find(team => team.teamID === user.teamID);

  useEffect(() => {
    if (!teamWithPasscode) {
      socket.emit('requestTeamPasscode', currentTeam, (teamWithPasscode: BaseTeam) => {
        setTeamWithPasscode(teamWithPasscode);
      });
    }
  }, [currentTeam, teamWithPasscode]);

  const qrLink = teamWithPasscode && generateLink(teamWithPasscode);

  return (
    <>
      <Button shape='round' type='primary' onClick={() => setShowQR(prev => !prev)}>
        {showQR ? 'Hide' : 'Show'} login QR
      </Button>

      {!showQR ? null : !qrLink ? (
        <Spin />
      ) : (
        <>
          <QRCode size={256} style={{ height: 'auto', maxWidth: '70%', width: '100%' }} value={qrLink} viewBox={`0 0 256 256`} />
          <p>
            <strong>Only allow your team to scan this code</strong>
          </p>
        </>
      )}
    </>
  );
};
