import { useState, useContext, memo, Fragment } from 'react';
import { Button, Select } from 'antd';
import { IErrorProps, IAsset, BaseTeam } from '@powertrader/schema';
import { socket } from '../../../../socket/socket';
import { gameProgressContext, setErrorContext, setupDataContext } from '../../../../context';

import styles from '../Footer.module.css';

const { Option } = Select;

interface IEditAssetType {
  setFooterDisplay: (value: string | undefined) => void;
  selectedTeam: BaseTeam;
  asset: IAsset;
  editAssetID: IAsset['assetID'];
}

export const EditAssetType = memo(({ setFooterDisplay, selectedTeam, asset, editAssetID }: IEditAssetType) => {
  const setError = useContext(setErrorContext);
  const [newAssetType, setNewAssetType] = useState<number>(asset.typeID);
  const { assetTypes } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const handleSaveAsset = (asset: IAsset) => {
    const assetToEdit = {
      selectedAsset: editAssetID,
      newType: newAssetType,
      selectedTeam: selectedTeam.teamID,
      operation: 'edit'
    };

    socket.emit('editAsset', assetToEdit, function (error: IErrorProps['error'], data: 'complete') {
      if (error) {
        setError({
          message: `Cannot edit asset  ${asset.label} from ${asset.teamLabel}`,
          error
        });
      } else if (data === 'complete') {
        setFooterDisplay(undefined);
        socket.emit('eventLog', {
          type: 'assets',
          log: `${asset.label} was change to a ${assetTypes.find(at => at.typeID === newAssetType)?.label}`
        });
      }
    });
  };

  return (
    <div style={{ borderColor: selectedTeam.color }} className={styles.footer}>
      <h5>{asset.label}</h5>
      <Select
        showSearch
        optionFilterProp='children'
        value={newAssetType}
        size='small'
        className={styles.typeSelect}
        dropdownClassName={styles.typeSelectDropdown}
        onChange={option => setNewAssetType(+option)}>
        <Option value='' disabled hidden>
          Change Default Type
        </Option>

        {assetTypes.map(types => {
          return (
            <Fragment key={types.typeID}>
              <Option value={types.typeID}>{types.label}</Option>
            </Fragment>
          );
        })}
      </Select>
      <Button type='primary' size='small' className={styles.button} shape='round' onClick={() => handleSaveAsset(asset)}>
        Update Type in Round {roundID}
      </Button>
      <Button type='primary' size='small' shape='round' className={styles.returnButton} onClick={() => setFooterDisplay(undefined)}>
        Return
      </Button>
    </div>
  );
});
