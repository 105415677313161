import { IGameProgress, IBooleanModalProps } from '@powertrader/schema';
import { Button, Modal } from 'antd';
import { memo } from 'react';

interface IRound2Modal {
  showRound2Modal: IBooleanModalProps['showModal'];
  setShowRound2Modal: IBooleanModalProps['setShowModal'];
  roundID: IGameProgress['roundID'];
  onOK: () => void;
}
export const Round2Modal = memo(({ showRound2Modal, setShowRound2Modal, roundID, onOK }: IRound2Modal) => {
  return (
    <Modal
      width='80%'
      visible={showRound2Modal}
      closable={false}
      maskClosable={false}
      onCancel={() => setShowRound2Modal(false)}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            setShowRound2Modal(false);
            onOK();
          }}>
          Start Level 2
        </Button>
      ]}>
      <div style={{ textAlign: 'center' }}>
        {roundID === 2 ? (
          <>
            <h2>Level 2 - Trading</h2>
            <p style={{ fontSize: '16px', margin: '20px' }}>
              Well done, you have made it to level 2. This level introduces trading, watch this short 1 minute video explination before diving in. In
              order to complete this level you must <strong>balance the system</strong> and <strong>complete 5 trades (max 10 trades)</strong>. When
              you are ready, click start below.
            </p>
          </>
        ) : (
          <h2>Watch this short video for a trading demonstration</h2>
        )}
        <iframe
          width='880'
          height='520'
          src='https://www.youtube.com/embed/f2QEfZfAfU0'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />{' '}
        <p style={{ fontSize: '14px', marginBottom: '10px' }}>
          This screen recording was made from the power trader workshop app with advanced features. <br />
          To find out more visit{' '}
          <a href='https://powertrader.online' target='_blank' rel='nofollow noreferrer'>
            powertrader.online
          </a>{' '}
          (opens in new tab)
        </p>
      </div>
    </Modal>
  );
});
