import { BaseTeam, ISettings, ITeamScore } from '@powertrader/schema';
import { Dispatch, memo, SetStateAction, useContext } from 'react';
import { allTeamAssetsContext, gameProgressContext, setupDataContext, teamRoundDataContext } from '../../../context';
import styles from './TeamSummaryCard.module.css';
import { TeamSummaryCardRow } from './TeamSummaryCardRow';
import { SummaryCardCell } from './SummaryCardCell';

interface ITeamSummaryCard {
  selectedTeamID: BaseTeam['teamID'];
  setSelectedTeamID: Dispatch<SetStateAction<number>>;
  noOfTeams: ISettings['noOfTeams'];
  teamScores: ITeamScore[];
}
export const TeamSummaryCard = memo(({ selectedTeamID, setSelectedTeamID, noOfTeams, teamScores }: ITeamSummaryCard) => {
  const allTeamAssets = useContext(allTeamAssetsContext);
  const { roundID } = useContext(gameProgressContext);
  const { teams } = useContext(setupDataContext);
  const teamRoundData = useContext(teamRoundDataContext);

  return (
    <div className={styles.teamsSummaryArea}>
      <div className={styles.innerCard}>
        <div className={styles.summaryContainer}>
          {' '}
          <SummaryCardCell header style={{ width: '100px', border: 'none' }}>
            <h5>TEAM</h5>
          </SummaryCardCell>
          <SummaryCardCell header>
            <h5>DAY</h5>
          </SummaryCardCell>
          <SummaryCardCell header>
            <h5>NIGHT</h5>
          </SummaryCardCell>
          <SummaryCardCell header style={{ paddingBottom: '8px', width: '22%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <p>BALANCE</p>
              <p>&</p>
              <p>STORAGE</p>
            </div>{' '}
            <h5>HYDROGEN</h5>
          </SummaryCardCell>
          <SummaryCardCell header style={{ paddingBottom: '8px', width: '12%' }}>
            <>
              <p>CUSTOMER</p>
              <h5>SHORT</h5>
            </>
          </SummaryCardCell>
          <SummaryCardCell header style={{ width: '13%' }}>
            <h5 style={{ fontSize: '12px' }}>GENERATION</h5>
          </SummaryCardCell>
          <SummaryCardCell header>
            <h5>PROFIT</h5>
          </SummaryCardCell>
          <SummaryCardCell header>
            <h5>C.CERTS</h5>
          </SummaryCardCell>
        </div>
      </div>

      {teams
        .filter(team => team.type !== 'ADMIN')
        .map((team, index) => {
          if (index < noOfTeams) {
            const teamRound = teamRoundData.find(tr => tr.teamID === team.teamID && tr.roundID === roundID);
            const teamRoundLR = teamRoundData.find(tr => tr.teamID === team.teamID && tr.roundID === roundID - 1);

            const strategySubmitted = () => {
              if (roundID === 1) {
                return !!(teamRound?.strapline && teamRound?.strategyStatement);
              }
              return teamRoundLR?.strapline !== teamRound?.strapline || teamRoundLR?.strategyStatement !== teamRound?.strategyStatement;
            };
            const SCteamScore = teamScores.find(ts => ts.teamID === team.teamID);
            const SCGenAssets = allTeamAssets.find(ta => ta.teamID === team.teamID)?.teamAssets.filter(ta => ta.electricityLoadType === 'generator');

            if (SCteamScore && SCGenAssets) {
              return (
                <TeamSummaryCardRow
                  key={team.teamID}
                  team={team}
                  teamScore={SCteamScore}
                  isSelectedTeam={team.teamID === selectedTeamID}
                  setSelectedTeamID={setSelectedTeamID}
                  genAssets={SCGenAssets}
                  strategyStatement={teamRound?.strategyStatement || 'No Strategy Statement Provided'}
                  strapline={teamRound?.strapline || 'No Strapline Provided'}
                  strategySubmitted={strategySubmitted()}
                />
              );
            }
          }
          return null;
        })}
    </div>
  );
});
