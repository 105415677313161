import { IBooleanModalProps } from '@powertrader/schema';
import { Button, Modal } from 'antd';
import { memo } from 'react';

interface ITradingHelpModal {
  showModal: IBooleanModalProps['showModal'];
  setShowModal: IBooleanModalProps['setShowModal'];
  onOK: () => void;
}
export const Round3Modal = memo(({ showModal, setShowModal, onOK }: ITradingHelpModal) => {
  return (
    <Modal
      width='fit-content'
      visible={showModal}
      closable={false}
      maskClosable={false}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            setShowModal(false);
            onOK();
          }}>
          Start The Clock
        </Button>
      ]}>
      <div style={{ textAlign: 'center' }}>
        <h2>Level 3 - Time, Profit, Emissions...</h2>
        <p style={{ fontSize: '16px', marginBottom: '10px', maxWidth: '800px' }}>
          Well done, you have made it to the final level. <br />
          <br />
          This level is scored with a chance to add your company onto our leaderboard, you now have an extra generator in your portfolio, click on
          "Show Details" on each asset to find out what it can do. <br /> <br />
          In order to get top of the leaderboard, maximise profit and minimise emissions in the fastest time. There are no trading limits - Good luck!
        </p>
      </div>
    </Modal>
  );
});
