import { Tooltip } from 'antd';
import { memo, useContext } from 'react';

import { Market } from '@powertrader/schema';
import styles from './LastTradedValues.module.css';
import { lastTradedPricesContext } from '../../../context';

interface ILastTradedValues {
  activeMarkets: Market[];
  currency: string;
}
const LastTradedValues = memo(({ activeMarkets, currency }: ILastTradedValues) => {
  const lastTradedPrices = useContext(lastTradedPricesContext);

  const pricesToDisplay = activeMarkets.map(market => {
    const price = lastTradedPrices.find(ltp => ltp.market.marketID === market.marketID)?.price;
    return { market, price: price || 0 };
  });

  return (
    <div className={styles.lastTradedContainer}>
      <h5>Last Traded Prices</h5>
      <table>
        <thead>
          <tr>
            {pricesToDisplay.map(ptd => (
              <th key={ptd.market.marketID}>{ptd.market.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {pricesToDisplay.map(ptd => {
              return (
                <td key={ptd.market.marketID}>
                  <Tooltip title={currency + ptd.market.priceUnits}> {ptd.price} </Tooltip>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default LastTradedValues;
