import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ISettingsDetails } from '@powertrader/schema';
import { Input, Switch } from 'antd';
import { memo } from 'react';
import styles from './GameSettingsControl.module.css';
interface IGameSettingInputType {
  setting: ISettingsDetails;
  newValue: string | number | boolean | undefined;
  locked?: boolean;
  handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>, setting: ISettingsDetails) => void;
  handleSwitchChange: (setting: ISettingsDetails, value: boolean) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const GameSettingInputType = memo(
  ({ setting, newValue, locked = false, handleOptionChange, handleSwitchChange, onBlur, disabled }: IGameSettingInputType) => {
    return (
      <>
        {setting.type !== 'boolean' && typeof newValue !== 'boolean' ? (
          <Input
            className={styles.input}
            size='small'
            disabled={locked || disabled}
            type='text'
            pattern={setting.type === 'int' ? '^[+-]?[0-9]*' : '^[+-]?[0-9]*(\\.{1})?[0-9]*'}
            autoComplete='off'
            value={newValue}
            onChange={e => handleOptionChange(e, setting)}
            onBlur={onBlur}
            style={{ backgroundColor: setting.value !== newValue ? '#c1c1c1' : 'white' }}
          />
        ) : (
          <Switch
            disabled={disabled}
            className={styles.switch}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={newValue === '1' || newValue === 'true' || newValue === 1 || newValue === true}
            onClick={c => handleSwitchChange(setting, c)}
          />
        )}
      </>
    );
  }
);
