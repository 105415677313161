import { memo } from 'react';
import styles from './TradeRowHeader.module.css';

interface ITradeRowHeader {
  backgroundColor: string;
  headings: { label: string; style?: React.CSSProperties | undefined }[];
}
export const TradeRowHeader = memo(({ backgroundColor, headings }: ITradeRowHeader) => {
  return (
    <div style={{ backgroundColor }} className={styles.summaryContainer}>
      {headings.map((heading, index) => (
        <div key={index} style={heading?.style ? heading.style : { flex: 1 }} className={styles.headingColumn}>
          <h6>{heading.label}</h6>
        </div>
      ))}
    </div>
  );
});
