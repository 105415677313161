import { memo, useState } from 'react';
import { Button } from 'antd';

import { IComment, IUser } from '@powertrader/schema';

import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { socket } from '../../socket/socket';
import styles from './ReplyInputField.module.css';

interface IReplyInputField {
  comment: IComment;

  user: IUser;
  setReplyRequired: (R: boolean) => void;
  setForceScrollToBottom: (R: 'all' | 'team' | null) => void;
  chatType: 'all' | 'team';
}

export const ReplyInputField = memo(({ comment, user, setReplyRequired, setForceScrollToBottom, chatType }: IReplyInputField) => {
  const [commentInputAlert, setCommentInputAlert] = useState('');
  const [nextReply, setNextReply] = useState('');

  const handleNextReply = (value: string) => {
    setNextReply(value);
  };

  const postReply = (originalComment: IComment) => {
    if (!nextReply.replace(/\s/g, '').length) {
      setCommentInputAlert('Reply field is empty');
    } else {
      setCommentInputAlert('');
      setReplyRequired(false);
      const replyData = {
        userID: user.userID,
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        sendingTeamID: user.role === 'ADMIN' ? -1 : user.teamID,
        recipientTeamID:
          originalComment.recipientTeamID === 0
            ? 0
            : originalComment.sendingTeamID === user.teamID
            ? originalComment.recipientTeamID
            : originalComment.sendingTeamID,
        comment: nextReply,
        type: 'reply',
        replyID: originalComment.commentID
      };

      socket.emit('nextComment', replyData); // Sent using same socket to reduce duplicate code in Server

      setForceScrollToBottom(chatType);
      setNextReply('');
    }
  };

  return (
    <form className={styles.replyContainer}>
      <TextArea
        autoFocus
        autoComplete='off'
        autoSize={{ minRows: 1, maxRows: 3 }}
        name='reply'
        onPressEnter={e => {
          if (!e.shiftKey) {
            e.preventDefault();
            postReply(comment);
          }
        }}
        value={nextReply}
        onChange={e => handleNextReply(e.target.value)}
        placeholder='Reply'
        onBlur={() => {
          setTimeout(() => {
            setReplyRequired(false);
          }, 200);
        }}
      />
      <Button ghost type='default' htmlType='submit' shape='round' onClick={() => postReply(comment)}>
        Reply
      </Button>
      <p className={styles.alert}>{commentInputAlert}</p>
    </form>
  );
});
