import { memo, useContext, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { controlDataContext, gameProgressContext, setErrorContext, setupDataContext } from '../../../context';
import styles from './RoundScenarioControl.module.css';
import { RoundScenarioRow } from './RoundScenarioRow';

export const RoundScenarioControl = memo(() => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { scenarioDefinition, settings } = useContext(setupDataContext);
  const { roundID: currentRoundID, scenarioID: currentScenarioID } = useContext(gameProgressContext);
  const { gameActions } = useContext(controlDataContext);
  const setError = useContext(setErrorContext);

  const getScenarioForRound = (roundID: number) => {
    const scenarioID = gameActions.find(
      action => action.triggerRoundID === roundID && action.entryID && action.actionUpdateType === 'scenario'
    )?.entryID;
    if (scenarioID === undefined) {
      const error = `Error getting scenario game action for round ${roundID}`;
      setError({ error, message: error });
      return;
    }
    const scenario = scenarioDefinition.find(defin => defin.scenarioID === scenarioID);

    if (scenario === undefined) {
      const error = `Error getting scenario definition for round ${roundID}`;
      setError({ error, message: error });
      return;
    }
    return scenario;
  };

  const currentRoundScenarioDefinition = scenarioDefinition.find(defin => defin.scenarioID === currentScenarioID);
  const isSetup = settings.currentGamePhase === 'setup' || settings.currentGamePhase === 'portfolio';

  return (
    <>
      <div className={styles.innerCard}>
        <div className='componentHeader'>
          <h3>Scenario Controls</h3>{' '}
          <DownOutlined style={isExpanded ? {} : { transform: 'rotate(180deg)' }} onClick={() => setIsExpanded(!isExpanded)} />
        </div>
        {isExpanded && (
          <>
            <div className={styles.headings}>
              <h5>Rounds</h5>
              <h5>Scenarios</h5>
            </div>
            {(settings.currentGamePhase === 'setup' || settings.currentGamePhase === 'portfolio') && currentRoundScenarioDefinition ? (
              <RoundScenarioRow
                roundID={0}
                preGame
                highlighted={true}
                roundScenarioDefinition={currentRoundScenarioDefinition}
                currentRoundID={currentRoundID}
                allowDelete={false}
              />
            ) : (
              ''
            )}
            {Array(settings.noOfRounds + 1)
              .fill('')
              .map((_, index) => {
                const roundScenarioDefinition = getScenarioForRound(index);
                if (roundScenarioDefinition)
                  return (
                    <RoundScenarioRow
                      key={index}
                      disabled={isSetup}
                      highlighted={!isSetup && index === currentRoundID}
                      roundID={index}
                      roundScenarioDefinition={roundScenarioDefinition}
                      currentRoundID={currentRoundID}
                      allowDelete={settings.noOfRounds === index}
                    />
                  );

                return <p>Error: Round {index} scenario not set in game flow</p>;
              })}
          </>
        )}
      </div>
    </>
  );
});
