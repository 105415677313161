import { Button, message, Modal, Popconfirm, Spin } from 'antd';
import { socket } from '../../../socket';
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { BaseTeam } from '@powertrader/schema';
import styles from './PasscodeModal.module.css';
import { CopyOutlined, LinkOutlined, ReloadOutlined } from '@ant-design/icons';
interface IPasscodeModal {
  isPasscodeModalVisible: boolean;
  setIsPasscodeModalVisible: Dispatch<SetStateAction<boolean>>;
  noOfTeams: number;
}
export const generateLink = (team: BaseTeam) => `https://${window.location.host}/?teamID=${team.teamID}&passcode=${team?.passcode}`;

export const PasscodeModal = memo(({ isPasscodeModalVisible, setIsPasscodeModalVisible, noOfTeams }: IPasscodeModal) => {
  const [teams, setTeams] = useState<BaseTeam[]>([]);

  useEffect(() => {
    if (isPasscodeModalVisible)
      socket.emit('requestPasscodes', (data: BaseTeam[]) => {
        if (data) setTeams(data);
      });
  }, [isPasscodeModalVisible]);
  useEffect(() => {
    socket.on('passcodes', (data: BaseTeam[]) => {
      if (data) setTeams(data);
    });
    return () => {
      socket.off('passcodes');
    };
  }, []);

  const handleCopyLink = (team: BaseTeam) => {
    navigator.clipboard.writeText(generateLink(team));
    message.success('Copied');
  };

  const handleCopyAllLinks = () => {
    navigator.clipboard.writeText(teams.map(generateLink).join('\n'));
    message.success('Copied');
  };
  const handleCopy = (team: BaseTeam) => {
    navigator.clipboard.writeText(`${team?.passcode}`);
    message.success('Copied');
  };

  return (
    <Modal
      title='Passcodes'
      open={isPasscodeModalVisible}
      footer={[
        <Button key='submit' type='primary' onClick={() => setIsPasscodeModalVisible(false)}>
          Close
        </Button>
      ]}
      onCancel={() => setIsPasscodeModalVisible(false)}>
      {!teams.length ? (
        <Spin size='large' />
      ) : (
        teams.map((team, i) => (
          <div key={team.teamID} className={styles.teamRow}>
            <h5 style={{ color: team.color }}>
              {team.teamID}){'  '}
              {team.label}
            </h5>
            <h5>{noOfTeams >= i + 1 ? team.type : 'NOT IN USE'}</h5>
            <h6>{team.passcode}</h6>

            <Button
              shape='round'
              size='small'
              type='primary'
              ghost
              className={styles.presetButton}
              icon={<CopyOutlined />}
              onClick={() => handleCopy(team)}
            />
            <Popconfirm
              title={`Are you sure? This cannot be reversed`}
              placement='top'
              onConfirm={() => socket.emit('randomisePasscodes', team.teamID)}
              okText='Yes'
              cancelText='No'>
              <Button shape='round' size='small' type='primary' ghost className={styles.presetButton} icon={<ReloadOutlined />} />
            </Popconfirm>
            <Button
              shape='round'
              size='small'
              type='primary'
              ghost
              className={styles.presetButton}
              icon={<LinkOutlined />}
              onClick={() => handleCopyLink(team)}
            />
          </div>
        ))
      )}
      <Popconfirm title='Are you sure? This cannot be reversed' onConfirm={() => socket.emit('randomisePasscodes')} okText='Yes' cancelText='No'>
        <Button className={styles.randomiseButton} type='primary' ghost shape='round'>
          Randomise all passcodes
        </Button>
      </Popconfirm>
      <Button className={styles.randomiseButton} type='primary' ghost shape='round' onClick={handleCopyAllLinks}>
        Copy all links
      </Button>
    </Modal>
  );
});
