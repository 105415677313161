import { useState, useEffect, useContext, memo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import styles from './ActiveAuctionDisplay.module.css';
import { setupDataContext } from '../../context';
import { Asset } from '../Asset/Asset';

export const ActiveAuctionDisplay = memo(() => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { assets, settings } = useContext(setupDataContext);
  const [auctionAssets, setAuctionAssets] = useState(assets.filter(asset => asset.inAuction === 1));

  useEffect(() => {
    setAuctionAssets(
      assets.filter(asset => asset.inAuction === 1) // updates list when assets changes - due to selling asset
    );
  }, [assets]);

  const assetSellNext = auctionAssets.find(asset => !!asset.sellNext);
  return (
    <div className={styles.innerCard}>
      <div className='componentHeader'>
        <h3>Next Auction</h3> <DownOutlined style={isExpanded ? {} : { transform: 'rotate(180deg)' }} onClick={() => setIsExpanded(!isExpanded)} />
      </div>

      <>
        {isExpanded && (
          <div className={styles.assetView}>
            {auctionAssets.length && assetSellNext ? (
              <>
                {' '}
                {settings.currentGamePhase !== 'strategy' ? (
                  <>
                    <h4>You must be in the Strategy Phase to run an auction.</h4>
                  </>
                ) : (
                  <div>
                    {!settings.isAuctionActive && <h4>Turn on Auction Active when you are ready to start.</h4>}
                    <Asset demo details='show' asset={assetSellNext} key={assetSellNext.assetID} />
                  </div>
                )}
              </>
            ) : (
              <h4>Select the {settings.currency} sign next to an asset in the auction to sell this asset next.</h4>
            )}
          </div>
        )}
      </>
    </div>
  );
});
