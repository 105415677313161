import { useState, memo, useContext } from 'react';
import { Button, message, Input } from 'antd';
import { BaseTeam } from '@powertrader/schema';
import { socket } from '../../../../socket/socket';

import styles from '../Footer.module.css';

interface INotification {
  setFooterDisplay: (value: string | undefined) => void;

  selectedTeam: BaseTeam;
}

export const Notification = memo(({ setFooterDisplay, selectedTeam }: INotification) => {
  const [notification, setNotification] = useState<string>();

  const handleNotification = (to: BaseTeam['teamID']) => {
    if (notification) {
      socket.emit(
        'controlNotification',
        {
          teamID: to,
          message: notification
        },
        function (data: string) {
          message.info(data);
        }
      );
    } else {
      socket.emit('eventLog', {
        type: 'teams',
        log: `Cannot send blank notification message`
      });
    }
    setNotification(undefined);
  };

  return (
    <div style={{ borderColor: selectedTeam.color }} className={styles.footer}>
      <div className={styles.messageContainer}>
        <Input
          placeholder={`Message Team ${selectedTeam.label}...`}
          size='small'
          value={notification}
          onPressEnter={() => handleNotification(selectedTeam.teamID)}
          onChange={event => {
            setNotification(event.target.value);
          }}
        />
        <Button
          type='primary'
          size='small'
          shape='round'
          className={styles.sendNotificaitonButton}
          onClick={() => {
            handleNotification(selectedTeam.teamID);
          }}>
          Send
        </Button>
      </div>
      <Button
        type='primary'
        size='small'
        shape='round'
        className={styles.returnButton}
        onClick={() => {
          handleNotification(0);
        }}>
        TO ALL
      </Button>
      <Button type='primary' size='small' shape='round' className={styles.returnButton} onClick={() => setFooterDisplay(undefined)}>
        Return
      </Button>
    </div>
  );
});
