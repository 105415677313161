import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { memo } from 'react';
import styles from './Targets.module.css';

interface ITargets {
  targets: { label: string; status: boolean }[];
}
export const Targets = memo(({ targets }: ITargets) => {
  return (
    <div className={styles.container}>
      {targets.map(target => (
        <div key={target.label} className={styles.targetContainer}>
          {target.status ? (
            <CheckCircleOutlined className={styles.icon} style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined className={styles.icon} style={{ color: '#e20000' }} />
          )}{' '}
          <p style={{ color: target.status ? 'green' : '#e20000' }}> {target.label}</p>
        </div>
      ))}
    </div>
  );
});
