import { memo, useMemo } from 'react';
import { round } from 'lodash';
import styles from './HydrogenStorage.module.css';
import { useHydrogenValues } from '../../hooks/useHydrogenValues';

export const HydrogenStorage = memo(() => {
  const { hydrogenValues } = useHydrogenValues();
  const { initialStored, changeInHydrogenStorage, currentStored, capacity } = hydrogenValues;

  const storageIconLevel = useMemo(() => {
    const iconNumber = round((currentStored / capacity) * 4) * 25;
    if (currentStored > 0 && iconNumber === 0) return 5;
    return iconNumber;
  }, [capacity, currentStored]);

  const percentageFull = (currentStored * 100) / capacity;
  return (
    <div className={styles.container}>
      <h3>Hydrogen Storage</h3>
      <table className={styles.storageTable}>
        <thead>
          <tr>
            <th />
            <th style={{ minWidth: '70px' }}>H₂</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Initial Stored </td>
            <td style={{ fontSize: '14px', padding: '3px 5px' }}>
              {initialStored} <span> GWh</span>
            </td>
          </tr>
          <tr>
            <td>Level Change </td>
            <td style={{ fontSize: '14px', padding: '3px 5px' }}>
              {changeInHydrogenStorage > 0 ? '+' : ''}
              {changeInHydrogenStorage} <span> GWh</span>
            </td>
          </tr>
          <tr>
            <td>Current Stored</td>
            <td style={{ fontSize: '14px', padding: '3px 5px' }}>
              <strong>{currentStored}</strong> <span> GWh</span>
            </td>
          </tr>
          <tr>
            <td>Max Capacity </td>
            <td style={{ fontSize: '14px', padding: '3px 5px' }}>
              {capacity}
              <span> GWh</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={styles.storageTank}>
        {capacity ? (
          <>
            <h3>Tank Level: {percentageFull ? `${round((currentStored * 100) / capacity)}%` : 'Tank Empty'}</h3>
            <img src={`/icons/H2_Store_${storageIconLevel}.png`} alt={`H2_Store_${storageIconLevel} Icon`} />{' '}
          </>
        ) : (
          <h3 style={{ textAlign: 'center', marginTop: '40px' }}>No Hydrogen Storage Installed</h3>
        )}
      </div>
    </div>
  );
});
