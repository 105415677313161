import { useState, useEffect, useContext, useRef, memo } from 'react';
import { debounce, isEqual } from 'lodash';
import type {
  IAsset,
  ITeamScore,
  BilateralTrade,
  ITeamRoundData,
  OpenTrade,
  ICashAndStrategy,
  IErrorProps,
  IControlDisplay
} from '@powertrader/schema';
import {
  setErrorContext,
  setupDataContext,
  allTeamAssetsContext,
  gameProgressContext,
  teamRoundDataContext,
  openTradesContext,
  controlDataContext,
  bilateralTradesContext,
  openTradesCompletedContext,
  TeamDetailedView,
  TeamSummaryCard,
  RoundScenarioControl,
  GameSettingsControl,
  DisplayController,
  EventLog,
  ErrorLog,
  ReviewPage,
  calcTeamScores,
  calcSharePrice,
  TradeTable,
  socket
} from '@powertrader/core';

import styles from './ControlArea.module.css';

const ControlArea = memo(() => {
  const { teams, settings, user, markets } = useContext(setupDataContext);
  const { scenarioID, roundID } = useContext(gameProgressContext);
  const { sharePriceFactors } = useContext(controlDataContext);
  const openTrades = useContext(openTradesContext);
  const teamRoundData = useContext(teamRoundDataContext);
  const allTeamAssets = useContext(allTeamAssetsContext);
  const gameProgress = useContext(gameProgressContext);
  const setError = useContext(setErrorContext);
  const bilateralTrades = useContext(bilateralTradesContext);
  const [allAssets, setAllAssets] = useState<IAsset[]>([]);
  const [selectedTeamID, setSelectedTeamID] = useState(teams[1]?.teamID);
  const [teamScores, setTeamScores] = useState<ITeamScore[]>();
  const [cashAndStrategy, setCashAndStrategy] = useState<ICashAndStrategy[]>();
  const [lastRoundScores, setLastRoundScores] = useState<ITeamRoundData[]>();
  const completedTrades = useContext(openTradesCompletedContext);
  const [acceptedBilateralTrades, setAcceptedBilateralTrades] = useState<BilateralTrade[]>([]);
  const [controlDisplay, setControlDisplay] = useState<IControlDisplay['props']>('controls');
  useEffect(() => {
    socket.emit('requestTeamAssets', (error: IErrorProps['error'], data: IAsset[]) => {
      if (error) {
        setError({ message: 'cannot get teamAssetsData', error });
      }
      if (data !== undefined) {
        setAllAssets(data);
      }
    });

    socket.on('updateTeamAssets', (data: IAsset[]) => {
      // console.log(data)
      if (data !== undefined) {
        setAllAssets(data);
      }
    });

    return () => {
      socket.off('updateTeamAssets');
    };
  }, [setError]);

  useEffect(() => {
    setCashAndStrategy(
      teamRoundData
        .filter(tr => tr.roundID === roundID)
        .map(tr => ({
          teamID: tr.teamID,
          cashAdjustment: tr.cashAdjustment,
          shareholderSentiment: tr.shareholderSentiment
        }))
    );

    setLastRoundScores(teamRoundData.filter(tr => tr.roundID === roundID - 1));
  }, [roundID, teamRoundData]);

  useEffect(() => {
    const filteredBilateralTrades = bilateralTrades.filter(bT => bT.status === 'accepted');
    if (!isEqual(filteredBilateralTrades, acceptedBilateralTrades)) {
      setAcceptedBilateralTrades(filteredBilateralTrades);
    }
  }, [acceptedBilateralTrades, bilateralTrades]);

  const debounceMainCalc = useRef(
    debounce(
      ({
        settings,
        scenarioID,
        roundID,
        allTeamAssets,
        cashAndStrategy,
        completedTrades,
        user,
        sharePriceFactors,
        lastRoundScores,
        bilateralTrades
      }) => {
        if (cashAndStrategy && allTeamAssets && sharePriceFactors && bilateralTrades && !settings.newGame) {
          const teamScoresResults = calcTeamScores({
            settings,
            markets,
            scenarioID,
            roundID,
            assetsToScore: allTeamAssets,
            cashAndStrategy,
            trades: completedTrades,
            bilateralTrades,
            lastRoundScores
          });

          const results = calcSharePrice({
            settings,
            teamID: user.teamID,
            teamType: user.teamType,
            roundID,
            assetsToScore: allTeamAssets,
            cashAndStrategy,
            trades: completedTrades,
            sharePriceFactors,
            lastRoundScores,
            teamScores: teamScoresResults
          });

          setTeamScores(results);
        }
      },
      250
    )
  );

  useEffect(() => {
    debounceMainCalc.current({
      settings,
      scenarioID,
      roundID,
      allTeamAssets,
      cashAndStrategy,
      completedTrades,
      user,
      sharePriceFactors,
      lastRoundScores,
      bilateralTrades: acceptedBilateralTrades
    });
  }, [
    completedTrades,
    allTeamAssets,
    cashAndStrategy,
    roundID,
    scenarioID,
    settings,
    sharePriceFactors,
    lastRoundScores,
    user.teamID,
    user.teamType,
    user,
    acceptedBilateralTrades
  ]);

  const selectedTeamScore = teamScores?.find(ts => ts.teamID === selectedTeamID);
  const selectedTeam = teams.find(team => selectedTeamID === team.teamID);

  if (settings.newGame) {
    return (
      <div className={styles.controlAreaContainer}>
        <h1>Inital Setup</h1>

        <div className={styles.setupArea}>
          <GameSettingsControl />
        </div>
      </div>
    );
  }
  const selectedTeamRoundData = teamRoundData.find(tr => tr.teamID === selectedTeamID && tr.roundID === roundID);
  if (!teamScores) {
    return null;
  }
  return (
    <div className={styles.controlAreaContainer}>
      {controlDisplay === 'review' && (
        <>
          <ReviewPage controlDisplay={controlDisplay} setControlDisplay={setControlDisplay} teamScores={teamScores} />
        </>
      )}

      {controlDisplay === 'controls' && (
        <div className={styles.controlAreaInner}>
          <div className={styles.controlColumn}>
            {selectedTeam && selectedTeamScore ? (
              <TeamDetailedView
                teamScore={selectedTeamScore}
                allAssets={allAssets}
                selectedTeam={selectedTeam}
                teamRoundData={selectedTeamRoundData}
                teamCompletedTrades={completedTrades.filter(
                  ct => ct.openTrade.offeringTeam.teamID === selectedTeamID || ct.receivingTeam.teamID === selectedTeamID
                )}
                teamCompletedBilateralTrades={bilateralTrades.filter(
                  ct => ct.offeringTeam.teamID === selectedTeamID || ct.receivingTeam?.teamID === selectedTeamID
                )}
              />
            ) : (
              <div className={styles.noTeamSelected}>
                <h2>Select a team below to see details</h2>
              </div>
            )}
            <TeamSummaryCard
              selectedTeamID={selectedTeamID}
              setSelectedTeamID={setSelectedTeamID}
              noOfTeams={settings.noOfTeams}
              teamScores={teamScores}
            />

            <div className={styles.tradeTablesContainer}>
              {markets.map(market => {
                const dealTypes: OpenTrade['dealType'][] = ['sell', 'buy'];
                return (
                  <div key={market.marketID} className={styles.tradesTable}>
                    <h3 className={styles.tradeMarketHeading}>{market.label} Market</h3>
                    {dealTypes.map(dealType => (
                      <TradeTable
                        key={dealType}
                        dealType={dealType}
                        trades={openTrades.filter(trade => trade.volumeRemaining > 0 && trade.market.marketID === market.marketID)}
                        market={market}
                        teamID={user.teamID}
                        tradeType={market.type}
                        controlView
                      />
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.controlColumn}>
            <GameSettingsControl />

            <div className={styles.logoContainer}>
              <h2>Power Trader</h2>

              <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
            </div>
            {/* <AuctionControl allAssets={allAssets} /> */}
          </div>

          <div className={styles.controlColumn}>
            <RoundScenarioControl />
          </div>
        </div>
      )}

      {controlDisplay === 'eventLog' && (
        <>
          <div className={styles.logsHeader}>
            <h2>Event & Error Logs</h2>
            <DisplayController style={{ position: 'absolute', top: '8px', right: '12px' }} onClick={setControlDisplay} round={gameProgress.roundID} />
          </div>
          <div className={styles.logs}>
            <div className={styles.column}>
              <h3>Event Log</h3>
              <EventLog />
            </div>
            <div className={styles.column}>
              <h3>Error Log</h3>
              <ErrorLog />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default ControlArea;
