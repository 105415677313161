import { Button } from 'antd';
import { gameProgressContext, Trading, socket } from '@powertrader/core';
import { IBooleanModalProps } from '@powertrader/schema';
import { memo, useContext, useEffect, useState } from 'react';
import styles from './GamePlayArea.module.css';
import { Results } from '../Results/Results';

interface ICenterStage {
  setShowModal: IBooleanModalProps['setShowModal'];
  startRound: () => void;
  roundStatus: 'started' | 'ended' | 'loading';
  showResults: boolean;
  showTip: boolean;
  setShowTip: React.Dispatch<React.SetStateAction<boolean>>;
  setDontShowTip: React.Dispatch<React.SetStateAction<boolean>>;
  maximumTradesReached: boolean;
}
export const CenterStage = memo(
  ({ setShowModal, startRound, roundStatus, showResults, showTip, setShowTip, setDontShowTip, maximumTradesReached }: ICenterStage) => {
    const [autoTraderPaused, setAutoTraderPaused] = useState(false);

    const { roundID } = useContext(gameProgressContext);

    useEffect(() => {
      socket.on('autoTraderPaused', () => {
        setAutoTraderPaused(true);
      });
      socket.on('autoTraderStarted', () => {
        setAutoTraderPaused(false);
      });
      return () => {
        socket.off('autoTraderPaused');
        socket.off('autoTraderStarted');
      };
    }, [socket]);

    const roundMessage = () => {
      if (roundStatus === 'loading')
        return (
          <h4 style={{ margin: '20px 0px' }} className={styles.descriptionText}>
            When you are ready, press start to begin.
            <br /> <br />
            <Button shape='round' className={styles.button} onClick={() => startRound()}>
              Start!
            </Button>
          </h4>
        );
      if (roundStatus === 'started') return <h4 className={styles.descriptionText}>Begin balancing the system</h4>;

      return null;
    };

    if (showTip && roundID === 1) {
      return (
        <div className={styles.centerWrapper}>
          <h1 style={{ marginTop: '5%' }} className={styles.descriptionText}>
            Tip: Show Asset Details
          </h1>
          <h4 style={{ margin: '40px 0px' }} className={styles.descriptionText}>
            Through the game it will be important to make the most of your assets, this may mean not running them at certain times. <br />
            <br />
            Click on "Show Details" at the bottom of each asset to find out how much it cost to run or what income it may generate.
          </h4>

          <Button
            shape='round'
            style={{ marginTop: '10px' }}
            className={styles.button}
            onClick={() => {
              setDontShowTip(true);
              setShowTip(false);
            }}>
            Hide Tip
          </Button>
        </div>
      );
    }
    if (roundID === 1) {
      return (
        <div className={styles.centerWrapper}>
          <h1 style={{ marginTop: '5%' }} className={styles.descriptionText}>
            Level 1 - Balancing The System
          </h1>
          <br />
          <h4 style={{ marginTop: '20px' }} className={styles.descriptionText}>
            In order to complete this level you must balance the system and be profitable. This is shown in the top dashboard. <br />
          </h4>{' '}
          <br />{' '}
          <h4 style={{ marginTop: '20px' }} className={styles.descriptionText}>
            In order to do this, first set the amount you would like to supply to your customers on the right, and then try and increase your
            generation to meet this demand.
          </h4>
          <br />
          <h4 style={{ margin: '20px 0px' }} className={styles.descriptionText}>
            When you have balanced the system, you will be able to proceed to the next level in the dashboard above (under your company name).
          </h4>
          <br />
          {roundMessage()}
        </div>
      );
    }

    if (showResults) {
      return (
        <div className={styles.centerWrapper}>
          <Results />
        </div>
      );
    }

    return (
      <div className={styles.tradingWrapper}>
        {maximumTradesReached && (
          <div className={styles.autoTraderPaused}>
            <h1 className={styles.descriptionText}>You have performed the maximum 10/10 trades.</h1>
          </div>
        )}
        {autoTraderPaused && (
          <div className={styles.autoTraderPaused}>
            <h1>Auto trading paused due to inactivity</h1>
            <br />
            <Button
              shape='round'
              style={{ marginTop: '10px' }}
              className={styles.button}
              onClick={() => {
                socket.emit('stillhere');
              }}>
              Resume Trading
            </Button>
          </div>
        )}

        <Trading setShowTradingHelpModal={setShowModal} />
      </div>
    );
  }
);
