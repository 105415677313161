import { BaseTeam, ISettings, IControlData, ITeamRoundDataToDisplay } from '@powertrader/schema';
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js';

export function lineChartOptions(display: 'sharePrice' | 'emissions' | 'carbonCertificates' | 'noOfTrades'): ChartOptions {
  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: { font: { size: 14 } }
      }
    },

    scales: {
      y: {
        title: {
          display: true,
          text:
            display === 'sharePrice'
              ? 'Share Price'
              : display === 'emissions'
              ? 'Emissions (kt.CO2)'
              : display === 'noOfTrades'
              ? 'Trade Score'
              : 'Carbon Certificates (kt.CO2)',

          font: {
            size: 16
          }
        },
        display: true,
        ticks: {
          font: {
            size: 16
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 16
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      }
    }
  };
  return options;
}
interface ILineChartData {
  teams: BaseTeam[];
  display: 'sharePrice' | 'emissions' | 'carbonCertificates' | 'noOfTrades';
  startingSharePrice: ISettings['startingSharePrice'];
  teamRoundDataToDisplay: ITeamRoundDataToDisplay[];
  noOfRounds: ISettings['noOfRounds'];
}
export function lineChartData({ teams, display = 'sharePrice', startingSharePrice, teamRoundDataToDisplay, noOfRounds }: ILineChartData): ChartData {
  const results: ChartDataset[] = [];
  let startingValue = 0;
  if (display === 'sharePrice') startingValue = startingSharePrice;

  for (const team of teams) {
    if (team.type === 'PLAYER') {
      const dataToPlot = teamRoundDataToDisplay.filter(trd => trd.teamID === team.teamID);
      results.push(datasets({ team, dataToPlot, display, startingValue }));
    }
  }

  return {
    labels: [
      'Start',
      ...Array(noOfRounds)
        .fill('')
        .map((_, index) => `Round ${index + 1}`)
    ],
    datasets: results
  };
}

interface IDatasets {
  team: BaseTeam;
  dataToPlot: ITeamRoundDataToDisplay[];
  display: 'sharePrice' | 'emissions' | 'carbonCertificates' | 'noOfTrades';
  startingValue: number;
}
const datasets = ({ team, dataToPlot, display, startingValue }: IDatasets): ChartDataset => {
  return {
    label: team.label,
    fill: false,
    tension: 0.4,
    backgroundColor: team.color,
    borderColor: team.color,
    data: [startingValue, ...dataToPlot.map(dtp => dtp[display])],
    type: 'line'
  };
};
