import { useState, useContext, useMemo, useCallback, memo, Fragment } from 'react';
import { IBooleanModalProps, INewTradeReq, Market } from '@powertrader/schema';
import { Button } from 'antd';
import styles from './Trading.module.css';
import { MyTrades } from './MyTrades';
import { TradeRequestForm } from './TradeRequestForm/TradeRequestForm';
import { TradeTable } from './TradeTable/TradeTable';
import { setupDataContext, openTradesContext, bilateralTradesContext, openTradesCompletedContext, gameProgressContext } from '../../context';
import { HydrogenStorage } from '../HydrogenStorage/HydrogenStorage';

interface ITrading {
  setShowTradingHelpModal: IBooleanModalProps['setShowModal'];
  defaultReqType?: INewTradeReq['type'];
}
export const Trading = memo(({ setShowTradingHelpModal, defaultReqType }: ITrading) => {
  const { user, markets, settings, teams } = useContext(setupDataContext);
  const bilateralTrades = useContext(bilateralTradesContext);

  const openTrades = useContext(openTradesContext);
  const openTradesCompleted = useContext(openTradesCompletedContext);
  const [teamTradesOnly, setTeamTradesOnly] = useState(false);
  const [tradeType, setTradeType] = useState<INewTradeReq['tradeType']>('powerExchange');
  const { roundID } = useContext(gameProgressContext);
  const [newTradeReq, setNewTradeReq] = useState<INewTradeReq>({
    offeringTeamID: user.teamID,
    tradeType,
    receivingTeamID: 0,
    type: defaultReqType || 'OpenTrade',
    marketID: 1,
    dealType: 'sell',
    volume: 1,
    price: '',
    startRoundID: roundID,
    endRoundID: roundID
  });

  const activeMarkets = useMemo(
    () =>
      markets.reduce((result: Market[], current) => {
        if (current.type === 'powerExchange') return [...result, current];
        if (current.type === 'carbonMarket' && settings.allowCarbonTrading) return [...result, current];
        if (current.type === 'hydrogenMarket' && settings.allowHydrogenTrading) return [...result, current];

        return result;
      }, []),
    [markets, settings.allowCarbonTrading, settings.allowHydrogenTrading]
  );

  const displayMarkets = useMemo(
    () =>
      activeMarkets.reduce((result: Market[], current) => {
        if (tradeType === 'powerExchange' && current.type === 'powerExchange') return [...result, current];
        if (tradeType === 'carbonMarket' && current.type === 'carbonMarket') return [...result, current];
        if (tradeType === 'hydrogenMarket' && current.type === 'hydrogenMarket') return [...result, current];

        return result;
      }, []),
    [activeMarkets, tradeType]
  );

  const setTradeDestination = useCallback(
    (marketID: INewTradeReq['marketID'], dealType: INewTradeReq['dealType']) => {
      setNewTradeReq(existingTradeRequest => ({
        ...existingTradeRequest,
        startRoundID: roundID,
        endRoundID: roundID,
        marketID,
        dealType
      }));
    },
    [roundID]
  );

  const teamBilateralTrades = bilateralTrades.filter(bT => bT.offeringTeam.teamID === user.teamID || bT.receivingTeam?.teamID === user.teamID);
  const dealTypes: ('sell' | 'buy')[] = ['sell', 'buy'];
  return (
    <div className={styles.innerContainer}>
      {!!settings.allowTrading && settings.currentGamePhase === 'operational' && (
        <Button type='text' className={styles.helpButton} onClick={() => setShowTradingHelpModal(true)}>
          Trading tutorial
        </Button>
      )}
      <h2 className={styles.sectionTitle}>{displayMarkets[0].typeLabel}</h2>
      <div className={styles.tradingArea}>
        <div className={styles.tradeRequestContainer}>
          <TradeRequestForm
            teamTradesOnly={teamTradesOnly}
            setTeamTradesOnly={setTeamTradesOnly}
            activeMarkets={activeMarkets}
            setTradeType={setTradeType}
            tradeType={tradeType}
            newTradeReq={newTradeReq}
            setNewTradeReq={setNewTradeReq}
          />
        </div>
        {displayMarkets.map(market => {
          return (
            <Fragment key={market.marketID}>
              <div className={styles.tradesTable}>
                <h4 className={styles.heading}>{displayMarkets.length !== 1 && `${market.label} Market`}</h4>

                {dealTypes.map(dealType => (
                  <TradeTable
                    key={dealType}
                    dealType={dealType}
                    trades={
                      newTradeReq.type === 'BilateralTrade'
                        ? teamBilateralTrades.filter(bT => bT.market.marketID === market.marketID && bT.status === 'offered')
                        : openTrades.filter(trade => trade.volumeRemaining > 0 && trade.market.marketID === market.marketID)
                    }
                    market={market}
                    teamID={user.teamID}
                    teamTradesOnly={teamTradesOnly}
                    tradeType={tradeType}
                    highlighted={newTradeReq.dealType === dealType && newTradeReq.marketID === market.marketID}
                    bilateralsView={newTradeReq.type === 'BilateralTrade'}
                    setTradeDestination={setTradeDestination}
                  />
                ))}
              </div>
              {market.type === 'hydrogenMarket' && <HydrogenStorage />}
            </Fragment>
          );
        })}
      </div>
      <div className={styles.myTradesContainer}>
        <h4 className={styles.heading}>
          {teams.find(t => t.teamID === user.teamID)?.label}s Completed{' '}
          {tradeType === 'carbonMarket' ? 'Carbon ' : tradeType === 'hydrogenMarket' ? 'Hydrogen ' : ''}
          Trades
        </h4>
        <div className={styles.myTradeMarkets}>
          {displayMarkets.map(market => (
            <div
              key={market.marketID}
              style={displayMarkets.length === 1 ? { width: '60%' } : { width: `${100 / displayMarkets.length}%` }}
              className={styles.myTradesMarket}>
              <MyTrades
                tradeType={tradeType}
                displayMarket={market}
                completedTrades={
                  newTradeReq.type === 'BilateralTrade'
                    ? teamBilateralTrades.filter(trade => trade.status === 'accepted' && trade.market.marketID === market.marketID)
                    : openTradesCompleted.filter(trade => trade.openTrade.market.marketID === market.marketID)
                }
                teamID={user.teamID}
                bilateralView={newTradeReq.type === 'BilateralTrade'}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
