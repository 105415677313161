import { round } from 'lodash';
import { BaseTeam, ISharePriceFactors, ITeamScore } from '@powertrader/schema';
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js';

export function barChartOptions(): ChartOptions {
  return {
    responsive: true,
    maintainAspectRatio: true,

    plugins: {
      legend: {
        display: true,
        position: 'top',

        labels: {
          font: {
            size: 16
          }
        }
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Percentage Growth',

          font: {
            size: 16
          }
        },
        stacked: true,
        ticks: {
          font: {
            size: 16
          }
        }
      },
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 16
          },

          maxRotation: 0,
          minRotation: 0
        }
      }
    }
  };
}
interface IBarChartData {
  teams: BaseTeam[];
  teamScores: ITeamScore[];
  sharePriceFactors: ISharePriceFactors[];
}

export function barChartData({ teams, teamScores, sharePriceFactors }: IBarChartData): ChartData {
  const results: ChartDataset[] = [];

  const displayTeams = teams.filter(t => t.type === 'PLAYER');

  for (const factor of sharePriceFactors) {
    results.push({
      label: factor.displayLabel,
      backgroundColor: factor.color,

      data: teamScores.map(ts => round(ts.sharePrice[factor.variableName] * 100))
    });
  }
  const shareholderSentiment = teamScores.map(ts => round(ts.sharePrice.shareholderSentiment * 100));
  results.push({
    label: 'Shareholders Sentiment',
    backgroundColor: 'green',
    data: shareholderSentiment
  });

  return {
    labels: displayTeams.map(t => t.label),
    datasets: results
  };
}
