import { useState, memo, useEffect } from 'react';
import styles from './Stopwatch.module.css';

interface IStopwatch {
  startTime?: Date;
}
export const Stopwatch = memo(({ startTime }: IStopwatch) => {
  // startTime = Wed Feb 03 2021 18:21:06 GMT+0000 (Greenwich Mean Time)

  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    if (startTime) {
      const myInterval = setInterval(() => {
        const timeNow = new Date().getTime();
        const startTimeMiliSeconds = new Date(startTime).getTime();
        const timePasted = timeNow - startTimeMiliSeconds;
        setMinutes(Math.floor(timePasted / 1000 / 60));
        setSeconds(Math.floor((timePasted / 1000) % 60));
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
    return () => {};
  }, [startTime]);

  return (
    <div className={styles.innerCard}>
      <h2>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </h2>
    </div>
  );
});
