import { useState, useEffect, useContext } from 'react';

import moment from 'moment';
import { message } from 'antd';
import { IErrorProps, IEventProps } from '@powertrader/schema';
import { socket } from '../../socket/socket';
import { setErrorContext } from '../../context';

import styles from './EventLog.module.css';

export const EventLog = () => {
  const [eventLog, setEventLog] = useState<IEventProps[]>([]);

  const setError = useContext(setErrorContext);

  useEffect(() => {
    socket.emit('requestEventLog', (error: IErrorProps['error'], data: IEventProps[]) => {
      if (error) {
        setError({ message: 'cannot get eventLog', error });
      }
      if (data !== undefined) {
        setEventLog(data);
      }
    });

    socket.on('updateEventLog', (data: IEventProps) => {
      message.info(data.log);
      if (data !== undefined) {
        setEventLog(prevState => {
          return [...prevState, data];
        });
      }
    });

    return () => {
      socket.off('updateEventLog');
    };
  }, [setError, socket]);

  const sortedEventLog = eventLog.sort((a, b) => (b?.timeStamp || 0) - (a?.timeStamp || 0));
  return (
    <div className={styles.innerCard}>
      {sortedEventLog.map((log, i) => {
        return (
          <p key={i}>
            {moment(+(log?.timeStamp || 0)).format('YYYY-MM-DD HH:mm:ss')} - {log.log}
          </p>
        );
      })}
    </div>
  );
};
