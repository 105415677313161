import { IAsset, ISettings } from '@powertrader/schema';
import { Tooltip } from 'antd';
import styles from './Asset.module.css';

interface IDetailElement {
  label: string;
  value: string;
}
const DetailElement = ({ label, value }: IDetailElement) => (
  <>
    <p>
      <strong>{label}</strong>
    </p>
    <p>{value}</p>
  </>
);

interface IShowAssetDetails {
  electricityLoadType: IAsset['electricityLoadType'];
  hydrogenLoadType: IAsset['hydrogenLoadType'];
  notes: IAsset['notes'];
  currency: ISettings['currency'];
  purchaseCost: IAsset['purchaseCost'];
  fixedCost: IAsset['fixedCost'];
  roundPeriod: ISettings['roundPeriod'];
  runningCost: IAsset['runningCost'];
  subsidyIncome: IAsset['subsidyIncome'];
  carbonIntensity: IAsset['carbonIntensity'];
  electricityRunningTariff: IAsset['electricityRunningTariff'];
  hydrogenRunningTariff: IAsset['hydrogenRunningTariff'];
  conversionEfficiency: IAsset['conversionEfficiency'];
  allowVisibleCostDetails: ISettings['allowVisibleCostDetails'];
}

export const ShowAssetDetails = ({
  electricityLoadType,
  hydrogenLoadType,
  notes,
  currency,
  purchaseCost,
  fixedCost,
  roundPeriod,
  runningCost,
  subsidyIncome,
  carbonIntensity,
  electricityRunningTariff,
  hydrogenRunningTariff,
  conversionEfficiency,
  allowVisibleCostDetails
}: IShowAssetDetails) => {
  const isGen = electricityLoadType === 'generator';
  const isCust = electricityLoadType === 'customer';
  const isCons = hydrogenLoadType === 'consumer';
  const isProd = hydrogenLoadType === 'producer';

  return (
    <>
      <p className={styles.notes}>{notes}</p>
      <div className={styles.extraInfo}>
        {isCust && !isProd && (
          <div>
            <DetailElement
              label='Tariff'
              value={`${currency}
              ${allowVisibleCostDetails ? electricityRunningTariff || 0 : '??'} k/GWh`}
            />
            <DetailElement label='Pollution Intensity' value={`${carbonIntensity || 0} kt.CO₂/GWh`} />
          </div>
        )}
        <div>
          <DetailElement
            label='Fixed Cost'
            value={`${currency}
            ${(purchaseCost || 0) + (fixedCost || 0)} k/
           ${roundPeriod}`}
          />
          {(isGen || (isProd && !isCust)) && (
            <DetailElement
              label='Running Cost'
              value={`${currency}
              ${allowVisibleCostDetails ? runningCost || 0 : '??'} k/GWh`}
            />
          )}
          {isCons && isCust && (
            <DetailElement
              label='H₂ Tariff'
              value={`${currency}
              ${allowVisibleCostDetails ? hydrogenRunningTariff || 0 : '??'} k/GWh`}
            />
          )}
        </div>

        {isGen && (
          <div>
            <DetailElement
              label='Subsidy'
              value={`${currency}
             ${subsidyIncome || 0} k/GWh`}
            />
            <DetailElement label='Pollution Intensity' value={`${carbonIntensity || 0} kt.CO₂/GWh`} />
          </div>
        )}
      </div>
      {((isProd && isCust) || (isCons && isGen)) && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DetailElement label='Coversion Efficiency' value={`${(conversionEfficiency || 0) * 100}%`} />
        </div>
      )}
    </>
  );
};
