import { useState, useContext, memo } from 'react';
import { IErrorProps, IUpdateSharePriceFactor, ISharePriceEditingKey } from '@powertrader/schema';
import { DownOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import { socket } from '../../../socket/socket';
import { controlDataContext, setErrorContext, setupDataContext } from '../../../context';

import styles from './SharePriceControl.module.css';
import { SharePriceAdjustmentInterface } from './SharePriceAdjustmentInterface';

export const SharePriceControl = memo(() => {
  const { settings } = useContext(setupDataContext);
  const { sharePriceFactors } = useContext(controlDataContext);
  const setError = useContext(setErrorContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaved, setIsSaved] = useState<boolean | undefined>();

  const handleSave = (editingKey: ISharePriceEditingKey, editingValue: string | undefined) => {
    if (editingKey.baseLimitsID === null || editingKey.key === null) {
      // message.error('Value not changed');
      return;
    }
    const updateSharePriceFactor: IUpdateSharePriceFactor = {
      baseLimitsID: editingKey.baseLimitsID,
      keyToUpdate: editingKey.key,
      value: editingValue !== null && editingValue !== undefined ? parseFloat(editingValue) : 0
    };
    if (isNaN(updateSharePriceFactor.value)) {
      message.info('Share price factor must be a number');
      return;
    }
    socket.emit('updateSharePrice', updateSharePriceFactor, (error: IErrorProps['error'], data: string) => {
      if (error) {
        setError({ message: 'Cannot update share price factors', error });
      }
      if (data === 'complete') {
        setIsSaved(true);
      }
    });
  };

  return (
    <div className={styles.innerCard}>
      <div className='componentHeader'>
        <h3>Share Price Factors</h3>
        <DownOutlined style={isExpanded ? {} : { transform: 'rotate(180deg)' }} onClick={() => setIsExpanded(!isExpanded)} />
      </div>
      {isExpanded && (
        <Tooltip
          visible={settings.currentGamePhase !== 'review' ? false : undefined}
          placement='top'
          color='red'
          title='Share price factors should only be changed in the strategy phase'>
          <SharePriceAdjustmentInterface sharePriceFactors={sharePriceFactors} handleSave={handleSave} />
        </Tooltip>
      )}
    </div>
  );
});
