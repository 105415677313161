import { FunctionComponent, memo } from 'react';
import { getColorToDisplay, IGetColorToDisplay } from '../../../calculations/getFunctions';
import styles from './TeamSummaryCard.module.css';

interface ISummaryCardCell {
  style?: React.CSSProperties;
  colorToDisplay?: IGetColorToDisplay;
  header?: boolean;
}

export const SummaryCardCell: FunctionComponent<ISummaryCardCell> = memo(({ children, style, colorToDisplay, header }) => {
  const color = colorToDisplay ? getColorToDisplay(colorToDisplay) : {};
  return (
    <div style={{ ...color, ...style }} className={header ? styles.headingColumn : styles.column}>
      {header ? children : <p>{children}</p>}
    </div>
  );
});
