import { memo, useContext, useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { IRoundScenarios, IScenarioDefinition, IErrorProps, IGameProgress, ISettingsDetails, ISettings } from '@powertrader/schema';

import { socket } from '../../../socket/socket';
import { setErrorContext, setupDataContext } from '../../../context';

import styles from './RoundScenarioRow.module.css';
import { ScenarioSelect } from './ScenarioSelect';

interface IRoundScenarioRow {
  roundID: IGameProgress['roundID'];
  roundScenarioDefinition: IScenarioDefinition;
  currentRoundID: IGameProgress['roundID'];
  allowDelete: boolean;
  preGame?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
}
export const RoundScenarioRow = memo(
  ({ roundID, currentRoundID, roundScenarioDefinition, allowDelete, preGame, disabled, highlighted }: IRoundScenarioRow) => {
    const setError = useContext(setErrorContext);
    const { settingDetails, settings } = useContext(setupDataContext);
    const scenarioSetting = settingDetails.find(sd => sd.name === 'currentScenario');

    const handleScenarioChange = (newValue: number) => {
      const update = [
        {
          ...scenarioSetting,

          value: newValue
        }
      ];
      socket.emit('updateSettings', update, function (error: IErrorProps['error'], data: 'complete') {
        if (error) {
          setError({
            message: `Failed changing current scenario from control screen`,
            error
          });
        } else if (data === 'complete') {
          message.success(`Current Scenario Updated`);
          socket.emit('eventLog', {
            type: 'system',
            log: `Current scenario updated to ${newValue}`
          });
        }
      });
    };

    return (
      <div className={styles.row}>
        <h6 className={styles.round} style={highlighted ? { color: 'green', fontWeight: 'bold' } : roundID < currentRoundID ? { color: 'grey' } : {}}>
          {preGame ? 'Portfolio' : roundID === 0 ? 'Demo' : roundID}
        </h6>
        {highlighted && scenarioSetting?.value && !disabled ? (
          <ScenarioSelect
            className={styles.scenario}
            scenario={+scenarioSetting.value}
            handleScenarioChange={handleScenarioChange}
            disabled={settings.currentGamePhase === 'review'}
          />
        ) : (
          <h6
            className={styles.scenario}
            style={highlighted ? { color: 'green', fontWeight: 'bold' } : roundID < currentRoundID ? { color: 'grey' } : {}}>
            {roundScenarioDefinition.label}
          </h6>
        )}
      </div>
    );
  }
);
