import { Button, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { memo, useContext, useState } from 'react';

import { IUser, BaseTeam } from '@powertrader/schema';
import { socket } from '../../socket/socket';
import styles from './InputArea.module.css';

const { Option } = Select;
interface IInputArea {
  user: IUser;

  teams: BaseTeam[];
  chatType: 'all' | 'team';
  setForceScrollToBottom: (R: 'team' | 'all' | null) => void;
}
export const InputArea = memo(({ user, teams, chatType, setForceScrollToBottom }: IInputArea) => {
  const [nextComment, setNextComment] = useState('');
  const [recipient, setRecipient] = useState(user.teamID);
  const [commentInputAlert, setCommentInputAlert] = useState('');

  const postComment = () => {
    if (!nextComment.replace(/\s/g, '').length) {
      setCommentInputAlert('Comment field is empty');
    } else {
      setCommentInputAlert('');

      const commentData = {
        userID: user.userID,
        sendingTeamID: user.teamID,
        recipientTeamID: chatType === 'team' ? recipient : 0,
        comment: nextComment,
        type: 'comment'
      };
      socket.emit('nextComment', commentData);
      setNextComment('');
      setForceScrollToBottom(chatType);
    }
  };

  const handleNextComment = (value: string) => {
    setCommentInputAlert('');
    setNextComment(value);
  };

  const Send = () => (
    <Button
      ghost
      type='default'
      shape='round'
      onClick={event => {
        event.preventDefault();
        postComment();
      }}
      htmlType='submit'>
      Send
    </Button>
  );

  const team = teams.find(team => user.teamID === Number(team.teamID));
  return (
    <form id='chat'>
      <div className={styles.inputRow}>
        <TextArea
          autoComplete='off'
          autoSize={{ minRows: 1, maxRows: 3 }}
          onPressEnter={e => {
            if (!e.shiftKey) {
              e.preventDefault();
              postComment();
            }
          }}
          value={nextComment}
          onChange={event => handleNextComment(event.target.value)}
          placeholder={
            user.role === 'ADMIN' && team?.type !== 'ADMIN'
              ? chatType === 'team'
                ? `Private Message as ${team?.label}`
                : `Public Message as ${team?.label}`
              : chatType === 'team'
              ? 'Private Message'
              : 'Public Message'
          }
        />
        {chatType === 'all' && <Send />}
      </div>
      <div className={styles.inputRow}>
        {chatType === 'team' && (
          <Select
            showSearch
            placeholder='Select a Team'
            optionFilterProp='children'
            className={styles.recipientSelect}
            value={recipient}
            onChange={option => setRecipient(Number(option))}>
            {teams.map(team => (
              <Option key={team.teamID} value={team.teamID}>
                {team.label}
              </Option>
            ))}
          </Select>
        )}

        {chatType === 'team' && <Send />}
      </div>

      <p className={styles.alert}>{commentInputAlert}</p>
    </form>
  );
});
