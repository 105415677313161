import { useContext, memo } from 'react';
import { ITeamRoundDataToDisplay } from '@powertrader/schema';
import { round } from 'lodash';
import styles from './ReviewTable.module.css';

import { setupDataContext, controlDataContext } from '../../context';

interface IReviewTable {
  thisRoundScores: ITeamRoundDataToDisplay[];
  teamRoundDataToDisplay: ITeamRoundDataToDisplay[];
  display: 'sharePrice' | 'emissions' | 'carbonCertificates' | 'noOfTrades';
}

export const ReviewTable = memo(({ thisRoundScores, teamRoundDataToDisplay, display }: IReviewTable) => {
  const { teams, settings } = useContext(setupDataContext);

  let sortedTeams = thisRoundScores
    .sort((a, b) => b[display] - a[display])
    .map(trd => ({
      teamID: trd.teamID,
      label: teams.find(t => t.teamID === trd.teamID)?.label
    }));

  if (display === 'emissions') {
    sortedTeams = sortedTeams.reverse();
  }

  const rank = [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    '13th',
    '14th',
    '15th',
    '16th',
    '17th',
    '18th',
    '19th',
    '20th',
    '21st',
    '22nd',
    '23rd',
    '24th'
  ];
  return (
    <div className={styles.reviewPageContainer}>
      <table className={styles.balanceTable}>
        <thead className={styles.header}>
          <tr>
            <th />
            {sortedTeams.map((t, i) => (
              <th key={i}>
                <p className={styles.rank}>{rank[i]}</p> {t.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {display === 'sharePrice' && (
            <tr>
              <td>Start</td>
              {sortedTeams.map((t, i) => (
                <td key={i}> {settings.startingSharePrice}</td>
              ))}
            </tr>
          )}
          {Array(settings.noOfRounds)
            .fill('')
            .map(
              (_, i) =>
                i + 1 <= settings.currentRound && (
                  <tr key={i + 1}>
                    <td>Round {i + 1}</td>
                    {sortedTeams.map(t => (
                      <td key={t.teamID}>
                        {round(teamRoundDataToDisplay.find(trd => trd.roundID === i + 1 && trd.teamID === t.teamID)?.[display] || 0, 2)}
                      </td>
                    ))}
                  </tr>
                )
            )}
        </tbody>
      </table>
    </div>
  );
});
