import { memo, useContext, useMemo, useState } from 'react';

import { IAsset, IAssetType } from '@powertrader/schema';

import { gameProgressContext, setupDataContext } from '../../context';
import styles from './Asset.module.css';
import { ShowAssetDetails } from './ShowAssetDetails';
import { AssetControls } from './AssetControls';

interface IDetailedView {
  asset: IAsset | IAssetType;
  details?: 'show' | 'block';
  demo?: boolean;
  disabled?: boolean;
}

export const DetailedView = memo(({ asset, details, demo = false, disabled }: IDetailedView) => {
  const { markets, settings } = useContext(setupDataContext);
  const { currency } = settings;
  const [showDetails, setShowDetails] = useState(details === 'show');

  const {
    label,
    icon,
    typeID,
    color,
    carbonIntensity,
    runningCost,
    electricityRunningTariff,
    hydrogenRunningTariff,
    conversionEfficiency,
    subsidyIncome,
    fixedCost,
    notes,
    electricityLoadType,
    hydrogenLoadType
  } = asset;

  const purchaseCost = 'purchaseCost' in asset ? asset.purchaseCost : 0;
  const typeLabel = 'typeLabel' in asset ? asset.typeLabel : undefined;

  const gameProgress = useContext(gameProgressContext);

  const scenarioID = !!settings.allowStrategyInput || !!settings.isAuctionActive ? 1 : gameProgress.scenarioID;
  const energyMarkets = useMemo(() => markets.filter(market => market.type === 'powerExchange'), [markets]);
  const hydrogenMarkets = useMemo(() => markets.filter(market => market.type === 'hydrogenMarket'), [markets]);

  return (
    <div className={styles.detailedView}>
      <div className={styles.header}>
        <div className={styles.headingInfo}>
          <h4>{label}</h4>
          <h5>{typeLabel}</h5>
        </div>
        <div className={styles.icon}>
          <img src={`/icons/${icon}`} alt={`${typeID} Icon`} />
        </div>
      </div>
      <AssetControls asset={asset} markets={energyMarkets} demo={demo} scenarioID={scenarioID} disabled={disabled} displayMaxMinLoad />
      {hydrogenLoadType && (
        <AssetControls
          asset={asset}
          markets={hydrogenMarkets}
          demo={demo}
          scenarioID={scenarioID}
          disabled={(hydrogenLoadType === 'producer' && electricityLoadType !== null) || disabled}
          displayMaxMinLoad={false}
        />
      )}

      <div className={styles.spacer}>
        {showDetails && <hr style={{ borderColor: color, opacity: '70%' }} />}
        {details !== 'block' && (
          <p className={styles.showDetailsText} onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? 'Hide' : 'Show'} Details
          </p>
        )}
        {showDetails && <hr style={{ borderColor: color, opacity: '70%' }} />}
      </div>
      {showDetails && (
        <ShowAssetDetails
          electricityLoadType={electricityLoadType}
          hydrogenLoadType={hydrogenLoadType}
          notes={notes}
          currency={currency}
          purchaseCost={purchaseCost}
          fixedCost={fixedCost}
          roundPeriod={settings.roundPeriod}
          allowVisibleCostDetails={settings.allowVisibleCostDetails}
          runningCost={runningCost}
          subsidyIncome={subsidyIncome}
          carbonIntensity={carbonIntensity}
          electricityRunningTariff={electricityRunningTariff}
          hydrogenRunningTariff={hydrogenRunningTariff}
          conversionEfficiency={conversionEfficiency}
        />
      )}
    </div>
  );
});
