/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext } from 'react';

import {
  BilateralTrade,
  IAllTeamAssets,
  IAsset,
  IControlData,
  IGameProgress,
  ILastTradedPrice,
  ISetError,
  ISetupData,
  ITeamRoundData,
  OpenTrade,
  OpenTradeComplete
} from '@powertrader/schema';
import type { Socket } from 'socket.io-client';

export const socketContext = createContext<Socket>(undefined!);
export const setErrorContext = createContext<ISetError['setError']>(undefined!);
export const setupDataContext = createContext<ISetupData>(undefined!);
export const teamAssetsContext = createContext<IAsset[]>(undefined!);
export const bilateralTradesContext = createContext<BilateralTrade[]>(undefined!);

export const allTeamAssetsContext = createContext<IAllTeamAssets[]>(undefined!);
export const gameProgressContext = createContext<IGameProgress>(undefined!);
export const controlDataContext = createContext<IControlData>(undefined!);
export const teamRoundDataContext = createContext<ITeamRoundData[]>(undefined!);
export const openTradesContext = createContext<OpenTrade[]>(undefined!);
export const openTradesCompletedContext = createContext<OpenTradeComplete[]>(undefined!);
export const lastTradedPricesContext = createContext<ILastTradedPrice[]>(undefined!);
