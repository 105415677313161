import { useState, useContext, memo, useMemo } from 'react';
import { Line, Bar, Chart } from 'react-chartjs-2';
import { Select } from 'antd';
import { round } from 'lodash';
import { ITeamScore, ITeamRoundDataToDisplay, IControlDisplay } from '@powertrader/schema';

import type { ChartData, ChartOptions } from 'chart.js';
import { setupDataContext, teamRoundDataContext, controlDataContext } from '../../context';
import { DisplayController } from '../control/DisplayController/DisplayController';
import styles from './ReviewPage.module.css';
import { lineChartOptions, lineChartData } from './lineChartData';
import { barChartOptions, barChartData } from './barChartData';

import { ReviewTable } from './ReviewTable';
import { SharePriceTable } from './SharePriceTable';

import { TradeChart } from './TradeChart';

const { Option } = Select;

interface IReviewPage {
  controlDisplay: IControlDisplay['props'];
  setControlDisplay: IControlDisplay['setControlDisplay'];
  teamScores: ITeamScore[];
}

export const ReviewPage = memo(({ controlDisplay, setControlDisplay, teamScores }: IReviewPage) => {
  const teamRoundData = useContext(teamRoundDataContext);
  const { teams, settings } = useContext(setupDataContext);
  // const energyMarkets = useMemo(() => markets.filter(market => market.type === 'energy'), [
  //   markets
  // ])

  const { sharePriceFactors } = useContext(controlDataContext);
  const [display, setDisplay] = useState<'sharePrice' | 'emissions' | 'noOfTrades' | 'carbonCertificates'>('sharePrice');

  const teamRoundDataToDisplay: ITeamRoundDataToDisplay[] = teamRoundData.filter(
    trd => trd.roundID !== settings.currentRound && trd.teamType === 'PLAYER'
  );

  const thisRoundScores = teamScores
    .filter(ts => ts.teamType === 'PLAYER')
    .map(thisTeamScores => ({
      teamID: thisTeamScores.teamID,
      roundID: settings.currentRound,
      sharePrice: round(thisTeamScores.sharePrice.thisRoundSharePrice),
      emissions: thisTeamScores.totals.emissions,
      carbonCertificates: round(thisTeamScores.totals.carbonCertificates, 2),
      noOfTrades: thisTeamScores.totals.noOfTrades
    }));
  teamRoundDataToDisplay.push(...thisRoundScores);

  const lineChartDatasets: ChartData = useMemo(
    () =>
      lineChartData({
        teams,
        display,
        startingSharePrice: settings.startingSharePrice,
        teamRoundDataToDisplay,
        noOfRounds: settings.noOfRounds
      }),
    [display, settings.noOfRounds, settings.startingSharePrice, teamRoundDataToDisplay, teams]
  );
  const lineChartOptionsMemo: ChartOptions = useMemo(() => lineChartOptions(display), [display]);
  const barChartOptionsMemo: ChartOptions = useMemo(() => barChartOptions(), []);
  const barChartDatasets: ChartData = useMemo(
    () =>
      barChartData({
        teams,
        teamScores: teamScores.filter(ts => ts.teamType === 'PLAYER'),
        sharePriceFactors
      }),
    [sharePriceFactors, teamScores, teams]
  );

  return (
    <div className={styles.reviewPageContainer}>
      <div className={styles.header}>
        {' '}
        <h2>End of Round Review</h2>
        <DisplayController style={{ position: 'absolute', top: '8px', right: '6px' }} onClick={setControlDisplay} round={settings.currentRound} />
      </div>
      <div className={styles.chartAndTables}>
        <TradeChart />

        <SharePriceTable teamScores={teamScores.filter(ts => ts.teamType === 'PLAYER')} sharePriceFactors={sharePriceFactors} />
        <div className={styles.chartInnerCard}>
          {/* @ts-ignore */}
          <Bar data={barChartDatasets} options={barChartOptionsMemo} />
        </div>

        <h3>Team Round Comparison</h3>
        <ReviewTable thisRoundScores={thisRoundScores} teamRoundDataToDisplay={teamRoundDataToDisplay} display={display} />
        <Select className={styles.chartSelect} value={display} onChange={option => setDisplay(option)}>
          <Option value='sharePrice'>Share Price</Option>
          <Option value='noOfTrades'>Trade Score</Option>
          <Option value='emissions'>Physical Emissions</Option>
          <Option value='carbonCertificates'>Carbon Certificates</Option>
        </Select>
        <div className={styles.chartInnerCard}>
          {/* @ts-ignore */}
          <Line data={lineChartDatasets} options={lineChartOptionsMemo} />
        </div>

        <br />
        <br />
      </div>
    </div>
  );
});
