import { useState, useContext, memo } from 'react';
import { Button, Select, message, Input } from 'antd';
import { IErrorProps, IAsset, BaseTeam } from '@powertrader/schema';

import { PlusOutlined } from '@ant-design/icons';
import { socket } from '../../../../socket/socket';
import { setupDataContext, setErrorContext } from '../../../../context';

import styles from '../Footer.module.css';

const { Option } = Select;

interface IAddAssetToTeam {
  setFooterDisplay: (value: string | undefined) => void;
  selectedTeam: BaseTeam;
  allAssets: IAsset[];
}

export const AddAssetToTeam = memo(({ setFooterDisplay, selectedTeam, allAssets }: IAddAssetToTeam) => {
  const { settings } = useContext(setupDataContext);
  const setError = useContext(setErrorContext);
  const [newAssetID, setNewAssetID] = useState<number>(); // assetID: price:
  const [newAssetPrice, setNewAssetPrice] = useState<number>();

  const handleAddAsset = () => {
    if (!newAssetID) {
      message.error(`No asset selected - cannot add asset ${newAssetID}`);

      return;
    }
    const [asset] = allAssets.filter(asset => newAssetID === asset.assetID);

    const assetToAdd = {
      price: newAssetPrice ? +newAssetPrice : 0,
      selectedAsset: newAssetID,
      selectedTeam: selectedTeam.teamID,
      operation: 'add',
      removeFromAuction: asset?.inAuction || false
    };

    socket.emit('editAsset', assetToAdd, function (error: IErrorProps['error'], data: 'complete') {
      if (error) {
        setError({
          message: `Cannot add asset ${asset.label} to team ${selectedTeam?.label}`,
          error
        });
      } else if (data === 'complete') {
        socket.emit('eventLog', {
          type: 'assets',
          log: `${asset.label} was added to team ${selectedTeam?.label} for ${assetToAdd.price}k Fixed Cost`
        });
      }
    });

    setNewAssetID(undefined);
    setNewAssetPrice(undefined);
  };

  return (
    <div style={{ borderColor: selectedTeam.color }} className={styles.footer}>
      <Select
        showSearch
        placeholder='Select an Asset'
        optionFilterProp='children'
        value={newAssetID}
        className={styles.assetSelect}
        dropdownClassName={styles.assetSelectDropdown}
        onChange={option => setNewAssetID(+option)}>
        {allAssets
          .filter(asset => asset.teamID === null)
          .map(asset => {
            return (
              <Option key={asset.assetID} value={asset.assetID}>
                {asset.label} ({asset.typeShortLabel})
              </Option>
            );
          })}
      </Select>

      <Input
        onChange={event => setNewAssetPrice(isNaN(+event.target.value) ? 0 : +event.target.value)}
        value={newAssetPrice}
        prefix={settings.currency}
        suffix={`k/${settings.roundPeriod}`}
        className={styles.assetAdderCost}
      />

      <Button type='primary' size='small' shape='round' icon={<PlusOutlined />} onClick={handleAddAsset} className={styles.addButton} />

      <Button type='primary' size='small' shape='round' className={styles.returnButton} onClick={() => setFooterDisplay(undefined)}>
        Return
      </Button>
    </div>
  );
});
