import { useContext, memo } from 'react';
import classNames from 'classnames';
import { INewTradeReq, BilateralTrade, OpenTrade, Market, BaseTeam } from '@powertrader/schema';
import styles from './TradeTable.module.css';

import { gameProgressContext, setupDataContext } from '../../../context';

import OTTradeRow from '../OpenTradeTable/OTTradeRow';
import BilateralTradeRow from '../BilateralTradeTable/BilateralTradeRow';
import { TradeRowHeader } from './TradeRowHeader';
import { TradeRowLabel } from './TradeRowLabel';

interface ITradeTable {
  dealType: OpenTrade['dealType'];
  trades: BilateralTrade[] | OpenTrade[];
  teamID: BaseTeam['teamID'];
  teamTradesOnly?: boolean;
  tradeType: INewTradeReq['tradeType'];
  market: Market;
  controlView?: boolean;
  setTradeDestination?: (marketID: INewTradeReq['marketID'], dealType: INewTradeReq['dealType']) => void;
  highlighted?: boolean;
  bilateralsView?: boolean;
}
export const TradeTable = memo(
  ({
    dealType,
    trades,
    teamID,
    teamTradesOnly = false,
    tradeType,
    market,
    controlView = false,
    setTradeDestination,
    highlighted = false,
    bilateralsView = false
  }: ITradeTable) => {
    const { settings } = useContext(setupDataContext);
    const { roundID } = useContext(gameProgressContext);
    let tradesToDisplay = [...trades];

    const numberOfTradesPerMarket = controlView ? 6 : 4;
    if (teamTradesOnly) {
      tradesToDisplay = tradesToDisplay.filter(trade => trade.offeringTeam.teamID === teamID);
    }

    if (dealType === 'buy') {
      tradesToDisplay = tradesToDisplay
        .filter(trade => trade.dealType === 'buy' && trade.startRoundID <= roundID && trade.endRoundID >= roundID)
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .sort((a, b) => b.price - a.price);

      tradesToDisplay.splice(numberOfTradesPerMarket);
    }

    if (dealType === 'sell') {
      tradesToDisplay = tradesToDisplay
        .filter(trade => trade.dealType === 'sell' && trade.startRoundID <= roundID && trade.endRoundID >= roundID)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .sort((a, b) => b.price - a.price);

      tradesToDisplay = tradesToDisplay.filter((t, index) => index >= tradesToDisplay.length - numberOfTradesPerMarket);
    }

    return (
      <div style={highlighted ? { borderColor: 'red' } : {}} className={styles.tradeTableContainer}>
        {dealType === 'sell' && (
          <>
            <TradeRowHeader
              backgroundColor={bilateralsView ? 'rgb(32, 72, 133)' : 'rgb(12, 160, 73)'}
              headings={
                bilateralsView
                  ? [
                      { label: 'Deal', style: { width: '25%' } },
                      { label: 'Team', style: { width: '28%' } },
                      { label: 'Vol', style: { width: '12%' } },
                      { label: settings.currency, style: { width: '15%' } },
                      { label: 'Action', style: { width: '20%' } }
                    ]
                  : [
                      {
                        label: 'Deal',
                        style: controlView ? { width: '35%' } : { width: '22%' }
                      },
                      { label: 'Volume' },
                      {
                        label: settings.currency + market.priceUnits,
                        style: { width: '29%' }
                      }
                    ]
              }
            />

            <TradeRowLabel
              text={
                market.type === 'carbonMarket'
                  ? `Offers to Sell (${settings.currency}${settings.carbonTax} ${market.priceUnits})`
                  : tradeType === 'hydrogenMarket'
                  ? `Offers to Sell (${settings.currency}${settings.hydrogenShortfallPrice} ${market.priceUnits})`
                  : `Offers to Sell (${settings.currency}${settings.electricityShortfallPrice} ${market.priceUnits})`
              }
              enableTooltip={!controlView}
              tooltipText={
                market.type === 'carbonMarket'
                  ? `You will be charged a carbon tax of ${settings.currency}${settings.carbonTax} ${market.priceUnits} if you are short at the end of the round.`
                  : tradeType === 'hydrogenMarket'
                  ? `The grid will sell you hydrogen at ${settings.currency}${settings.hydrogenShortfallPrice} ${market.priceUnits} if you are short at the end of the round.`
                  : `The grid will sell you power at ${settings.currency}${settings.electricityShortfallPrice} ${market.priceUnits} if you are short at the end of the round.`
              }
              tooltipPlacement='bottom'
              onClick={() => {
                if (typeof setTradeDestination === 'function') setTradeDestination(market.marketID, dealType);
              }}
              showArrows={!controlView}
              arrowDirection='down'
            />
          </>
        )}

        {tradesToDisplay.length === 0 ? (
          <div className={classNames([styles.noTrades, dealType === 'sell' ? styles.tradeRowContainerSell : styles.tradeRowContainerBuy])}>
            No {bilateralsView && 'Bilateral '}Trades Available
            <br />
          </div>
        ) : (
          <div className={dealType === 'sell' ? styles.tradeRowContainerSell : styles.tradeRowContainerBuy}>
            {tradesToDisplay.map((trade, index) => {
              const renderBuySellButton = (index === 0 && dealType === 'buy') || (index === tradesToDisplay.length - 1 && dealType === 'sell');
              if (trade.type === 'BilateralTrade') {
                return <BilateralTradeRow key={index} trade={trade} teamID={teamID} />;
              }
              return <OTTradeRow key={index} trade={trade} teamID={teamID} renderBuySellButton={renderBuySellButton} controlView={controlView} />;
            })}{' '}
          </div>
        )}
        {dealType === 'buy' && (
          <TradeRowLabel
            text={
              tradeType === 'carbonMarket'
                ? `Bids to Buy (${settings.currency} 0 ${market.priceUnits})`
                : tradeType === 'hydrogenMarket'
                ? `Bids to Buy`
                : `Bids to Buy (${settings.currency}${settings.electricityBuyBackPrice} ${market.priceUnits})`
            }
            enableTooltip={!controlView}
            tooltipText={
              tradeType === 'carbonMarket'
                ? `Your excess emission certificates have no monetry value at the end of the round.`
                : tradeType === 'hydrogenMarket'
                ? 'Your unsold hydrogen will be left in storage for use in future ronuds'
                : `The grid will buy your excess generation at ${settings.currency}${settings.electricityBuyBackPrice} ${market.priceUnits} at the end of the round.`
            }
            tooltipPlacement='top'
            onClick={() => {
              if (typeof setTradeDestination === 'function') setTradeDestination(market.marketID, dealType);
            }}
            showArrows={!controlView}
            arrowDirection='up'
          />
        )}
      </div>
    );
  }
);
