import { useState, useContext, memo } from 'react';
import { Button, message, Input } from 'antd';
import { BaseTeam, IErrorProps } from '@powertrader/schema';

import { socket } from '../../../../socket/socket';
import { setupDataContext, setErrorContext, gameProgressContext } from '../../../../context';

import styles from '../Footer.module.css';

interface ITeamAccountAdjustments {
  setFooterDisplay: (value: string | undefined) => void;
  selectedTeam: BaseTeam;
}

export const TeamAccountAdjustments = memo(({ setFooterDisplay, selectedTeam }: ITeamAccountAdjustments) => {
  const { settings } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const setError = useContext(setErrorContext);
  const [cashAdjustment, setCashAdjustment] = useState<string>();
  const [shareholderSentiment, setShareholderSentiment] = useState<string>();

  const handleAdjustment = (keyToUpdate: 'cashAdjustment' | 'shareholderSentiment') => {
    const updateData = {
      teamID: selectedTeam.teamID,
      roundID,
      keyToUpdate,
      value: 0,
      operation: 'update'
    };
    if (keyToUpdate === 'cashAdjustment' && cashAdjustment) {
      updateData.value = +cashAdjustment;
    } else if (keyToUpdate === 'shareholderSentiment' && shareholderSentiment) {
      updateData.value = +shareholderSentiment / 100;
    } else {
      message.error('Please enter a value');
      return;
    }

    socket.emit('updateTeamRound', updateData, function (error: IErrorProps['error'], data: 'complete') {
      if (error) {
        setError({
          message: `Cannot add ${keyToUpdate}`,
          error
        });
      } else if (data === 'complete') {
        message.success('Adjustment made to team');
        socket.emit('eventLog', {
          type: 'assets',
          log: `A ${keyToUpdate} of ${updateData.value} was added to team ${selectedTeam.teamID} for ${keyToUpdate}`
        });
      }
    });
    if (keyToUpdate === 'cashAdjustment') {
      setCashAdjustment(undefined);
    } else {
      setShareholderSentiment(undefined);
    }
  };

  return (
    <div style={{ borderColor: selectedTeam.color }} className={styles.footer}>
      <Input
        onChange={event => setCashAdjustment(isNaN(+event.target.value) && event.target.value !== '-' ? '0' : event.target.value)}
        value={cashAdjustment}
        prefix={settings.currency}
        suffix={`k/${settings.roundPeriod}`}
        className={styles.Adjustment}
      />

      <Button type='primary' size='small' shape='round' onClick={() => handleAdjustment('cashAdjustment')}>
        Regulators Adjustment
      </Button>
      <Input
        onChange={event => setShareholderSentiment(isNaN(+event.target.value) && event.target.value !== '-' ? '0' : event.target.value)}
        value={shareholderSentiment}
        suffix='%'
        className={styles.Adjustment}
      />

      <Button type='primary' size='small' shape='round' onClick={() => handleAdjustment('shareholderSentiment')}>
        Shareholder Sentiment
      </Button>
      <Button type='primary' size='small' shape='round' className={styles.returnButton} onClick={() => setFooterDisplay(undefined)}>
        Return
      </Button>
    </div>
  );
});
