import { SaveOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import { memo, useState } from 'react';
import styles from './SharePriceControl.module.css';
import { factorKeys, ISharePriceEditingKey, ISharePriceFactors } from '@powertrader/schema';

interface ISharePriceAdjustmentInterface {
  handleSave: (editingKey: ISharePriceEditingKey, editingValue: string | undefined) => void;
  sharePriceFactors: ISharePriceFactors[];
  disabled?: boolean;
}
export const SharePriceAdjustmentInterface = memo(({ handleSave, sharePriceFactors, disabled }: ISharePriceAdjustmentInterface) => {
  const [editingValue, setEditingValue] = useState<string>();
  const [editingKey, setEditingKey] = useState<ISharePriceEditingKey>({
    baseLimitsID: null,
    key: null
  });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Type</th>
          <th>Best Expected</th>
          <th>Worst Expected</th>
          <th>Growth Limit</th>
        </tr>
      </thead>
      <tbody>
        {sharePriceFactors.map(spf => (
          <tr key={spf.baseLimitsID}>
            <td>
              {spf.label} <p>({spf.absOrRel})</p>
            </td>
            {factorKeys.map(factorKey => {
              const isEditing = editingKey.baseLimitsID === spf.baseLimitsID && editingKey.key === factorKey && editingValue !== null;

              const editingValueAsNumber = editingValue ? parseFloat(editingValue) : undefined;
              return (
                <td key={factorKey}>
                  <div className={styles.row}>
                    <Input
                      disabled={disabled}
                      type='text'
                      pattern='^[+-]?[0-9]*(\.{1})?[0-9]*'
                      // onFocus={e => e.target.select()}
                      onChange={event => {
                        setEditingKey({
                          baseLimitsID: spf.baseLimitsID,
                          key: factorKey
                        });
                        event.target.validity.valid ? setEditingValue(event.target.value) : setEditingValue('');
                      }}
                      onBlur={() =>
                        setTimeout(async () => {
                          handleSave(editingKey, editingValue);
                          setEditingKey({ baseLimitsID: null, key: null });
                        }, 300)
                      }
                      onPressEnter={() => handleSave(editingKey, editingValue)}
                      value={isEditing ? editingValue : +spf[factorKey]}
                      className={styles.input}
                      style={{
                        backgroundColor: isEditing && editingValueAsNumber !== +spf[factorKey] ? '#c1c1c1' : 'white'
                      }}
                    />
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
});
