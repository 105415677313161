import { memo, useContext } from 'react';
import moment from 'moment';

import { BilateralTrade, OpenTradeComplete, Market, BaseTeam, INewTradeReq } from '@powertrader/schema';

import { setupDataContext } from '../../context';
import styles from './MyTrades.module.css';

interface IMyTrades {
  displayMarket: Market;
  completedTrades: BilateralTrade[] | OpenTradeComplete[];
  teamID: BaseTeam['teamID'];
  tradeType: INewTradeReq['tradeType'];
  bilateralView: boolean;
}
export const MyTrades = memo(({ displayMarket, completedTrades, teamID, tradeType, bilateralView }: IMyTrades) => {
  const { settings } = useContext(setupDataContext);

  const sortedCompletedTrades = completedTrades.sort(
    (a: BilateralTrade | OpenTradeComplete, b: BilateralTrade | OpenTradeComplete) =>
      new Date(b.tradedAt || 0).getTime() - new Date(a.tradedAt || 0).getTime()
  );

  return (
    <>
      <h4 className={styles.marketLabel}> {tradeType !== 'powerExchange' ? '' : `${displayMarket.label} Market`}</h4>
      {completedTrades.length === 0 ? (
        <h5>No completed trades in market</h5>
      ) : (
        <table>
          <tr className={styles.tableHeadings}>
            <th>Type</th>
            <th>Volume</th>
            <th>
              {settings.currency}
              {displayMarket.priceUnits}
            </th>
            {bilateralView && <th>With</th>}
            <th>Traded At</th>
          </tr>
          {sortedCompletedTrades.map((trade: BilateralTrade | OpenTradeComplete) => {
            const date = moment(trade.tradedAt).format('hh:mm:ss a');
            if (trade.type === 'BilateralTrade') {
              return (
                <tr
                  key={trade.id}
                  className={styles.tableItems}
                  style={new Date(trade.tradedAt || 0).getTime() > Date.now() - 2000 ? { color: 'green' } : {}}>
                  <th>
                    {(trade.dealType === 'buy' && trade?.receivingTeam?.teamID === teamID) ||
                    (trade.dealType === 'sell' && trade.offeringTeam.teamID === teamID)
                      ? 'Sold'
                      : 'Bought'}
                  </th>

                  <th>
                    {trade.volume} {displayMarket.volumeUnits}
                  </th>
                  <th>{trade.price}</th>
                  {bilateralView && <th>{trade?.receivingTeam?.teamID === teamID ? trade.offeringTeam.label : trade?.receivingTeam?.label}</th>}
                  <th>{date}</th>
                </tr>
              );
            }
            return (
              <tr
                key={trade.id}
                className={styles.tableItems}
                style={new Date(trade.tradedAt).getTime() > Date.now() - 2000 ? { color: 'green' } : {}}>
                <th>
                  {(trade.openTrade.dealType === 'buy' && trade.receivingTeam.teamID === teamID) ||
                  (trade.openTrade.dealType === 'sell' && trade.openTrade.offeringTeam.teamID === teamID)
                    ? 'Sold'
                    : 'Bought'}
                </th>

                <th>
                  {trade.volume} {displayMarket.volumeUnits}
                </th>
                <th>{trade.openTrade.price}</th>
                {bilateralView && <th>{trade.receivingTeam.teamID === teamID ? trade.openTrade.offeringTeam.label : trade.receivingTeam.label}</th>}
                <th>{date}</th>
              </tr>
            );
          })}
        </table>
      )}
    </>
  );
});
