import { memo, useContext, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { OpenTradeComplete } from '@powertrader/schema';
import type { ChartData, ChartOptions } from 'chart.js';
import styles from './ReviewPage.module.css';
import { openTradesCompletedContext, setupDataContext } from '../../context';
import { tradeChartDatasets, tradeChartOptions } from './tradeChartData';

export const TradeChart = memo(() => {
  const [marketSelected, setMarketSelected] = useState<number | undefined>();
  const openTradesCompleted = useContext(openTradesCompletedContext);
  const {
    markets,
    settings: { currency }
  } = useContext(setupDataContext);

  let filteredTrades: OpenTradeComplete[] = openTradesCompleted;
  let filteredMarkets = markets;
  if (marketSelected !== undefined) {
    filteredTrades = filteredTrades.filter(t => t.openTrade.market.marketID === marketSelected);
    filteredMarkets = markets.filter(t => t.marketID === marketSelected);
  }
  const tradeChartOption: ChartOptions = useMemo(() => tradeChartOptions(currency), [currency]);
  const tradeChartData: ChartData = useMemo(() => tradeChartDatasets({ filteredTrades, filteredMarkets }), [filteredTrades, filteredMarkets]);

  return (
    <>
      <h3>Trade Price</h3>
      <div className={styles.buttonRow}>
        <button onClick={() => setMarketSelected(1)} style={{ background: 'rgb(236, 147, 47)' }} className={styles.button} type='button'>
          Day Market
        </button>

        <button onClick={() => setMarketSelected(2)} style={{ background: 'rgb(29, 100, 140)' }} className={styles.button} type='button'>
          Night Market
        </button>
        <button onClick={() => setMarketSelected(3)} style={{ background: 'rgb(0, 0, 0)' }} className={styles.button} type='button'>
          H<sub>2</sub> Market
        </button>
        <button
          onClick={() => setMarketSelected(4)}
          style={{ background: 'rgb(200, 200, 200)', color: 'black' }}
          className={styles.button}
          type='button'>
          Carbon Market
        </button>
        <button onClick={() => setMarketSelected(undefined)} style={{ background: 'green' }} className={styles.button} type='button'>
          All Trades
        </button>
      </div>
      <div className={styles.chartInnerCard}>
        {/* @ts-ignore */}
        <Bar data={tradeChartData} options={tradeChartOption} />
      </div>
    </>
  );
});
