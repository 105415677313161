import { IAsset, IAssetType, IErrorProps, IUpdateAssetLoadSocket, Market } from '@powertrader/schema';
import { round } from 'lodash';
import { getMarket } from '../../calculations';
import { socket } from '../../socket/socket';

interface IhandleLoadChange {
  asset: IAsset | IAssetType;
  value: number;
  market: Market;
  setError: (E: IErrorProps) => void;
  scenarioTypeLimit: number;
  roundID: number;
  markets: Market[];
}

export const handleLoadChange = ({ asset, value, market, setError, scenarioTypeLimit, roundID, markets }: IhandleLoadChange, trigger = false) => {
  const assetID = 'assetID' in asset ? asset.assetID : undefined;
  const typeName = 'typeName' in asset ? asset.typeName : undefined;
  const load = 'load' in asset ? asset.load : [];
  const teamID = 'teamID' in asset ? asset.teamID : undefined;

  const { storageEfficiency, electricityLoadType, hydrogenLoadType } = asset;

  const otherEnergyMarket = market.name === 'day' ? getMarket(markets, 'night') : getMarket(markets, 'day');
  const valueOfOtherEnergyMarket = load.find(l => l.marketID === otherEnergyMarket.marketID && l.roundID === roundID)?.load || 0;
  const sumOfCurrentLoad = valueOfOtherEnergyMarket + value;

  if (assetID && teamID) {
    const data: IUpdateAssetLoadSocket = {
      option: value,
      assetID,
      teamID,
      marketID: market.marketID,
      roundID
    };
    socket.emit('updateAssetLoad', data, (error: IErrorProps['error'], data?: 'complete') => {
      if (error) {
        setError({ message: 'cannot set asset load', error });
      } else if (data === 'complete') {
        if (trigger) return;
        if (storageEfficiency !== null && electricityLoadType === 'generator') {
          // Pump Storage
          handleLoadChange(
            {
              asset,
              value: round(-value / storageEfficiency),
              market: otherEnergyMarket,
              setError,
              scenarioTypeLimit,
              roundID,
              markets
            },

            true
          );
          return;
        }
        if (typeName === 'nuclearPlant' && electricityLoadType === 'generator') {
          // nuclear plant force simultatious loading
          handleLoadChange(
            {
              asset,
              value,
              market: otherEnergyMarket,
              setError,
              scenarioTypeLimit,
              roundID,
              markets
            },
            true
          );
          return;
        }
        if (hydrogenLoadType === 'producer' && electricityLoadType === 'customer') {
          handleLoadChange(
            {
              asset,
              value: round(sumOfCurrentLoad * (asset?.conversionEfficiency || 1)),
              market: getMarket(markets, 'hydrogenMarket'), // hydrogen Market
              setError,
              scenarioTypeLimit,
              roundID,
              markets
            },
            true
          );
          return;
        }
        if (hydrogenLoadType === 'consumer' && electricityLoadType === 'generator') {
          handleLoadChange(
            {
              asset,
              value: round((sumOfCurrentLoad * 1) / (asset?.conversionEfficiency || 1)),
              market: getMarket(markets, 'hydrogenMarket'), // hydrogen Market
              setError,
              scenarioTypeLimit,
              roundID,
              markets
            },
            true
          );
        }
      }
    });
  }
};
