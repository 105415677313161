import { DeleteOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { memo, useContext } from 'react';
import { OpenTrade, BaseTeam } from '@powertrader/schema';

import { setupDataContext } from '../../../context';

import OTDealOption from './OTDealOption';
import styles from './OTTradeRow.module.css';
import { socket } from '../../../socket/socket';

interface IOTTradeRow {
  trade: OpenTrade;
  teamID: BaseTeam['teamID'];
  renderBuySellButton: boolean;
  controlView: boolean;
}
const OTTradeRow = memo(({ trade, teamID, renderBuySellButton, controlView }: IOTTradeRow) => {
  const { teams } = useContext(setupDataContext);
  const onDeletTrade = () => {
    socket.emit('deleteTrade', trade.id);
  };
  const tradeTeam = teams.find(t => t.teamID === trade.offeringTeam.teamID);
  const teamColor = trade.offeringTeam.teamID === teamID || controlView ? { borderColor: tradeTeam?.color } : {};

  return (
    <div className={styles.rowContainer} style={renderBuySellButton ? { ...teamColor, backgroundColor: 'white' } : teamColor}>
      <div style={controlView ? { width: '35%', ...teamColor } : teamColor} className={classNames([styles.column, styles.deal])}>
        {controlView ? (
          <p style={{ color: tradeTeam?.color }}>{tradeTeam?.label}</p>
        ) : (
          <>
            {renderBuySellButton && trade.offeringTeam.teamID !== teamID ? (
              <p style={{ color: 'green' }}>{trade.dealType === 'sell' ? 'Buy' : 'Sell'}</p>
            ) : (
              trade.offeringTeam.teamID === teamID && (
                <div className={styles.dealContainer}>
                  <button className={styles.deleteButton} onClick={() => onDeletTrade()}>
                    <DeleteOutlined />
                  </button>
                </div>
              )
            )}
          </>
        )}
      </div>
      <div style={teamColor} className={classNames([styles.column, styles.volume])}>
        <OTDealOption allowDeal={renderBuySellButton && trade.offeringTeam.teamID !== teamID && !controlView} trade={trade} teamID={teamID} />
      </div>
      <div style={teamColor} className={classNames([styles.column, styles.price])}>
        <p>{trade.price}</p>
      </div>
    </div>
  );
});
export default OTTradeRow;
