import { memo, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as regfaThumbsUp } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { IComment, IUser } from '@powertrader/schema';

import moment from 'moment';
import { socket } from '../../../socket/socket';
import styles from './IndividualComment.module.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'now',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy'
  }
});
interface IOrignalCommentPreview {
  originalComment: IComment;
}

const OrignalCommentPreview = ({ originalComment }: IOrignalCommentPreview) => {
  const { comment, userName, sendingTeamName, sendingTeamColor } = originalComment;

  return (
    <div style={{ borderColor: sendingTeamColor }} className={styles.orignalReplyContainer}>
      <div className={styles.replyingToText}>replying to...</div>
      <div className={styles.orignalReplyComment}>
        <strong>
          {userName} ({sendingTeamName})
        </strong>
        <br />
        {comment}
      </div>
    </div>
  );
};

interface IIndividualCommentFooter {
  likedByMe: boolean;
  numberoflikes: number;
  handleLikeRequest: () => void;
  handleReplyRequest: () => void;
  createdAt: number;
}

const IndividualCommentFooter = ({ numberoflikes, likedByMe, handleLikeRequest, handleReplyRequest, createdAt }: IIndividualCommentFooter) => {
  // calcualate number of reactions

  const liked = likedByMe;

  return (
    <div className={styles.footer}>
      <p className={styles.noOfLikes}>{numberoflikes}</p>
      <label className={styles.likeButton}>
        <input type='checkbox' checked={liked} onChange={handleLikeRequest} />

        {liked ? <FontAwesomeIcon icon={faThumbsUp as IconProp} /> : <FontAwesomeIcon icon={regfaThumbsUp as IconProp} />}
      </label>
      <p className={styles.spacer}>·</p>
      <p className={styles.replyButton} onClick={() => handleReplyRequest()}>
        reply
      </p>
      <p className={styles.spacer}>·</p>
      <p className={styles.timeElapsed}>{moment(createdAt).format('HH:mm:ss')}</p>
    </div>
  );
};
interface ICommentLocal {
  comment: IComment['comment'];
  type: IComment['type'];
}
const Comment = ({ comment, type }: ICommentLocal) => {
  if (type === 'comment' || type === 'reply') {
    return <p className={styles.comment}>{comment}</p>;
  }
  return (
    <div>
      <p className={styles.notification}>{comment}</p>
    </div>
  );
};

interface IIndividualComment {
  comment: IComment;
  user: IUser;
  setReplyRequired: (R: boolean) => void;
  setReplyID: (R: IIndividualComment['replyID']) => void;
  replyID: number | null;
  likedByMe: boolean;
  numberoflikes: number;
  originalComment: IComment | undefined;
}

export const IndividualComment = memo(
  ({
    comment: indivComment,
    user,
    setReplyRequired,
    setReplyID,
    replyID: currentReplyID,
    likedByMe,
    numberoflikes,
    originalComment
  }: IIndividualComment) => {
    const {
      userID,
      userName,
      commentID,
      comment,
      recipientTeamID,
      sendingTeamID,
      createdAt,
      type,
      sendingTeamName,
      sendingTeamColor,
      recipientTeamName
    } = indivComment;

    const postReaction = (idofcomment: number, like: boolean) => {
      const reactionData = {
        userID: user.userID,
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        reaction: like ? 'like' : 'unlike',
        type: 'reaction',
        replyID: idofcomment
      };
      socket.emit('nextComment', reactionData);
    };

    const handleReplyRequest = () => {
      if (currentReplyID === commentID) {
        setReplyRequired(false);
        setReplyID(null);
      } else {
        setReplyRequired(true);
        setReplyID(commentID);
      }
    };

    const handleLikeRequest = () => {
      const like = !likedByMe;
      postReaction(commentID, like);
    };

    if (type === 'notification') {
      return <Comment type={type} comment={comment} />;
    }

    const commentUserNameDisplay = getUserTitle();

    function getUserTitle() {
      let commentUserNameDisplay;
      if (recipientTeamID === user.teamID && sendingTeamID !== user.teamID) {
        commentUserNameDisplay = `${userName} (${sendingTeamName}) to ${recipientTeamName} (PRIVATE)`;
      } else if (recipientTeamID !== 0) {
        commentUserNameDisplay = `${userName} to ${recipientTeamName} (PRIVATE)`;
      } else if (userID === user.userID) {
        commentUserNameDisplay = `${userName} to All`;
      } else if (recipientTeamID === 0) {
        commentUserNameDisplay = `${userName} (${sendingTeamName})  to All`;
      }
      return commentUserNameDisplay;
    }
    return (
      <>
        <div
          className={classNames([styles.commentContainer, type === 'reply' ? styles.individualReply : styles.individualComment])}
          style={
            userID === user.userID ? { borderColor: sendingTeamColor, marginLeft: '40px' } : { borderColor: sendingTeamColor, marginRight: '40px' }
          }>
          {type === 'reply' && originalComment && <OrignalCommentPreview originalComment={originalComment} />}

          <p className={styles.userName}>{commentUserNameDisplay}</p>
          <Comment type={type} comment={comment} />
          <IndividualCommentFooter
            likedByMe={likedByMe}
            numberoflikes={numberoflikes}
            handleLikeRequest={handleLikeRequest}
            handleReplyRequest={handleReplyRequest}
            createdAt={createdAt}
          />
        </div>
      </>
    );
  }
);
