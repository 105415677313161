import { useState, useEffect, useContext } from 'react';
import type { Leaderboard } from '@powertrader/schema';
import { setErrorContext, setupDataContext, socketContext, socket } from '@powertrader/core';
import styles from './Leaderboard.module.css';
import { calcLeaderboardScore } from '../../Util/calcLeaderboardScore';

interface ILeaderboardComponent {
  currency: string;
}
export const LeaderboardComponent = ({ currency }: ILeaderboardComponent) => {
  const [leaderboard, setLeaderboard] = useState<Leaderboard[]>([]);

  const setError = useContext(setErrorContext);
  useEffect(() => {
    socket.emit('getLeaderboard', (error: Error) => {
      if (error) setError({ error, message: 'cannot get leaderboard' });
    });

    socket.on('leaderboard', (data: Leaderboard[]) => {
      if (data) setLeaderboard(data);
    });

    return () => {
      socket.off('leaderboard');
    };
  }, [setError]);

  return (
    <div className={styles.leaderboard}>
      <h1>All Time Leaderboard</h1>
      <div className={styles.leaderboardTableContainer}>
        <table>
          <thead>
            <tr>
              <th />
              <th>Company</th>
              <th>Portfolio</th>
              <th>Time</th>
              <th>Profit</th>
              <th>Emissions</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard
              .sort((a, b) => calcLeaderboardScore(b.time, b.profit, b.emissions) - calcLeaderboardScore(a.time, a.profit, a.emissions))
              .map((score, index) => {
                const minutes = Math.floor(score.time / 1000 / 60);
                const seconds = Math.floor((score.time / 1000) % 60);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{score.companyName}</td>
                    <td>{score.portfolio}</td>
                    <td>
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </td>
                    <td className={styles.profit}>
                      {currency} {score.profit} <span>k</span>
                    </td>
                    <td className={styles.emissions}>
                      {score.emissions}{' '}
                      <span>
                        kt.CO<sub>2</sub>
                      </span>
                    </td>
                    <td>{calcLeaderboardScore(score.time, score.profit, score.emissions)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
