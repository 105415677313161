import { useState, useEffect, useContext, memo, useCallback, useRef, useMemo } from 'react';

import { CaretLeftOutlined } from '@ant-design/icons';

import { round } from 'lodash';
import { Spin, Tooltip } from 'antd';
import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'underscore';
import {
  IErrorProps,
  ISettings,
  ISharePriceFactors,
  IAsset,
  ITeamRoundData,
  BilateralTrade,
  ITeamScore,
  OpenTradeComplete,
  IAdditionalInformationDisplayProps,
  ICalcTeamScore
} from '@powertrader/schema';

import { socket } from '../../socket/socket';
import {
  setupDataContext,
  teamAssetsContext,
  gameProgressContext,
  teamRoundDataContext,
  controlDataContext,
  setErrorContext,
  bilateralTradesContext,
  openTradesCompletedContext
} from '../../context';

import { Timer } from '../Timer/Timer';

import { calcSharePrice } from '../../calculations/calcSharePrice';
import { calcTeamScores } from '../../calculations/calcTeamScores';
import styles from './Dashboard.module.css';
import { getMarket } from '../../calculations/getFunctions';
import { useHydrogenValues } from '../../hooks/useHydrogenValues';
import { CustomerTable } from './CustomerTable';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IDebouncedDashResults {
  myTrades: OpenTradeComplete[];
  roundID: number;
  scenarioID: number;
  settings: ISettings;
  sharePriceFactors: ISharePriceFactors[];
  teamAssets: IAsset[];
  teamID: number;
  teamRoundData: ITeamRoundData[];
  bilateralTrades: BilateralTrade[];
}
interface IGetAverageProfit {
  roundID: number;
  teamScores: {
    roundID: number;
    teamID: number;
    profit: number;
    emissions: number;
    carbonCertificates: number;
    noOfTrades: number;
    hydrogenStored: number;
  }[];
}
interface IDashboard {
  teamID: number;
  setAdditionalInformationDisplay: IAdditionalInformationDisplayProps['setState'];
}

export const Dashboard = memo(({ teamID, setAdditionalInformationDisplay }: IDashboard) => {
  const setError = useContext(setErrorContext);
  const bilateralTrades = useContext(bilateralTradesContext);
  const { user, settings, teams, scenarioDefinition, markets } = useContext(setupDataContext);
  const teamAssets = useContext(teamAssetsContext);
  const { scenarioID, roundID } = useContext(gameProgressContext);
  const { sharePriceFactors } = useContext(controlDataContext);
  const teamRoundData = useContext(teamRoundDataContext);
  const [teamScore, setTeamScore] = useState<ITeamScore>();
  const myTrades = useContext(openTradesCompletedContext);
  const { setHydrogenValues } = useHydrogenValues();
  const activeMarkets = useMemo(() => {
    if (settings.allowHydrogenTrading && !settings.allowCarbonTrading)
      return markets.filter(market => market.type === 'powerExchange' || market.type === 'hydrogenMarket');
    if (!settings.allowHydrogenTrading && settings.allowCarbonTrading)
      return markets.filter(market => market.type === 'powerExchange' || market.type === 'carbonMarket');
    if (!settings.allowHydrogenTrading && !settings.allowCarbonTrading) return markets.filter(market => market.type === 'powerExchange');
    return markets;
  }, [markets, settings.allowCarbonTrading, settings.allowHydrogenTrading]);

  const getAverageProfit = useCallback((updateScores: IGetAverageProfit, currentGamePhase: ISettings['currentGamePhase']) => {
    return new Promise<number>((resolve, reject) => {
      socket.emit('updateTeamRoundFromDashboard', updateScores, currentGamePhase, (error: IErrorProps['error'], data: number) => {
        if (error) {
          reject(error);
        } else if (data) {
          resolve(data);
        }
      });
    });
  }, []);

  const { currency, currentGamePhase } = settings;

  const teamType = teams.find(team => team.teamID === teamID)?.type || 'PLAYER'; // errror really
  const calculateDashboardResults = useCallback(
    async props => {
      const {
        myTrades,
        roundID,
        scenarioID,
        settings,
        sharePriceFactors,
        teamAssets,
        teamID,
        teamRoundData,
        bilateralTrades
      }: IDebouncedDashResults = props;

      const lastRoundScores = teamRoundData.filter(tr => tr.roundID === roundID - 1);
      const teamScoreResults: ICalcTeamScore = calcTeamScores({
        settings,
        markets,
        scenarioID,
        roundID,
        assetsToScore: [{ teamID, teamType, teamAssets }],
        cashAndStrategy: teamRoundData.filter(tr => tr.roundID === roundID),
        trades: myTrades,
        bilateralTrades,
        lastRoundScores
      })[0];
      const averageProfit = await getAverageProfit(
        {
          roundID,
          teamScores: [
            {
              roundID,
              teamID,
              profit: teamScoreResults.totals.profit,
              emissions: teamScoreResults.totals.emissions,
              carbonCertificates: teamScoreResults.totals.carbonCertificates,
              noOfTrades: teamScoreResults.totals.noOfTrades,
              hydrogenStored: teamScoreResults.hydrogen.currentStored
            }
          ]
        },
        currentGamePhase
      );
      const [results]: ITeamScore[] = calcSharePrice({
        settings,
        teamID,
        teamType: user.teamType,
        roundID,
        assetsToScore: [{ teamID, teamType, teamAssets }],
        cashAndStrategy: teamRoundData.filter(tr => tr.roundID === roundID),
        trades: myTrades,
        sharePriceFactors,
        lastRoundScores,
        teamScores: [teamScoreResults],
        averageProfit
      });
      setTeamScore(results);
      setHydrogenValues(results.hydrogen);
    },
    [currentGamePhase, getAverageProfit, markets, setHydrogenValues, teamType, user.teamType]
  );

  // useRef holds the debounce to prevent overload

  const debounceDashResults = useRef<(props: IDebouncedDashResults) => void>(
    debounce(props => {
      if (myTrades && teamRoundData) {
        calculateDashboardResults(props);
      }
    }, 250)
  );

  // use Effect Triggers calculation
  useEffect(() => {
    if (myTrades && teamRoundData && bilateralTrades) {
      debounceDashResults.current({
        myTrades,
        roundID,
        scenarioID,
        settings,
        sharePriceFactors,
        teamAssets,
        teamID,
        teamRoundData,
        bilateralTrades: bilateralTrades.filter(bT => bT.status === 'accepted')
      });
    }
  }, [calculateDashboardResults, myTrades, roundID, scenarioID, settings, sharePriceFactors, teamAssets, teamID, teamRoundData, bilateralTrades]);

  if (!teamScore) {
    return (
      <div className={styles.loading}>
        <Spin size='large' />
        <h3>Loading...</h3>
      </div>
    );
  }
  const { marketTotalGen, marketTotalSupply, totals, sharePrice, wholesaleBalance, imbalance, tradedVolumeMarket } = teamScore;

  const carbonMarket = getMarket(markets, 'carbonMarket');
  const generationDataArray = marketTotalGen.map(mtg => ({
    market: mtg.market,
    value: mtg.totalLoad
  }));
  generationDataArray.push({
    market: carbonMarket,
    value: -round(teamScore.carbon.generation, 1)
  });

  const tradedDataArray = tradedVolumeMarket.map(tvm => ({
    market: tvm.market,
    value: tvm.volume
  }));
  tradedDataArray.push({
    market: carbonMarket,
    value: round(teamScore.carbon.trading)
  });

  const storageDataArray = markets.map(market => ({
    market,
    value: market.name === 'hydrogen' ? -teamScore.hydrogen.changeInHydrogenStorage : 0
  }));

  const suppliedDataArray = marketTotalSupply.map(mtg => ({
    market: mtg.market,
    value: -mtg.totalLoad
  }));
  suppliedDataArray.push({
    market: carbonMarket,
    value: round(teamScore.carbon.supply)
  });

  const balanceDataArray = wholesaleBalance.map(wB => ({
    market: wB.market,
    value: wB.market.name !== 'hydrogen' ? round(wB.balance) : round(wB.balance) - teamScore.hydrogen.changeInHydrogenStorage
  }));
  balanceDataArray.push({
    market: carbonMarket,
    value: round(teamScore.totals.carbonCertificates)
  });

  const wholesaleTableData = [
    {
      key: 'generation',
      rowHeading: ' Generation',
      data: generationDataArray
    },
    {
      key: 'traded',
      rowHeading: ' Trading',
      data: tradedDataArray
    },
    {
      key: 'fromStore',
      rowHeading: ' From Store',
      data: storageDataArray
    },
    {
      key: 'supplied',
      rowHeading: ' Supply',
      data: suppliedDataArray
    },
    {
      key: 'balance',
      rowHeading: 'Balance',
      data: balanceDataArray
    }
  ];
  const teamCurrentRoundData = teamRoundData.find(tr => tr.roundID === roundID);

  const balanceColor = (value: number) => {
    const red = 'rgb(255, 147, 147)';
    const orange = 'rgb(245, 192, 118)';
    const green = 'rgb(139, 201, 144)';

    if (value < 0) return { background: red };
    if (value >= 1) return { background: orange };
    return { background: green };
  };

  const customerAccountsData = [
    { label: 'Electricity', units: 'GWh', minDemand: totals.customerMinDemand, unfulfilled: teamScore.customerTotalShortR }
  ];
  if (settings.allowHydrogenTrading)
    customerAccountsData.push({
      label: 'Hydrogen',
      units: 'H₂',
      minDemand: teamScore.hydrogen.minDemand,
      unfulfilled: teamScore.hydrogen.unfulfilled
    });
  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.accountSheet}>
        <table className={styles.accountsTable}>
          <thead>
            <tr>
              <th>Account</th>
              <th style={{ borderRadius: '0px 8px 0px 0px' }}>Totals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Opening</td>
              <td>
                {currency} {teamCurrentRoundData?.opening.toLocaleString() || 0} <span>k</span>
              </td>
              <td className={styles.gap} />
              <td className={styles.breakdownHeading}>Generation</td>
              <td className={styles.breakdownHeading}>Trading</td>
              <td className={styles.breakdownHeading}>Customers</td>
              <td className={styles.breakdownHeading}>Imbalance</td>
            </tr>
            <tr>
              <td>Income</td>
              <td>
                {currency} {totals.income.toLocaleString()} <span>k</span>
              </td>
              <td className={styles.gap}>
                <CaretLeftOutlined />
              </td>
              <td>
                {currency}
                {teamScore.totals.subsidyIncome.toLocaleString()} <span>k</span>
              </td>
              <td>
                {currency} {totals.tradingIncome.toLocaleString()} <span>k</span>
              </td>
              <td>
                {currency} {totals.supplyIncome.toLocaleString()} <span>k</span>
              </td>
              <Tooltip
                placement='bottom'
                title={
                  <>
                    <p>
                      Imbalance Income: {currency}
                      {imbalance.income}k{' '}
                    </p>
                    <p>
                      Cash Adjustment: {currency}
                      {(teamCurrentRoundData?.cashAdjustment || 0) > 0 ? teamCurrentRoundData?.cashAdjustment : 0}k
                    </p>{' '}
                  </>
                }>
                <td>
                  {currency}{' '}
                  {(
                    imbalance.income + ((teamCurrentRoundData?.cashAdjustment || 0) > 0 ? teamCurrentRoundData?.cashAdjustment || 0 : 0)
                  ).toLocaleString()}{' '}
                  <span>k</span>
                </td>
              </Tooltip>
            </tr>
            <tr>
              <td>Expenditure</td>
              <td>
                {' '}
                {currency} {totals.expenditure.toLocaleString()} <span>k</span>
              </td>
              <td className={styles.gap}>
                <CaretLeftOutlined />
              </td>
              <Tooltip
                placement='bottom'
                title={
                  <>
                    <p>
                      Fixed Cost: {currency}
                      {totals.genCostBreakdown.fixedCost}k{' '}
                    </p>
                    <p>
                      Purchase Cost: {currency}
                      {totals.genCostBreakdown.purchaseCost}k{' '}
                    </p>
                    <p>
                      Running Cost: {currency}
                      {totals.genCostBreakdown.runningCost}k
                    </p>{' '}
                  </>
                }>
                <td>
                  {currency} {totals.generationCost.toLocaleString()} <span>k</span>
                </td>
              </Tooltip>
              <td>
                {currency} {totals.tradingExpenditure.toLocaleString()} <span>k</span>
              </td>
              <td>
                {currency} {totals.supplyCosts.toLocaleString()} <span>k</span>
              </td>
              <Tooltip
                placement='bottom'
                title={
                  <>
                    <p>
                      Electricity Wholesale Imbalance Penalty: {currency}
                      {imbalance.electricityPenalty - teamScore.customerTotalShortRPenalty}k{' '}
                    </p>
                    <p>
                      Electricity Customer Imbalance Penalty: {currency}
                      {teamScore.customerTotalShortRPenalty}k{' '}
                    </p>
                    {settings.allowHydrogenTrading ? (
                      <>
                        <p>
                          Hydrogen Wholesale Imbalance Penalty: {currency}
                          {imbalance.hydrogenTotalPenalty - imbalance.hydrogenCustomerPenalty}k{' '}
                        </p>
                        <p>
                          Hydrogen Customer Imbalance Penalty: {currency}
                          {imbalance.hydrogenCustomerPenalty}k{' '}
                        </p>{' '}
                      </>
                    ) : (
                      ''
                    )}
                    <p>
                      Carbon Penalty: {currency}
                      {totals.pollutionPenalty}k
                    </p>{' '}
                    <p>
                      Cash Adjustment: {currency}
                      {(teamCurrentRoundData?.cashAdjustment || 0) < 0 ? Math.abs(teamCurrentRoundData?.cashAdjustment || 0) : 0}k
                    </p>{' '}
                  </>
                }>
                <td>
                  {currency}{' '}
                  {(
                    imbalance.totalPenalty +
                    totals.pollutionPenalty +
                    ((teamCurrentRoundData?.cashAdjustment || 0) < 0 ? Math.abs(teamCurrentRoundData?.cashAdjustment || 0) : 0)
                  ).toLocaleString()}{' '}
                  <span>k</span>
                </td>
              </Tooltip>
            </tr>
            <tr>
              <td>Profit</td>
              <td style={totals.profit < 0 ? { background: 'rgb(255, 147, 147) ' } : totals.profit > 0 ? { background: 'rgb(139, 201, 144)' } : {}}>
                {currency}
                {totals.profit.toLocaleString()} <span>k</span>
              </td>
            </tr>
            <tr>
              <td>Cash</td>
              <td>
                {currency}
                {((teamCurrentRoundData?.opening || 0) + totals.profit).toLocaleString()} <span>k</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            position: 'absolute',
            left: '455px',
            top: '132px',
            color: 'rgb(94, 187, 159)',
            fontWeight: 'bold',
            transform: 'translate(-50%,-50%)',
            width: '400px'
          }}>
          <h1
            className={styles.teamName}
            style={{
              color: user.color
            }}
            onClick={() => setAdditionalInformationDisplay(prevState => (prevState === 'teamStats' ? undefined : 'teamStats'))}>
            Team {teams.find(team => team.teamID === teamID)?.label}
          </h1>
          <h5>{teamRoundData.find(trd => trd.roundID === roundID)?.strapline || 'No Strapline Provided'}</h5>
        </div>
      </div>
      <div className={styles.timerColumn}>
        <table className={styles.balanceTable}>
          <thead>
            <tr>
              <th>Share Price</th>
              <th>PhysicalEm</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center', fontWeight: 'normal' }}>{round(sharePrice?.thisRoundSharePrice) || 0}</td>
              <td>
                {round(teamScore.totals.emissions, 2)} kt.CO<sub>2</sub>
              </td>
            </tr>
            <tr>
              <Tooltip placement='bottom' title={<>Share Price Growth</>}>
                <td
                  style={
                    (round(sharePrice?.teamGrowth * 100) || 0) < 0
                      ? {
                          background: 'rgb(255, 147, 147) ',
                          textAlign: 'center',
                          fontWeight: 'normal'
                        }
                      : (round(sharePrice?.teamGrowth * 100) || 0) > 0
                      ? {
                          background: 'rgb(139, 201, 144)',
                          textAlign: 'center',
                          fontWeight: 'normal'
                        }
                      : { textAlign: 'center' }
                  }>
                  {round(sharePrice?.teamGrowth * 100) || 0} %
                </td>
              </Tooltip>
              <Tooltip
                placement='bottom'
                title={
                  <>
                    Carbon Tax: {settings.currency} {settings.carbonTax} /kt.CO
                    <sub>2</sub>
                  </>
                }>
                <td style={{ padding: '2px' }}>
                  {settings.currency}
                  {settings.carbonTax}/kt.CO<sub>2</sub>
                </td>
              </Tooltip>
            </tr>
          </tbody>
        </table>
        <Tooltip
          placement='bottom'
          title={
            settings.allowStrategyInput ? (
              'Sorry our forecasters are unsure what the weather will be like tomorrow, check back later.'
            ) : (
              <>
                <p>Scenario Name: {scenarioDefinition.find(sd => sd.scenarioID === scenarioID)?.label}</p>
                <br />
                <p>
                  Weather Forecast <br />
                  {scenarioDefinition.find(sd => sd.scenarioID === scenarioID)?.weatherDescription}
                </p>
              </>
            )
          }>
          <div className={styles.roundAndWeather}>
            <FontAwesomeIcon icon={faCloudSunRain as IconProp} />{' '}
            {settings.currentGamePhase === 'demo' ? <h3>Demo Round</h3> : <h3>Round {roundID}</h3>}
          </div>
        </Tooltip>
        <Timer endTime={settings.timerEndTime} />
      </div>
      <table className={styles.balanceTable}>
        <thead>
          <tr>
            <th>Wholesale</th>
            {activeMarkets.map(aM => (
              <th>{aM.label === 'Carbon' ? 'C.Certs' : aM.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {wholesaleTableData.map(wtd => (
            <tr key={wtd.key}>
              <td>{wtd.rowHeading}</td>
              {activeMarkets.map((aM, index) => {
                const value = wtd.data.find(d => d.market.marketID === aM.marketID)?.value;
                if (value === undefined) return 'REFRESH';
                return (
                  <td key={aM.name} style={wtd.key === 'balance' ? balanceColor(value) : {}}>
                    {value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.lastColumn}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
        </div>
        <CustomerTable marketData={customerAccountsData} />
      </div>
    </div>
  );
});
