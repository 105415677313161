import { memo } from 'react';
import styles from './Dashboard.module.css';

interface ICustomerTable {
  marketData: { label: string; units: string; minDemand: number; unfulfilled: number }[];
}
export const CustomerTable = memo(({ marketData }: ICustomerTable) => {
  return (
    <table style={{ width: '100%' }} className={styles.balanceTable}>
      <thead>
        <tr>
          <th>Customer </th>
          {marketData.map((data, i) => (
            <th key={i}>{data.units}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Min Demand</td>
          {marketData.map((data, i) => (
            <td key={i}>{data.minDemand}</td>
          ))}
        </tr>

        <tr>
          <td>Balance</td>

          {marketData.map((data, i) => (
            <td key={i} style={data.unfulfilled > 0 ? { background: 'rgb(255, 147, 147)' } : { background: 'rgb(139, 201, 144)' }}>
              {-data.unfulfilled}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
});
