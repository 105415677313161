import { Fragment, memo, useEffect, useRef, useState } from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import { Button } from 'antd';
import { IComment, IUser } from '@powertrader/schema';
import { ReplyInputField } from './ReplyInputField';
import { IndividualComment } from './IndividualComment/IndividualComment';
import styles from './CommentFeed.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScrollableFeedRC = ScrollableFeed as any;
interface ICommentFeed {
  user: IUser;
  allComments: IComment[];
  chatType: 'all' | 'team';
  forceScrollToBottom: boolean;
  setForceScrollToBottom: (R: 'all' | 'team' | null) => void;
}
export const CommentFeed = memo(({ user, allComments, chatType, forceScrollToBottom, setForceScrollToBottom }: ICommentFeed) => {
  const [numberToShow, setNumberToShow] = useState(100);
  const [replyID, setReplyID] = useState<IComment['replyID']>(null);
  const [replyRequired, setReplyRequired] = useState(false);

  const scrollableRef = useRef<ScrollableFeed | null>(null);
  let commentsToShow: IComment[];

  useEffect(() => {
    if (forceScrollToBottom) {
      scrollableRef.current?.scrollToBottom();
    }
    setForceScrollToBottom(null);
  }, [forceScrollToBottom, setForceScrollToBottom]);

  if (chatType === 'all') {
    commentsToShow = allComments.filter(aC => aC.recipientTeamID === 0 || aC.type === 'reaction' || aC.type === 'notification');
  } else {
    commentsToShow = allComments.filter(
      aC => ((aC.sendingTeamID === user.teamID || aC.recipientTeamID === user.teamID) && aC.recipientTeamID !== 0) || aC.type === 'reaction'
    );
  }
  const numberOfComments = commentsToShow.length;
  return (
    <>
      <ScrollableFeedRC ref={scrollableRef}>
        {commentsToShow.map((comment, index) => {
          if (index < numberOfComments - numberToShow) return null;
          let originalComment: IComment | undefined;
          if (comment.type === 'reply') {
            originalComment = commentsToShow.find(c => c.commentID === comment.replyID);
          }
          const commentReactions = commentsToShow.filter(c => c.replyID === comment.commentID && c.reaction !== null);

          const likedByMe =
            commentReactions.filter(c => c.userID === user.userID).reduce((n, comment) => n + (comment.reaction === 'like' ? 1 : 0), 0) -
              commentReactions.filter(c => c.userID === user.userID).reduce((n, comment) => n + (comment.reaction === 'unlike' ? 1 : 0), 0) >
            0;
          const numberoflikes =
            commentReactions.reduce((n, comment) => n + (comment.reaction === 'like' ? 1 : 0), 0) -
            commentReactions.reduce((n, comment) => n + (comment.reaction === 'unlike' ? 1 : 0), 0); // Counts likes - unlikes

          return (
            <Fragment key={comment.commentID}>
              {comment.type !== 'reaction' && (
                <>
                  {index === numberOfComments - numberToShow && (
                    <Button
                      type='primary'
                      shape='round'
                      ghost
                      className={styles.showMore}
                      onClick={() => setNumberToShow(prevState => prevState + 3)}>
                      {' '}
                      Show More
                    </Button>
                  )}
                  <IndividualComment
                    key={index}
                    comment={comment}
                    user={user}
                    setReplyRequired={setReplyRequired}
                    setReplyID={setReplyID}
                    replyID={replyID}
                    numberoflikes={numberoflikes}
                    originalComment={originalComment}
                    likedByMe={likedByMe}
                  />
                  {comment.commentID === replyID && replyRequired && (
                    <ReplyInputField
                      key={comment.commentID}
                      comment={comment}
                      user={user}
                      chatType={chatType}
                      setReplyRequired={setReplyRequired}
                      setForceScrollToBottom={setForceScrollToBottom}
                    />
                  )}
                </>
              )}
            </Fragment>
          );
        })}
      </ScrollableFeedRC>
    </>
  );
});
