import { memo, useContext, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BilateralTrade, IAsset, IErrorProps, ITeamRoundData, ITeamScore, OpenTradeComplete, BaseTeam } from '@powertrader/schema';

import { mean, round } from 'lodash';
import { socket } from '../../../socket/socket';
import { setErrorContext, setupDataContext } from '../../../context';

import { TeamDetailsFooter } from './TeamDetailsFooter';
import styles from './TeamDetailedView.module.css';

interface ITeamDetailedView {
  teamScore: ITeamScore;
  selectedTeam: BaseTeam;
  teamRoundData?: ITeamRoundData;
  allAssets: IAsset[];
  teamCompletedTrades: OpenTradeComplete[];
  teamCompletedBilateralTrades: BilateralTrade[];
}
export const TeamDetailedView = memo((props: ITeamDetailedView) => {
  const { allAssets, teamScore, selectedTeam, teamRoundData, teamCompletedTrades, teamCompletedBilateralTrades } = props;
  const { teamID } = selectedTeam;

  const setError = useContext(setErrorContext);
  const { teams, markets, settings } = useContext(setupDataContext);
  const [footerDisplay, setFooterDisplay] = useState<string | undefined>(); // "portfolio" "cash" "notification" "asset"
  const [editAssetID, setEditAssetID] = useState<number>();

  const handleEditAsset = (assetID: IAsset['assetID']) => {
    setEditAssetID(assetID);
    setFooterDisplay('editAssetType');
  };

  const handleDeleteAsset = (asset: IAsset) => {
    const assetToRemove = {
      selectedAsset: asset.assetID,
      selectedTeam: teamID,
      operation: 'remove'
    };

    socket.emit('editAsset', assetToRemove, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Cannot remove asset ${asset.label} from ${asset.teamLabel}`,
          error
        });
      } else if (data === 'complete') {
        socket.emit('eventLog', {
          type: 'assets',
          log: `${asset.label} was removed from team ${asset.teamLabel}`
        });
      }
    });
  };
  const teamAssets = allAssets.filter(asset => asset.teamID === teamID);

  return (
    <div className={styles.innerCard}>
      <div
        style={{
          backgroundColor: teams.find(team => team.teamID === teamID)?.color
        }}
        className={styles.header}>
        <Tooltip
          placement='bottom'
          title={
            <>
              <h5 style={{ color: 'white' }}>Strategy Statement</h5>

              <p className={styles.strategyStatement}>
                {teamRoundData?.strategyStatement || <p className={styles.notThere}>No Strategy Statment Submitted</p>}
              </p>
            </>
          }>
          <h2>
            Team {teams.find(team => team.teamID === teamID)?.label} - {teamRoundData?.strapline || 'No Strapline Submitted'}
          </h2>
        </Tooltip>
      </div>
      <div className={styles.teamDetailedBody}>
        <div className={styles.teamStats}>
          <h4>Statistics</h4>

          <div className={styles.statContainer}>
            <table>
              <thead>
                <tr>
                  <th />
                  {markets.map(m => (
                    <th key={m.marketID}>{m.label === 'Carbon' ? 'C.Certs' : m.label}</th>
                  ))}
                  <th>Account</th>
                </tr>
                <tr>
                  <th />
                  {markets.map(m => (
                    <th key={m.marketID}>{m.volumeUnits}</th>
                  ))}
                  <th>{settings.currency} k</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.rowHeading}>Generation </td>
                  {teamScore.marketTotalGen.map((g, i) => (
                    <td key={i}>{g.totalLoad} </td>
                  ))}
                  <td>{-round(teamScore.carbon.generation, 2)} </td>
                  <td>{-round(teamScore.totals.generationCost + teamScore.totals.subsidyIncome)}</td>
                </tr>
                <tr>
                  <td className={styles.rowHeading}>Trading</td>
                  {teamScore.tradedVolumeMarket.map((tvm, i) => (
                    <td key={i}>{tvm.volume} </td>
                  ))}
                  <td>{teamScore.carbon.trading} </td>
                  <td>{teamScore.totals.tradingAccount}</td>
                </tr>
                <tr>
                  <td className={styles.rowHeading}>From Store</td>
                  <td>0</td>
                  <td>0</td>
                  <td>{-teamScore.hydrogen.changeInHydrogenStorage} </td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className={styles.rowHeading}>Customer Supply</td>
                  {teamScore.marketTotalSupply.map((s, i) => (
                    <td key={i}>{-s.totalLoad} </td>
                  ))}
                  <td>{round(teamScore.carbon.supply)} </td>
                  <td>{round(teamScore.totals.supplyIncome - teamScore.totals.supplyCosts)}</td>
                </tr>
                <br />
                <tr>
                  <td className={styles.rowHeading}>Wholesale Balance</td>

                  {teamScore.wholesaleBalance.map((wb, i) => (
                    <td key={i}>{wb.market.name !== 'hydrogen' ? wb.balance : wb.balance - teamScore.hydrogen.changeInHydrogenStorage} </td>
                  ))}
                  <td>{teamScore.totals.carbonCertificates}</td>
                  <td>{teamScore.totals.wholesaleBalanceAccount - teamScore.totals.pollutionPenalty}</td>
                </tr>

                <tr>
                  <td className={styles.rowHeading}>Customer Short</td>
                  <td colSpan={2}>{teamScore.customerTotalShortR}</td>
                  <td>{teamScore.hydrogen.unfulfilled}</td>
                  <td />
                  <td>-{teamScore.customerTotalShortRPenalty + teamScore.imbalance.hydrogenCustomerPenalty}</td>
                </tr>

                <br />

                {['sell', 'buy'].map(dealType => (
                  <tr key={dealType}>
                    <td className={styles.rowHeading}>Average {dealType === 'buy' ? 'Buy' : 'Sell'}</td>

                    {markets.map(m => (
                      <td key={m.marketID}>
                        {round(
                          mean([
                            ...teamCompletedTrades
                              .filter(
                                trade =>
                                  ((trade.openTrade.dealType === (dealType === 'buy' ? 'buy' : 'sell') &&
                                    trade.openTrade.offeringTeam.teamID === teamID) ||
                                    (trade.openTrade.dealType === (dealType === 'buy' ? 'sell' : 'buy') && trade.receivingTeam.teamID === teamID)) &&
                                  trade.openTrade.market.marketID === m.marketID
                              )
                              .map(trade => trade.openTrade.price),
                            ...teamCompletedBilateralTrades
                              .filter(
                                trade =>
                                  ((trade.dealType === (dealType === 'buy' ? 'buy' : 'sell') && trade.offeringTeam.teamID === teamID) ||
                                    (trade.dealType === (dealType === 'buy' ? 'sell' : 'buy') && trade?.receivingTeam?.teamID === teamID)) &&
                                  trade.market.marketID === m.marketID
                              )
                              .map(trade => trade.price)
                          ])
                        ) || '-'}
                      </td>
                    ))}

                    <td>
                      <Tooltip title='Open Trades & Bileral Trades'>
                        {
                          teamCompletedTrades.filter(
                            trade =>
                              (trade.openTrade.dealType === (dealType === 'buy' ? 'buy' : 'sell') &&
                                trade.openTrade.offeringTeam.teamID === teamID) ||
                              (trade.openTrade.dealType === (dealType === 'buy' ? 'sell' : 'buy') && trade.receivingTeam.teamID === teamID)
                          ).length
                        }{' '}
                        &{' '}
                        {
                          teamCompletedBilateralTrades.filter(
                            trade =>
                              (trade.dealType === (dealType === 'buy' ? 'buy' : 'sell') && trade.offeringTeam.teamID === teamID) ||
                              (trade.dealType === (dealType === 'buy' ? 'sell' : 'buy') && trade?.receivingTeam?.teamID === teamID)
                          ).length
                        }
                      </Tooltip>
                    </td>
                  </tr>
                ))}

                <br />
                <tr>
                  <td className={styles.rowHeading} colSpan={5}>
                    Cash Adjustment
                  </td>

                  <td className={styles.currency}>
                    {settings.currency}
                    {teamRoundData?.cashAdjustment || 0} <span>k</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.rowHeading} colSpan={5}>
                    Shareholder Sentiment
                  </td>

                  <td> {round((teamRoundData?.shareholderSentiment || 0) * 100)}%</td>
                </tr>
                <br />
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th />
                  <th>Open</th>
                  <th>Close</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.rowHeading}>Round Finances</td>
                  <td className={styles.currency}>
                    {settings.currency}
                    {teamRoundData?.opening || settings.startingBalance}
                    <span>k</span>
                  </td>
                  <td className={styles.currency}>
                    {settings.currency}
                    {(teamRoundData?.opening || settings.startingBalance) + teamScore.totals.profit}
                    <span>k</span>
                  </td>
                  <td className={styles.currency}>
                    {settings.currency}
                    {teamScore.totals.profit}
                    <span>k</span>
                  </td>
                </tr>
                <tr>
                  <td className={styles.rowHeading}>Share Price</td>
                  <td>{round(teamScore.sharePrice.thisRoundSharePrice, 2)}</td>
                  <td style={{ fontWeight: 'bold' }}>Growth</td>
                  <td>{round(teamScore.sharePrice.teamGrowth * 100, 2)}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.teamAssets}>
          <>
            <h4>Team Assets</h4>
            {teamAssets.length === 0 && <p className={styles.notThere}>No assets assigned to team</p>}
            {teamAssets.map(asset => (
              <div key={asset.assetID} className={styles.asset}>
                <div style={{ borderColor: asset.color }} className={styles.assetDescription}>
                  <p className={styles.assetLabel}>{asset.label}</p>

                  <p>{asset.typeShortLabel}</p>
                </div>

                <Button
                  type='primary'
                  size='small'
                  disabled={settings.currentGamePhase === 'review'}
                  className={styles.button}
                  shape='round'
                  onClick={() => handleEditAsset(asset.assetID)}
                  icon={<EditOutlined />}
                />

                <Popconfirm title='Are you sure?' placement='bottomRight' onConfirm={() => handleDeleteAsset(asset)} okText='Yes' cancelText='No'>
                  <Button
                    type='primary'
                    size='small'
                    disabled={settings.currentGamePhase === 'review'}
                    className={styles.button}
                    shape='round'
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </div>
            ))}
          </>
        </div>
      </div>
      <TeamDetailsFooter
        selectedTeam={selectedTeam}
        allAssets={allAssets}
        footerDisplay={footerDisplay}
        setFooterDisplay={setFooterDisplay}
        editAssetID={editAssetID}
      />
    </div>
  );
});
