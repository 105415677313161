import { Fragment, memo, useContext } from 'react';
import styles from './PortfolioSelection.module.css';
import { Asset } from '../Asset/Asset';

import { setupDataContext } from '../../context';

export const PortfolioSelection = memo(() => {
  const { settings, assets } = useContext(setupDataContext);

  const takenPortfolios = assets.filter(asset => asset.teamID !== null).map(asset => asset.portfolioID);

  return (
    <div className={styles.portfolio}>
      <h1>Portfolio Selection</h1>
      <h2>Summary Table</h2>
      <div className={styles.summaryTable}>
        <table>
          <tbody>
            {[...Array(settings.noOfPortfolios)].map((a, index) => {
              return (
                <tr key={index + 1} style={takenPortfolios.find(e => e === index + 1) === undefined ? {} : { backgroundColor: '#bd2b2b' }}>
                  <td className={styles.portfolioSummaryNumber}>
                    <h3>{index + 1}</h3>
                  </td>

                  {assets
                    .filter(asset => asset.portfolioID === index + 1)
                    .map(asset => {
                      return (
                        <td key={asset.assetID}>
                          <div className={styles.summaryTableCell}>
                            <div className={styles.icon}>
                              <img src={`/icons/${asset.icon}`} alt={`${asset.typeID} Icon`} />
                            </div>
                            <h3>{asset.typeLabel}</h3>
                          </div>
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {[...Array(settings.noOfPortfolios)].map((a, index) => {
        return (
          <Fragment key={index + 1}>
            <div
              style={takenPortfolios.find(e => e === index + 1) === undefined ? {} : { backgroundColor: '#bd2b2b' }}
              className={styles.portfolioRow}>
              <h1>{index + 1}</h1>
              {assets
                .filter(asset => asset.portfolioID === index + 1)
                .map(asset => {
                  return <Asset className={styles.portfolioAssets} asset={asset} key={asset.assetID} demo />;
                })}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
});
