import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { memo } from 'react';
import { BilateralTrade, BaseTeam } from '@powertrader/schema';
import { socket } from '../../../socket/socket';
import styles from './BilateralTradeRow.module.css';

interface IBilateralTradeRow {
  trade: BilateralTrade;
  teamID: BaseTeam['teamID'];
}
const BilateralTradeRow = memo(({ trade, teamID }: IBilateralTradeRow) => {
  const handleDeletTrade = () => {
    socket.emit('deleteBilateralTrade', trade.id);
  };

  const handleAcceptBilateralTrade = () => {
    socket.emit('acceptBilateralTrade', trade.id);
  };

  const handleRejectBilateralTrade = () => {
    socket.emit('rejectBilateralTrade', trade.id);
  };

  const rowColor = () => {
    if (trade.offeringTeam.teamID === teamID) {
      return trade?.receivingTeam?.color;
    }
    return trade.offeringTeam.color;
  };
  return (
    <div className={styles.rowContainer} style={{ borderColor: rowColor(), display: 'flex' }}>
      <div style={{ borderLeft: 'none', width: '25%' }} className={styles.column}>
        <p style={{ fontSize: '12px' }}>
          {trade.offeringTeam.teamID === teamID
            ? trade.dealType === 'sell'
              ? 'Offering'
              : 'Bidding'
            : trade.dealType === 'sell'
            ? 'Buy from'
            : 'Sell to'}
        </p>
      </div>
      <div style={{ borderColor: rowColor(), width: '28%' }} className={styles.column}>
        <p style={{ color: rowColor(), fontWeight: 'bold', fontSize: '14px' }}>
          {trade.offeringTeam.teamID === teamID ? trade?.receivingTeam?.label || 'Token' : trade.offeringTeam.label}
        </p>
      </div>
      <div style={{ borderColor: rowColor(), width: '12%' }} className={styles.column}>
        <p>{trade.volume}</p>
      </div>
      <div style={{ borderColor: rowColor(), width: '15%' }} className={styles.column}>
        <p>{trade.price}</p>
      </div>
      <div
        style={{
          borderColor: rowColor(),
          width: '20%',
          justifyContent: 'center'
        }}
        className={styles.column}>
        {trade.offeringTeam.teamID === teamID ? (
          <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeletTrade()} />
        ) : (
          <>
            <CheckCircleOutlined style={{ color: 'green', marginRight: '2px', cursor: 'pointer' }} onClick={() => handleAcceptBilateralTrade()} />
            <CloseCircleOutlined style={{ color: 'red', marginLeft: '2px', cursor: 'pointer' }} onClick={() => handleRejectBilateralTrade()} />
          </>
        )}
      </div>
    </div>
  );
});
export default BilateralTradeRow;
