import { DeleteOutlined } from '@ant-design/icons';
import { Select, Button, AutoComplete } from 'antd';
import { memo, useContext, useState } from 'react';
import styles from './AssetTypeControl.module.css';
import {
  AssetTypeAdjustmentTypes,
  IAssetTypeAdjustment,
  isNumericProperty,
  isValidCriterion,
  isValidAction,
  IAssetType,
  invalidCriterionProperties,
  invalidActionProperties
} from '@powertrader/schema';
import { startCase, uniqBy } from 'lodash';
import { setupDataContext } from '../../../context';

const { Option } = Select;

interface IAssetTypeAdjustmentInterface {
  handleSave: (newAdjustment: IAssetTypeAdjustment) => void;
  assetTypeAdjustment: IAssetTypeAdjustment;
  disabled?: boolean;
}

export const AssetTypeAdjustmentInterface = memo(({ handleSave, assetTypeAdjustment, disabled }: IAssetTypeAdjustmentInterface) => {
  const [editingKey, setEditingKey] = useState<'none' | 'criterion' | 'action'>();
  const [editingIndex, setEditingIndex] = useState(0);
  const [editingValue, setEditingValue] = useState('');
  const { assetTypeDefinitions, assetTypes } = useContext(setupDataContext);

  const getSuggestions = (prop: '' | keyof IAssetType) => {
    return uniqBy(
      assetTypes.reduce((result: { label: string; value: string }[], assetType) => {
        const newVal = prop && prop in assetType ? assetType[prop] : null;
        return typeof newVal === 'string' ? [...result, { label: newVal, value: newVal }] : result;
      }, []),
      'value'
    );
  };

  return (
    <div className={styles.container}>
      <header style={{ clear: 'right' }}>Criteria</header>
      <hr></hr>
      {assetTypeAdjustment.criteria.map((crit, index) => (
        <div key={crit.property} className={isValidCriterion(crit, assetTypeDefinitions) ? styles.row : styles.warningRow}>
          {index > 0 ? <p className={styles.left}>OR</p> : <p className={styles.left}>IF</p>}
          <span className={styles.property}>
            <Select
              showSearch
              optionFilterProp='children'
              disabled={disabled}
              value={startCase(crit.property)}
              onChange={prop => {
                assetTypeAdjustment.criteria[index].property = prop as '' | keyof IAssetType;
                handleSave(assetTypeAdjustment);
              }}
              className={styles.select}>
              {assetTypeDefinitions.map(
                def =>
                  !invalidCriterionProperties.includes(def.columnName) && (
                    <Option key={def.columnName} value={def.columnName}>
                      {startCase(def.columnName)}
                    </Option>
                  )
              )}
            </Select>
          </span>
          <span className={styles.middle}>is equal to</span>
          <span className={styles.right}>
            <AutoComplete
              disabled={disabled}
              value={editingKey === 'criterion' && editingIndex === index ? editingValue : crit.value}
              onFocus={() => {
                setEditingKey('criterion');
                setEditingIndex(index);
                setEditingValue(crit.value);
              }}
              onChange={value => {
                setEditingKey('criterion');
                setEditingIndex(index);
                setEditingValue(value);
              }}
              onBlur={() => {
                assetTypeAdjustment.criteria[index].value = editingValue;
                handleSave(assetTypeAdjustment);
                setEditingKey('none');
              }}
              options={getSuggestions(crit.property)}
              className={editingKey === 'criterion' && editingIndex === index ? styles.autocompleteGrey : styles.autocomplete}
            />
            <div className={styles.deleteContainer}>
              {!disabled && index > 0 && (
                <DeleteOutlined
                  onClick={() => {
                    assetTypeAdjustment.criteria.splice(index, 1);
                    handleSave(assetTypeAdjustment);
                  }}
                  className={styles.delete}
                />
              )}
            </div>
          </span>
        </div>
      ))}

      <span className={styles.plusRow}>
        <Button
          disabled={disabled}
          ghost
          size='small'
          shape='circle'
          onClick={() => {
            assetTypeAdjustment.criteria.push({
              property: '',
              value: ''
            });
            handleSave(assetTypeAdjustment);
          }}
          className={styles.button}>
          <strong>+</strong>
        </Button>
      </span>

      <header style={{ clear: 'right' }}>Actions</header>
      <hr></hr>
      {assetTypeAdjustment.actions.map((act, index) => (
        <div key={act.property} className={isValidAction(act, assetTypeDefinitions) ? styles.row : styles.warningRow}>
          {index > 0 ? <p className={styles.left}>AND</p> : <p className={styles.left}>DO</p>}

          <span className={styles.property}>
            <Select
              showSearch
              optionFilterProp='children'
              disabled={disabled}
              value={startCase(act.property)}
              onChange={prop => {
                assetTypeAdjustment.actions[index].property = prop as '' | keyof IAssetType;
                assetTypeAdjustment.actions[index].adjustmentType = 'exact';
                handleSave(assetTypeAdjustment);
              }}
              className={styles.select}>
              {assetTypeDefinitions.map(
                def =>
                  !invalidActionProperties.includes(def.columnName) && (
                    <Option key={def.columnName} value={def.columnName}>
                      {startCase(def.columnName)}
                    </Option>
                  )
              )}
            </Select>
          </span>
          <span className={styles.middle}>
            <Select
              showSearch
              optionFilterProp='children'
              disabled={disabled}
              value={act.adjustmentType}
              onChange={type => {
                assetTypeAdjustment.actions[index].adjustmentType = type;
                handleSave(assetTypeAdjustment);
              }}
              className={styles.select}>
              {AssetTypeAdjustmentTypes.map(
                type =>
                  (type === 'exact' || (type === 'multiply' && isNumericProperty(act.property, assetTypeDefinitions))) && (
                    <Option key={type} value={type}>
                      {type === 'exact' ? 'Exact' : 'Multiply'}
                    </Option>
                  )
              )}
            </Select>
          </span>
          <span className={styles.right}>
            <AutoComplete
              disabled={disabled}
              value={editingKey === 'action' && editingIndex === index ? editingValue : act.value}
              onFocus={() => {
                setEditingKey('action');
                setEditingIndex(index);
                setEditingValue(act.value);
              }}
              onChange={value => {
                setEditingKey('action');
                setEditingIndex(index);
                setEditingValue(value);
              }}
              onBlur={() => {
                assetTypeAdjustment.actions[index].value = editingValue;
                handleSave(assetTypeAdjustment);
                setEditingKey('none');
              }}
              options={getSuggestions(act.property)}
              className={editingKey === 'action' && editingIndex === index ? styles.autocompleteGrey : styles.autocomplete}
            />
            <div className={styles.deleteContainer}>
              {!disabled && index > 0 && (
                <DeleteOutlined
                  onClick={() => {
                    assetTypeAdjustment.actions.splice(index, 1);
                    handleSave(assetTypeAdjustment);
                  }}
                  className={styles.delete}
                />
              )}
            </div>
          </span>
        </div>
      ))}

      <span className={styles.plusRow}>
        <Button
          disabled={disabled}
          ghost
          size='small'
          shape='circle'
          onClick={() => {
            assetTypeAdjustment.actions.push({
              property: '',
              value: '',
              adjustmentType: 'exact'
            });
            handleSave(assetTypeAdjustment);
          }}
          className={styles.button}>
          <strong>+</strong>
        </Button>
      </span>
    </div>
  );
});
