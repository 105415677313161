import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import type {
  BilateralTrade,
  OpenTrade,
  OpenTradeComplete,
  IErrorProps,
  IUser,
  IControlData,
  IGameProgress,
  ISetupData,
  ITeamRoundData,
  IAllTeamAssets,
  IAsset,
  ILastTradedPrice
} from '@powertrader/schema';
import {
  TeamSwitch,
  setErrorContext,
  setupDataContext,
  teamAssetsContext,
  teamRoundDataContext,
  allTeamAssetsContext,
  gameProgressContext,
  controlDataContext,
  openTradesContext,
  bilateralTradesContext,
  openTradesCompletedContext,
  lastTradedPricesContext,
  socket
} from '@powertrader/core';

import './style.css';

import GamePlayArea from './Components/GamePlayArea/GamePlayArea';
import ControlArea from './Components/ControlArea/ControlArea';
import { LoginArea } from './Components/LoginArea/LoginArea';

function App() {
  const [user, setUser] = useState<IUser>();
  const [error, setError] = useState<IErrorProps>(); // {message, error}
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [requiresLogin, setRequiresLogin] = useState(false);
  const [setupData, setSetupData] = useState<ISetupData>(); // Things that are always fixed
  const [controlData, setControlData] = useState<IControlData>(); // Things are cotrolled like the weather!
  const [teamRoundData, setTeamRoundData] = useState<ITeamRoundData[]>(); // TeamRound Scores and Data
  const [controlPannel, setControlPannel] = useState<boolean | null>(null); // was set to null before
  const [teamAssets, setTeamAssets] = useState<IAsset[]>([]);
  const [allTeamAssets, setAllTeamAssets] = useState<IAllTeamAssets[]>([]);
  const [gameProgress, setGameProgress] = useState<IGameProgress>();
  const [openTrades, setOpenTrades] = useState<OpenTrade[]>([]);
  const [openTradesCompleted, setOpenTradesCompleted] = useState<OpenTradeComplete[]>([]);
  const [bilateralTrades, setBilateralTrades] = useState<BilateralTrade[]>([]);
  const [lastTradedPrices, setLastTradedPrices] = useState<ILastTradedPrice[]>([]);

  const logout = useCallback(() => {
    socket.emit('leaveRoom', user);
    setIsLoggedIn(false);
    sessionStorage.clear();
    window.location.reload();
    message.info('Logging Out');
  }, [user]);

  useEffect(() => {
    if (error) {
      if (user?.teamType === 'ADMIN') {
        message.error(error.message);
      } else {
        message.error('An error has occured and has been loggged, if this error persists, please refresh your browser');
      }
      socket.emit('errorLog', { ...error, userID: user?.userID });
    }
  }, [error, user?.teamType, user?.userID]);

  useEffect(() => {
    if (isLoggedIn && user?.userID && user?.teamID && user?.teamType) {
      socket.emit('getAllSetupData', { userID: user.userID, teamID: user.teamID, teamType: user.teamType }, (error: IErrorProps['error']) => {
        setError({ message: 'cannot get SetupData', error });
      });
    }
  }, [isLoggedIn, user?.teamID, user?.teamType, user?.userID]);

  useEffect(() => {
    const roundID = setupData?.teams.find(t => t.teamID === user?.teamID)?.currentRound;

    if (setupData?.settings && roundID !== undefined) {
      setGameProgress({
        roundID,
        scenarioID: roundID
      });
    }
  }, [setupData?.settings, setupData?.teams, user?.teamID]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem('user');

    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
      setIsLoggedIn(true);
      setRequiresLogin(false);

      if (foundUser.teamType === 'ADMIN') {
        setControlPannel(true);
      } else {
        setControlPannel(false);
      }
    } else {
      setRequiresLogin(true);
    }
  }, [setIsLoggedIn, setUser]);

  // useSocketTrades(
  //   socket,
  //   setOpenTrades,
  //   setOpenTradesCompleted,
  //   setLastTradedPrices,
  //   isLoggedIn,
  //   user,
  //   setupData
  // );

  useEffect(() => {
    if (isLoggedIn && user) {
      socket.on('connectionMade', () => {
        socket.emit('room', user);
      });

      socket.on('logoutUser', () => {
        logout();
      });

      socket.on('controlData', (data: IControlData) => {
        if (data !== undefined) {
          setControlData(prevState => ({ ...prevState, ...data }));
        }
      });
      socket.on('setupData', (data: ISetupData) => {
        if (data !== undefined && user) {
          const userColor = data.teams.find(team => team.teamID === user.teamID)?.color || 'black';

          setUser(() => ({
            ...user,
            color: userColor
          }));

          setSetupData({ ...data, user: { ...user, color: userColor } });
        }
      });
      socket.on('updateSetupData', (data: ISetupData) => {
        if (data !== undefined) {
          setSetupData(prevState => ({ ...prevState, ...data }));
        }
      });

      interface IGameData {
        teamRoundData: ITeamRoundData[];
        teamAssets: IAsset[];
        allTeamAssets: IAllTeamAssets[];
        teamID: number;
      }
      socket.on('gameData', (data: IGameData, from: number) => {
        if (data !== undefined && (user.teamType === 'ADMIN' || user.teamID === data.teamID)) {
          if (data.teamRoundData) {
            setTeamRoundData(data.teamRoundData);
          }
          if (data.teamAssets && user.teamType !== 'ADMIN') setTeamAssets(data.teamAssets);

          if (user.teamType === 'ADMIN' && (data.allTeamAssets || data.teamAssets)) {
            setAllTeamAssets(prevState => {
              let newState: IAllTeamAssets[] = prevState ? [...prevState] : [];
              const index = newState.findIndex(teamData => teamData.teamID === data.teamID);

              if ((newState.length === 0 || Array.isArray(data.allTeamAssets)) && data.allTeamAssets) {
                return data.allTeamAssets;
              }
              if (index === -1) {
                // if not found

                const teamType = setupData?.teams.find(team => team.teamID === data.teamID)?.type || 'PLAYER';
                if (teamType) {
                  newState.push({
                    ...data,
                    teamType
                  });
                } else {
                  setError({
                    message: `Received gamesData but couldn't get teamType for allTeamAsset Data`
                  });
                }
              } else if (prevState) {
                newState = prevState.map(teamData => (teamData.teamID === data.teamID ? { ...teamData, teamAssets: data.teamAssets } : teamData));
              } else {
                setError({
                  message: `Prev State is undefined in AllTeamAsset`
                });
              }

              return newState;
            });
          }
        } else if (user.teamType === 'ADMIN' || user.teamID === data.teamID) {
          setError({
            message: `Received gamesData but couldn't validate teamID ${user.teamID} and dataTeamID ${data.teamID}`
          });
        } else {
          setError({
            message: `gameData is undefined from ln ${from || 'unknown'}`
          });
        }
      });

      socket.on('updateSetupAssets', (data: IAsset[]) => {
        if (data !== undefined && setupData) {
          setSetupData({ ...setupData, assets: [] });
          // Orignal Type Assets
        }
      });

      // OPEN TRADE

      // NOTIFICATION SOCKETS
      socket.on('notification', (data: string) => {
        message.info(`${data}`);
      });

      socket.on('openTrades', (openTradeData: OpenTrade[], openTradesCompletedData?: OpenTradeComplete[]) => {
        setOpenTrades(openTradeData);
        if (openTradesCompletedData) {
          const splicedOpenTradesCompletedData = [...openTradesCompletedData];
          splicedOpenTradesCompletedData.sort((a, b) => new Date(a.tradedAt).getTime() - new Date(b.tradedAt).getTime()).splice(10);
          setOpenTradesCompleted(openTradesCompletedData);

          const lastTradedPricesTemp = setupData?.markets.map(market => {
            const filteredTrades = openTradesCompletedData.filter(st => st.openTrade.market.marketID === market.marketID);
            return {
              market,
              price: filteredTrades[filteredTrades.length - 1]?.openTrade.price
            };
          });

          if (lastTradedPricesTemp) setLastTradedPrices(lastTradedPricesTemp);
        }
      });

      socket.on('openTradeAdded', (newTrade: OpenTrade) => {
        if (newTrade)
          setOpenTrades(prevState => {
            const newState = [...prevState];
            newState.push(newTrade);
            return newState;
          });
      });

      socket.on('openTradeModified', (updatedTrade: OpenTrade) => {
        if (updatedTrade)
          setOpenTrades(prevState => {
            const newState = [...prevState];
            const tradeToModify = newState.findIndex(trade => trade.id === updatedTrade.id);
            if (tradeToModify !== -1) newState[tradeToModify] = updatedTrade;
            return newState;
          });
      });
      socket.on('openTradeAccepted', (parentTrade: OpenTrade, completedTrade: OpenTradeComplete, autoTrade?: boolean) => {
        if (!parentTrade || !completedTrade) throw new Error('Parent Trade or Completed Trade undefined');
        if (parentTrade.offeringTeam.teamID === user.teamID || (completedTrade.receivingTeam.teamID === user.teamID && autoTrade)) {
          if (openTradesCompleted.length < 10) {
            message.success(
              `${completedTrade.volume}${parentTrade.market.volumeUnits} of your ${
                completedTrade.openTrade.dealType === 'sell' ? 'offer to sell' : 'bid to buy'
              } at ${completedTrade.openTrade.price}${parentTrade.market.priceUnits} was accepted`,
              5
            );
          }
        }
        setOpenTrades(prevState => {
          const newState = [...prevState];
          const tradeIndex = newState.findIndex(trade => trade.id === parentTrade.id);
          if (tradeIndex !== -1) newState[tradeIndex] = parentTrade;
          return newState;
        });

        if (user.teamType === 'ADMIN' || parentTrade.offeringTeam.teamID === user.teamID || completedTrade.receivingTeam.teamID === user.teamID)
          setOpenTradesCompleted(prevState => {
            if (prevState.length >= 10) return prevState; // max 5 trades per round
            const newState = [...prevState];
            newState.push(completedTrade);
            return newState;
          });

        setLastTradedPrices(prevState => {
          const newState = [...prevState];
          const index = newState.findIndex(ns => ns.market.marketID === completedTrade.openTrade.market.marketID);
          if (index !== -1) {
            newState[index].price = completedTrade.openTrade.price;
          } else {
            newState.push({
              market: completedTrade.openTrade.market,
              price: completedTrade.openTrade.price
            });
          }
          return newState;
        });
      });
    }
    socket.on('resetTrading', () => {
      setOpenTrades([]);
      setOpenTradesCompleted([]);
    });

    socket.on('refresh', () => {
      window.location.reload();
    });

    return () => {
      socket.off('openTrades');
      socket.off('openTradeAdded');
      socket.off('openTradeModified');
      socket.off('openTradeAccepted');
      socket.off('resetTrading');

      socket.off('connect');
      socket.off('updateSetupAssets');
      socket.off('controlData');
      socket.off('gameData');
      socket.off('setupData');
      socket.off('updateSetupData');

      socket.off('nextRound');
      socket.off('logoutUser');
      socket.off('marketData');
      socket.off('notification');
      socket.off('successNotification');
      socket.off('refresh');
    };
  }, [isLoggedIn, logout, setupData?.teams, user, setupData, openTradesCompleted.length]);

  if (
    !isLoggedIn ||
    !(
      setupData &&
      controlData &&
      ((!controlPannel && teamAssets) || (controlPannel && allTeamAssets.length)) &&
      gameProgress &&
      teamRoundData &&
      controlPannel !== null &&
      user
    )
  ) {
    return (
      <>
        <setErrorContext.Provider value={setError}>
          {' '}
          <LoginArea
            setUser={setUser}
            setIsLoggedIn={setIsLoggedIn}
            setControlPannel={setControlPannel}
            requiresLogin={requiresLogin}
            isDemoSite={window.location.origin === 'https://playdemo.powertrader.online'}
          />
        </setErrorContext.Provider>
      </>
    );
  }
  const serverRoundStartTime = teamRoundData.find(tr => tr.roundID === gameProgress.roundID)?.startTime;
  const storedStartTime = serverRoundStartTime ? new Date(serverRoundStartTime) : undefined;
  const gameEnded = !!teamRoundData.find(tr => tr.roundID === 3)?.endTime;
  return (
    <setErrorContext.Provider value={setError}>
      <setupDataContext.Provider value={setupData}>
        <bilateralTradesContext.Provider value={bilateralTrades}>
          <teamRoundDataContext.Provider value={teamRoundData}>
            <gameProgressContext.Provider value={gameProgress}>
              <controlDataContext.Provider value={controlData}>
                <openTradesContext.Provider value={openTrades}>
                  <openTradesCompletedContext.Provider value={openTradesCompleted}>
                    <lastTradedPricesContext.Provider value={lastTradedPrices}>
                      {controlPannel && (
                        <allTeamAssetsContext.Provider value={allTeamAssets}>
                          <ControlArea />
                        </allTeamAssetsContext.Provider>
                      )}
                      {!controlPannel && (
                        <teamAssetsContext.Provider value={teamAssets}>
                          <GamePlayArea
                            setGameProgress={setGameProgress}
                            storedStartTime={storedStartTime}
                            setTeamAssets={setTeamAssets}
                            gameEnded={gameEnded}
                          />
                        </teamAssetsContext.Provider>
                      )}

                      {user.role === 'ADMIN' && <TeamSwitch user={user} teams={setupData.teams} />}
                    </lastTradedPricesContext.Provider>
                  </openTradesCompletedContext.Provider>
                </openTradesContext.Provider>
              </controlDataContext.Provider>
            </gameProgressContext.Provider>
          </teamRoundDataContext.Provider>
        </bilateralTradesContext.Provider>
      </setupDataContext.Provider>
    </setErrorContext.Provider>
  );
}
export default App;
