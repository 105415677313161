import {
  BilateralTrade,
  IAssetsToScore,
  ISettings,
  ITeamRoundData,
  ITeamBalances,
  ICalcTeamScore,
  OpenTradeComplete,
  Market
} from '@powertrader/schema';

import { getLoadTotalsFromAssets, getMarket, getMarketTotals } from './getFunctions';

import { teamBalances } from './teamBalances';

const memorizedResults = new Map<string, ITeamBalances>();

interface ICalc {
  markets: Market[];
  electricityShortfallPrice: number;
  electricityBuyBackPrice: number;
  hydrogenShortfallPrice: number;
  scenarioID: number;
  roundID: number;
  teamDetails: IAssetsToScore;
  carbonTax: number;
  trades: OpenTradeComplete[];
  targetEmissionIntensity: number;
  bilateralTrades: BilateralTrade[];
  hydrogenStoredAtRoundStart: number;
}

const calc = ({
  markets,
  electricityShortfallPrice,
  electricityBuyBackPrice,
  hydrogenShortfallPrice,
  scenarioID,
  roundID,
  teamDetails,
  carbonTax,
  trades,
  targetEmissionIntensity,
  bilateralTrades,
  hydrogenStoredAtRoundStart
}: ICalc) => {
  // console.log({
  //   markets,
  //   electricityShortfallPrice,
  //   electricityBuyBackPrice,
  //   hydrogenShortfallPrice,
  //   scenarioID,
  //   roundID,
  //   teamDetails,
  //   carbonTax,
  //   trades,
  //   targetEmissionIntensity,
  //   bilateralTrades,
  //   hydrogenStoredAtRoundStart
  // });
  const energyMarkets = markets.filter(market => market.type === 'powerExchange');
  const hydrogenMarket = getMarket(markets, 'hydrogenMarket');

  // generators + producers
  const marketTotalGen = getMarketTotals({
    markets: energyMarkets,
    assets: teamDetails.teamAssets.filter(a => a.electricityLoadType === 'generator'),
    scenarioID,
    roundID
  });

  marketTotalGen.push(
    getLoadTotalsFromAssets(
      teamDetails.teamAssets.filter(a => a.hydrogenLoadType === 'producer'),
      hydrogenMarket,
      roundID,
      scenarioID
    )
  );

  // customers + consumers
  const marketTotalSupply = getMarketTotals({
    markets: energyMarkets,
    assets: teamDetails.teamAssets.filter(a => a.electricityLoadType === 'customer'),
    scenarioID,
    roundID
  });

  marketTotalSupply.push(
    getLoadTotalsFromAssets(
      teamDetails.teamAssets.filter(a => a.hydrogenLoadType === 'consumer'),
      hydrogenMarket,
      roundID,
      scenarioID
    )
  );

  // const nonElectricalProducersAssets = teamDetails.teamAssets.filter(
  //   asset => asset.electricityLoadType === null
  // );
  // const nonElectricalProducersTotal = getLoadTotalsFromAssets(
  //   nonElectricalProducersAssets,
  //   hydrogenMarket,
  //   roundID,
  //   scenarioID
  // );

  const teamBalancesResults = teamBalances({
    marketTotalGen,
    marketTotalSupply,

    teamAssets: teamDetails.teamAssets,
    scenarioID,
    roundID,
    markets,

    electricityShortfallPrice,
    electricityBuyBackPrice,
    hydrogenShortfallPrice,
    carbonTax,
    trades,
    teamID: teamDetails.teamID,
    targetEmissionIntensity,
    bilateralTrades,
    hydrogenStoredAtRoundStart
  });

  const result: ITeamBalances = {
    marketTotalGen,
    marketTotalSupply,
    ...teamBalancesResults
  };
  return result;
};

const myMemo = (deps: ICalc) => {
  const result =
    memorizedResults.get(JSON.stringify(deps)) ||
    (memorizedResults.set(JSON.stringify(deps), calc(deps)) && memorizedResults.get(JSON.stringify(deps)));

  return result;
};

export interface ICalcTeamScores {
  settings: ISettings;
  markets: Market[];
  scenarioID: number;
  roundID: number;
  assetsToScore: IAssetsToScore[];
  cashAndStrategy: ITeamRoundData[];
  trades: OpenTradeComplete[];
  bilateralTrades: BilateralTrade[];
  lastRoundScores: ITeamRoundData[];
}
export const calcTeamScores = ({
  settings,
  markets,
  scenarioID,
  roundID,
  assetsToScore,
  cashAndStrategy,
  trades,
  bilateralTrades,
  lastRoundScores
}: ICalcTeamScores) => {
  const teamScores: ICalcTeamScore[] = [];

  const { electricityShortfallPrice, electricityBuyBackPrice, hydrogenShortfallPrice, carbonTax, targetEmissionIntensity } = settings;
  assetsToScore.forEach(teamDetails => {
    if (teamDetails.teamType !== 'ADMIN') {
      const cashAdjustment = cashAndStrategy.find(cs => cs.teamID === teamDetails.teamID)?.cashAdjustment || 0;

      const hydrogenStoredAtRoundStart = lastRoundScores.find(cs => cs.teamID === teamDetails.teamID)?.hydrogenStored || 0;

      const calcResults = myMemo({
        markets,
        electricityShortfallPrice,
        electricityBuyBackPrice,
        hydrogenShortfallPrice,
        scenarioID,
        roundID,
        teamDetails,
        carbonTax,
        trades: trades.filter(t => t.openTrade.offeringTeam.teamID === teamDetails.teamID || t.receivingTeam.teamID === teamDetails.teamID),
        targetEmissionIntensity,
        bilateralTrades: bilateralTrades.filter(t => t.offeringTeam.teamID === teamDetails.teamID || t?.receivingTeam?.teamID === teamDetails.teamID),
        hydrogenStoredAtRoundStart
      });
      if (!calcResults) throw new Error('calcResults is undefined');

      const { totals, imbalance } = calcResults;
      totals.income =
        totals.supplyIncome +
        totals.tradingIncome +
        totals.subsidyIncome +
        totals.wholesaleBalanceAccountLong +
        (cashAdjustment > 0 ? cashAdjustment : 0);

      totals.expenditure =
        totals.supplyCosts +
        totals.pollutionPenalty +
        imbalance.totalPenalty +
        totals.generationCost +
        totals.tradingExpenditure +
        (cashAdjustment < 0 ? cashAdjustment : 0);
      totals.profit = totals.income - totals.expenditure;

      teamScores.push({
        teamID: teamDetails.teamID,
        teamType: teamDetails.teamType,
        ...calcResults
      });
    }
  });

  return teamScores;
};
