import { memo, useContext } from 'react';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { IControlDisplay } from '@powertrader/schema';
import styles from './DisplayController.module.css';
import { setupDataContext } from '../../../context';
import { ControlOutlined, DownOutlined, LineChartOutlined, SwitcherOutlined, TableOutlined } from '@ant-design/icons';

interface IDisplayController {
  style?: React.CSSProperties;
  onClick: IControlDisplay['setControlDisplay'];
  round: number;
}

export const DisplayController = memo(({ onClick, style, round }: IDisplayController) => {
  const { settings } = useContext(setupDataContext);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Control',
      icon: <ControlOutlined />,
      onClick: () => onClick('controls')
    },
    {
      key: '2',
      label: 'Charts',
      icon: <LineChartOutlined />,
      onClick: () => onClick('review')
    },
    {
      key: '3',
      label: 'Config',
      icon: <SwitcherOutlined />,
      onClick: () => onClick('gameFlow')
    },
    {
      key: '4',
      label: 'Logs',
      icon: <TableOutlined />,
      onClick: () => onClick('eventLog')
    }
  ];

  return (
    <div style={style} className={styles.innerCard}>
      <h3>
        {settings.currentGamePhase === 'setup'
          ? 'Setup'
          : settings.currentGamePhase === 'portfolio'
          ? 'Portfolio'
          : settings.currentGamePhase === 'demo'
          ? 'Demo'
          : `Round ${round}`}
      </h3>
      <Dropdown menu={{ items }}>
        <Space>
          <Button shape='round' ghost>
            Switch View
            <DownOutlined />
          </Button>
        </Space>
      </Dropdown>
    </div>
  );
});
