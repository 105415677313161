import { clamp, clone, mean } from 'lodash';
import { ICashAndStrategy, ISharePriceFactors } from '@powertrader/schema';

const growthPerformance = ({ bestExpectation, worstExpectation, growthLimit }: ISharePriceFactors, paramRel: number, paramThisRound: number) => {
  const expectationRange = bestExpectation - worstExpectation;
  // const averageExpectation = (bestExpectation + worstExpectation) / 2

  const adjTemp = (2 * (paramThisRound - paramRel)) / expectationRange;

  return growthLimit * clamp(adjTemp, -1, 1);
};

export interface ITeamSharePrice {
  sharePriceFactors: ISharePriceFactors[];
  cashAndStrategy: ICashAndStrategy[];
  teamID: number;
  lastRoundSharePrice: number;
  averageProfit?: number;
  profitThisRound: number;
  physicalEmissionsThisRound: number;
  carbonCertsThisRound: number;
  tradesThisRound: number;
  tradesLastRound: number;
}
export const teamSharePrice = ({
  sharePriceFactors,
  cashAndStrategy,
  teamID,
  lastRoundSharePrice,
  averageProfit,
  profitThisRound,
  physicalEmissionsThisRound,
  carbonCertsThisRound,
  tradesThisRound,
  tradesLastRound
}: ITeamSharePrice) => {
  const financialAbs = sharePriceFactors.find(spf => spf.performanceType === 'financial' && spf.absOrRel === 'absolute');
  const financialRel = sharePriceFactors.find(spf => spf.performanceType === 'financial' && spf.absOrRel === 'relative');
  const emissionsAbs = sharePriceFactors.find(spf => spf.performanceType === 'emissions' && spf.absOrRel === 'absolute');
  const emissionsRel = sharePriceFactors.find(spf => spf.performanceType === 'emissions' && spf.absOrRel === 'relative');
  const tradingAbs = sharePriceFactors.find(spf => spf.performanceType === 'trading' && spf.absOrRel === 'absolute');
  const tradingRel = sharePriceFactors.find(spf => spf.performanceType === 'trading' && spf.absOrRel === 'relative');

  if (averageProfit === undefined) {
    // also report this error
    // socket.emit('errorLog', {
    //   type: 'calculation',
    //   message: `Cannot get average profit - teamID ${teamID}`
    // });
  }
  if (!financialAbs || !financialRel || !emissionsAbs || !emissionsRel || !tradingAbs || !tradingRel) {
    // Cannot calculate team shareprice growth as x is missing
    // socket.emit('errorLog', {
    //   type: 'calculation',
    //   message: `Cannot calculate team sharePrice due to missing sharePriceFactor
    //   financialAbs:${!!financialAbs},
    //   financialRel:${!!financialRel},
    //   emissionsAbs:${!!emissionsAbs},
    //   emissionsRel:${!!emissionsRel},
    //   tradingAbs:${!!tradingAbs},
    //   tradingRel:${!!tradingRel}
    //   `
    // });

    return {
      financialAbsoluteGrowth: 0,
      financialRelativeGrowth: 0,
      emissionsAbsoluteGrowth: 0,
      emissionsRelativeGrowth: 0,
      tradingAbsoluteGrowth: 0,
      tradingRelativeGrowth: 0,
      shareholderSentiment: 0,
      teamGrowth: 0,
      thisRoundSharePrice: lastRoundSharePrice
    };
  }

  const shareholderSentiment = cashAndStrategy.find(cs => cs.teamID === teamID)?.shareholderSentiment || 0;

  const financialAbsoluteGrowth = growthPerformance(
    financialAbs,
    mean([financialAbs.bestExpectation, financialAbs.worstExpectation]),
    profitThisRound
  );
  const financialRelativeGrowth = averageProfit !== undefined ? growthPerformance(financialRel, averageProfit, profitThisRound) : 0;
  const emissionsAbsoluteGrowth = growthPerformance(
    emissionsAbs,
    mean([emissionsAbs.bestExpectation, emissionsAbs.worstExpectation]),
    physicalEmissionsThisRound
  );
  const emissionsRelativeGrowth = growthPerformance(emissionsRel, 0, carbonCertsThisRound);
  const tradingAbsoluteGrowth = growthPerformance(tradingAbs, mean([tradingAbs.bestExpectation, tradingAbs.worstExpectation]), tradesThisRound);
  const tradingRelativeGrowth = growthPerformance(tradingRel, tradesLastRound, tradesThisRound);

  const teamGrowth =
    financialAbsoluteGrowth +
    financialRelativeGrowth +
    emissionsAbsoluteGrowth +
    emissionsRelativeGrowth +
    tradingAbsoluteGrowth +
    tradingRelativeGrowth +
    shareholderSentiment;

  const thisRoundSharePrice = lastRoundSharePrice * (1 + teamGrowth);

  return {
    financialAbsoluteGrowth,
    financialRelativeGrowth,
    emissionsAbsoluteGrowth,
    emissionsRelativeGrowth,
    tradingAbsoluteGrowth,
    tradingRelativeGrowth,
    shareholderSentiment,
    teamGrowth,
    thisRoundSharePrice
  };
};
