import { memo } from 'react';
import classNames from 'classnames';
import { IAsset, IAssetType } from '@powertrader/schema';
import styles from './Asset.module.css';
import { DetailedView } from './DetailedView';

interface IAssetProps {
  asset: IAsset | IAssetType;
  details?: 'show' | 'block';
  demo?: boolean;
  className?: string;
  disabled?: boolean;
}
export const Asset = memo(({ asset, details, demo = false, className, disabled }: IAssetProps) => {
  return (
    <div className={classNames([className, styles.innerCard])} style={{ borderColor: asset.color }}>
      <DetailedView details={details} asset={asset} demo={demo} disabled={disabled} />
    </div>
  );
});
