import { memo, useContext } from 'react';
import { Select } from 'antd';
import { setupDataContext } from '../../../context';

interface IScenarioSelect {
  scenario: number | undefined;
  handleScenarioChange: (newValue: number) => void;
  className?: string;
  disabled?: boolean;
}
export const ScenarioSelect = memo(({ scenario, handleScenarioChange, className, disabled }: IScenarioSelect) => {
  const { Option } = Select;
  const { scenarioDefinition } = useContext(setupDataContext);

  return (
    <div className={className}>
      <Select
        disabled={disabled}
        showSearch
        placeholder='Select a Scenario'
        optionFilterProp='children'
        style={{ width: '190px' }}
        value={scenario}
        onChange={option => handleScenarioChange(option)}>
        {scenarioDefinition.map(sd => (
          <Option key={sd.scenarioID} value={sd.scenarioID}>
            {sd.label}
          </Option>
        ))}
      </Select>
    </div>
  );
});
