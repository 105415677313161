import { memo, useContext } from 'react';
import { Button, notification, Switch } from 'antd';
import { IAsset, IErrorProps, BaseTeam } from '@powertrader/schema';
import { socket } from '../../../socket/socket';
import { setErrorContext, setupDataContext } from '../../../context';

import styles from './Footer.module.css';

import { Notification } from './Footer/Notification';
import { Portfolio } from './Footer/Portfolio';
import { AddAssetToTeam } from './Footer/AddAssetToTeam';
import { TeamAccountAdjustments } from './Footer/TeamAccountAdjustments';
import { EditAssetType } from './Footer/EditAssetType';

interface IFooter {
  allAssets: IAsset[];
  selectedTeam: BaseTeam;
  footerDisplay: string | undefined;
  setFooterDisplay: (value: string | undefined) => void;
  editAssetID: IAsset['assetID'] | undefined;
}

export const TeamDetailsFooter = memo(({ allAssets, selectedTeam, footerDisplay, setFooterDisplay, editAssetID }: IFooter) => {
  const setError = useContext(setErrorContext);
  const { settings } = useContext(setupDataContext);
  const handleDemoSwitch = (checked: boolean) => {
    socket.emit('updateTeamType', { teamID: selectedTeam.teamID, isPlayer: checked }, (error: IErrorProps['error']) => {
      if (error) {
        setError({ message: 'Cannot change team type', error });
      }
    });
  };

  if (footerDisplay === 'adjustment') {
    return <TeamAccountAdjustments setFooterDisplay={setFooterDisplay} selectedTeam={selectedTeam} />;
  }
  if (footerDisplay === 'notification') {
    return <Notification setFooterDisplay={setFooterDisplay} selectedTeam={selectedTeam} />;
  }
  if (footerDisplay === 'portfolio') {
    return <Portfolio setFooterDisplay={setFooterDisplay} selectedTeam={selectedTeam} allAssets={allAssets} />;
  }
  if (footerDisplay === 'asset') {
    return <AddAssetToTeam setFooterDisplay={setFooterDisplay} selectedTeam={selectedTeam} allAssets={allAssets} />;
  }

  if (footerDisplay === 'editAssetType') {
    const asset = allAssets.find(asset => asset.assetID === editAssetID);
    if (asset && editAssetID) {
      return <EditAssetType editAssetID={editAssetID} asset={asset} selectedTeam={selectedTeam} setFooterDisplay={setFooterDisplay} />;
    }
    setError({
      message: `Error displaying Footer to Edit Asset ${editAssetID || 'unknown'}`
    });
  }

  return (
    <div className={styles.footer}>
      <Button type='primary' size='small' shape='round' className={styles.footerButton} onClick={() => setFooterDisplay('adjustment')}>
        Account Adjustments
      </Button>
      <Button type='primary' size='small' shape='round' className={styles.footerButton} onClick={() => setFooterDisplay('notification')}>
        Send Notification
      </Button>
      <Button type='primary' size='small' shape='round' className={styles.footerButton} onClick={() => setFooterDisplay('portfolio')}>
        Assign Portfolio
      </Button>
      <Button
        type='primary'
        size='small'
        shape='round'
        disabled={settings.currentGamePhase === 'review'}
        className={styles.footerButton}
        onClick={() => setFooterDisplay('asset')}>
        Add Asset
      </Button>
      <Switch
        className={styles.demoSwitch}
        checkedChildren='Player'
        unCheckedChildren='Demo'
        checked={selectedTeam.type === 'PLAYER'}
        onClick={checked => handleDemoSwitch(checked)}
      />
    </div>
  );
});
