import { useState, useContext, memo } from 'react';
import { Button, Select, message } from 'antd';
import { IErrorProps, IAsset, BaseTeam } from '@powertrader/schema';

import { setupDataContext, setErrorContext } from '../../../../context';
import { socket } from '../../../../socket/socket';

import styles from '../Footer.module.css';

const { Option } = Select;

interface IPortfolio {
  setFooterDisplay: (value: string | undefined) => void;
  selectedTeam: BaseTeam;
  allAssets: IAsset[];
}

export const Portfolio = memo(({ setFooterDisplay, selectedTeam, allAssets }: IPortfolio) => {
  const { assets, settings } = useContext(setupDataContext);
  const setError = useContext(setErrorContext);
  const [assignPortfolio, setAssignPortfolio] = useState<number>(); // requested:

  const handleAssignPortfolio = () => {
    if (!assignPortfolio) {
      message.error(`No portfolio selected - cannot add assets to team ${selectedTeam.label}`);
      return;
    }
    let isPortfolioAvailible = true;
    const takenAssets = allAssets.filter(asset => asset.teamID !== null);
    const assetsInPorfolio = assets
      .filter(asset => asset.portfolioID === assignPortfolio)
      .map(asset => {
        return {
          label: asset.label,
          price: 0,
          selectedAsset: asset.assetID,
          selectedTeam: selectedTeam.teamID,
          operation: 'add'
        };
      });

    takenAssets.forEach(takenAsset => {
      assetsInPorfolio.forEach(wantedAsset => {
        if (wantedAsset.selectedAsset === takenAsset.assetID) {
          isPortfolioAvailible = false;
        }
      });
    });

    if (!isPortfolioAvailible) {
      socket.emit('eventLog', {
        type: 'assets',
        log: 'All or part of this portfolio is assigned to another team'
      });
    } else {
      socket.emit('editAsset', assetsInPorfolio, (error: IErrorProps['error'], data: 'complete') => {
        if (error) {
          setError({
            message: `Cannot add portfolio ${assignPortfolio} to team ${selectedTeam.label}`,
            error
          });
        } else if (data === 'complete') {
          socket.emit('eventLog', {
            type: 'assets',

            log: `Assets  ${assetsInPorfolio.map(asset => ` ${asset.label}`).toString()} were added to team ${selectedTeam.label}`
          });
        }
      });
    }

    setAssignPortfolio(undefined);
  };

  return (
    <div style={{ borderColor: selectedTeam.color }} className={styles.footer}>
      <Select
        showSearch
        placeholder='Select an Portfolio'
        optionFilterProp='children'
        value={assignPortfolio}
        className={styles.potfolioSelect}
        dropdownClassName={styles.porfolioSelectDropdown}
        onChange={option => {
          setAssignPortfolio(+option);
        }}>
        {[...Array(settings.noOfPortfolios)].map((a, index) => {
          const assetsInPortfolio = allAssets.filter(asset => asset.portfolioID === index + 1);

          let alreadyTaken = false;
          assetsInPortfolio.forEach(asset => {
            if (asset.teamID !== null) alreadyTaken = true;
          });
          if (alreadyTaken) return null;

          return (
            <Option key={index} value={index + 1}>
              {index + 1}:{assetsInPortfolio.map(asset => ` ${asset.label}`).toString()}
            </Option>
          );
        })}
      </Select>
      <Button type='primary' size='small' shape='round' onClick={() => handleAssignPortfolio()}>
        Assign Portfolio
      </Button>
      <Button type='primary' size='small' shape='round' className={styles.returnButton} onClick={() => setFooterDisplay(undefined)}>
        Return
      </Button>
    </div>
  );
});
