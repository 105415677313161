import moment from 'moment';
import 'chartjs-adapter-moment';
import { OpenTradeComplete, Market } from '@powertrader/schema';
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js';

export function tradeChartOptions(currency: string): ChartOptions {
  return {
    responsive: true,
    elements: {
      point: {
        radius: 0,
        hitRadius: 10
      },
      line: {
        fill: false
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: { font: { size: 14 } }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
          padding: 5,
          font: { size: 18 }
        },
        //@ts-ignore This options works but TS error's
        type: 'time',
        // distribution: 'linear',
        stacked: true,
        grid: {
          display: true
        },
        ticks: {
          font: { size: 18 },
          //@ts-ignore This options works but TS error's
          maxTicksLimit: 10,
          source: 'auto'
        }
      },
      y: {
        title: {
          display: true,
          text: `Trade Price (${currency}k/GWh & ${currency}k/kt)`,
          padding: 5,
          font: { size: 18 }
        },
        type: 'linear',
        display: true,

        position: 'left',

        grid: {
          display: true
        },
        beginAtZero: true,
        ticks: {
          font: { size: 18 }
        }
      },
      y1: {
        title: {
          display: true,
          text: 'Volume (GWh)',
          padding: 5,
          font: { size: 18 }
        },
        stacked: true,
        type: 'linear',
        display: true,
        position: 'right',

        grid: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          font: { size: 18 }
        }
      }
    }
  };
}
interface ITradeChartDatasets {
  filteredTrades: OpenTradeComplete[];
  filteredMarkets: Market[];
}
const sumValue = (array: any[], key: string) => array.reduce((a, b) => a + (b[key] || 0), 0);
export function tradeChartDatasets({ filteredTrades, filteredMarkets }: ITradeChartDatasets): ChartData {
  const sortedTrades = filteredTrades.sort((a, b) => new Date(a.tradedAt).getTime() - new Date(b.tradedAt).getTime());

  const tradeData = (marketID: Market['marketID']) =>
    sortedTrades
      .filter(st => st.openTrade.market.marketID === marketID)
      .map(ct => ({
        x: moment.utc(ct.tradedAt).local().format(),
        y: ct.openTrade.price
      }));

  const volumeOfTradesPerMinute = (marketID: number) => {
    const result: { x: number; y: number }[] = [];
    const sortedTradesMarket = sortedTrades.filter(stm => stm.openTrade.market.marketID === marketID);

    if (sortedTradesMarket.length) {
      const positionOfLastTrade = sortedTradesMarket.length - 1;
      const firstTradeTime = new Date(sortedTrades[0].tradedAt).getTime();
      const numberOfMinutes = Math.ceil((new Date(sortedTradesMarket[positionOfLastTrade].tradedAt).getTime() - firstTradeTime) / 60000);
      let splitPointTime = 0;

      for (let index = 0; index < numberOfMinutes; index++) {
        let first: number;
        let last: number;

        if (splitPointTime === 0) {
          first = firstTradeTime;
          last = first + 60000;
        } else {
          first = splitPointTime + 1;
          last = splitPointTime + 60000;
        }

        result.push({
          y: sumValue(
            sortedTradesMarket.filter(st => new Date(st.tradedAt).getTime() >= first && new Date(st.tradedAt).getTime() <= last),
            'volume'
          ),
          x: first + (last - first) / 2
        });
        splitPointTime = last + 1;
      }
    }
    return result;
  };

  const datasets: ChartDataset[] = [];

  const marketColor = ['rgb(236, 147, 47)', 'rgb(29, 100, 140)', 'rgb(0,0,0)', 'rgb(200,200,200)'];

  const marketColorBar = ['rgba(236, 147, 47,0.7)', 'rgba(29, 100, 140,0.7)', 'rgba(0,0,0,0.7)', 'rgba(200,200,200,0.7)'];

  const lineData = filteredMarkets.map(market => {
    const data = tradeData(market.marketID) as any;
    const dataset: ChartDataset = {
      label: market.label,
      type: 'line',
      tension: 0,
      data: data.length ? data : [],
      fill: false,
      borderColor: marketColor[market.marketID - 1],
      backgroundColor: marketColor[market.marketID - 1],
      pointBorderColor: marketColor[market.marketID - 1],
      pointBackgroundColor: marketColor[market.marketID - 1],
      pointHoverBackgroundColor: marketColor[market.marketID - 1],
      pointHoverBorderColor: marketColor[market.marketID - 1],
      yAxisID: 'y'
    };
    return dataset;
  });

  datasets.push(...lineData);

  const barData = filteredMarkets.map(market => {
    const data = volumeOfTradesPerMinute(market.marketID);
    const dataset: ChartDataset = {
      type: 'bar',
      label: `${market.label} Volume`,
      barThickness: 'flex',
      data: data.length ? volumeOfTradesPerMinute(market.marketID) : [],
      // fill: true,
      backgroundColor: marketColorBar[market.marketID - 1],
      borderColor: marketColorBar[market.marketID - 1],
      hoverBackgroundColor: marketColorBar[market.marketID - 1],
      hoverBorderColor: marketColorBar[market.marketID - 1],
      yAxisID: 'y1'
    };
    return dataset;
  });
  datasets.push(...barData);

  return {
    datasets
  };
}
