import { DownOutlined } from '@ant-design/icons';
import { Tooltip, TooltipProps } from 'antd';
import { memo } from 'react';
import styles from './TradeRowLabel.module.css';

interface ITradeRowLabel {
  text: string;
  enableTooltip: boolean;
  tooltipText: string;
  tooltipPlacement: TooltipProps['placement'];
  showArrows?: boolean;
  arrowDirection?: 'up' | 'down';
  onClick?: () => void;
}

export const TradeRowLabel = memo(
  ({ text, enableTooltip, tooltipText, tooltipPlacement, showArrows = false, arrowDirection, onClick }: ITradeRowLabel) => {
    return (
      <h5
        className={styles.sellTitle}
        style={{ cursor: typeof onClick === 'function' ? 'pointer' : 'auto', minWidth: showArrows ? '260px' : 'unset' }}
        onClick={onClick}>
        <Tooltip placement={tooltipPlacement} title={enableTooltip ? tooltipText : ''}>
          {showArrows && <DownOutlined rotate={arrowDirection === 'up' ? 180 : 0} className={styles.arrow} />}
          {text}
          {showArrows && <DownOutlined rotate={arrowDirection === 'up' ? 180 : 0} className={styles.arrow} />}
        </Tooltip>
      </h5>
    );
  }
);
