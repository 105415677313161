import { IAsset, IAssetType, IGameProgress, Market } from '@powertrader/schema';
import { round } from 'lodash';
import { socket } from '../socket/socket';
// import { socketContext } from '../context';

/** Maximum load for an asset that can be generated in a given market/scenario */
export const getMaxScenarioMarketLoad = (asset: IAsset, marketID: number, scenarioID: number) => {
  const result = asset.maxScenarioMarketLoads.find(sml => sml.marketID === marketID && sml.scenarioID === scenarioID)?.maxLoad;
  if (result !== undefined) return result;

  // Is this really an error or just reflexing zero load demand requirement
  // socket.emit('errorLog', {
  //   type: 'calculation',
  //   message: `getMaxScenarioMarketLoad - cannot get MaxScenarioMarketLoad for asset:${asset.assetID} - ${asset.label}, marketID: ${marketID}, scenarioID: ${scenarioID}`
  // });
  return 0;
};

/** Maximum load for an asset that can be generated in a given scenario */
export const getMaxScenarioLoad = (asset: IAsset | IAssetType, scenarioID: number) => {
  const assetID = 'assetID' in asset ? asset.assetID : undefined;

  const result = asset.typeScenarioLimits.find(sml => sml.scenarioID === scenarioID)?.energyMarketLimit;
  if (result !== undefined) return result;

  socket.emit('errorLog', {
    type: 'calculation',
    message: `getMaxScenarioLoad - cannot get MaxScenarioLoad for asset:${assetID || 'NA'} - ${asset.label}, scenarioID: ${scenarioID}`
  });
  return 0;
};

/** Maximum ammount that can be supplied to an asset  */
export const getRawDemand = (asset: IAsset, scenarioID: number, demandType: 'electricity' | 'hydrogen') => {
  const scenarioLimit = asset.typeScenarioLimits.find(sml => sml.scenarioID === scenarioID);
  if (scenarioLimit === undefined) {
    socket.emit('errorLog', {
      type: 'calculation',
      message: `getRawDemand - cannot get rawData for asset:${asset.assetID} - ${asset.label}, scenarioID: ${scenarioID}`
    });
    return 0;
  }

  const rawDemand = (demandType === 'electricity' ? scenarioLimit.energyMarketLimit : scenarioLimit.hydrogenMarketLimit) || 0;
  return rawDemand;
};

/**  Minimum that can be supplied to an asset without penalty */
export const getMinDemand = (asset: IAsset, scenarioID: number, demandType: 'electricity' | 'hydrogen') => {
  const interuptible = (demandType === 'electricity' ? asset.electricityInterruptible : asset.hydrogenInterruptible) || 0;
  const minDemand = getRawDemand(asset, scenarioID, demandType) - interuptible;
  if (demandType === 'hydrogen') {
  }
  return minDemand;
};

/** Customer supply for market 1 = how much the team decide to supply to the customer */
export const getAssetMarketRoundLoad = (marketID: Market['marketID'], roundID: IGameProgress['roundID'], asset: IAsset) =>
  asset.load.find(l => l.marketID === marketID && l.roundID === roundID)?.load || 0;

/** How much the team decide to supply to the customer for an asset /round */
export const getAssetEnergyMarketRoundLoad = (roundID: IGameProgress['roundID'], asset: IAsset | IAssetType) => {
  if (!('load' in asset)) return 0;

  const result = getAssetMarketRoundLoad(1, roundID, asset) + getAssetMarketRoundLoad(2, roundID, asset);
  console.log('market1', getAssetMarketRoundLoad(1, roundID, asset));
  return result;
};

const loadTotalAcc = {
  subsidyIncome: 0, // abs(units) * subsidyIncome
  genCost: 0, // abs(units) * runningCost + fixedCost
  netSupplyIncome: 0, // units * electricityRunningTariff - fixedCost
  carbonIntensity: 0, //
  totalLoad: 0 // units
};

/** Totals from asset loads within a market */
export const getAssetMarketLoadTotals = (loads: IAsset['load'], market: Market, asset: IAsset) => {
  const result = loads.reduce(
    // should only be 1 valid load per market per asset
    (acc, curr) => {
      if (curr.marketID !== market.marketID) return acc;

      const netSupplyIncome = (market.type === 'hydrogenMarket' ? asset.hydrogenRunningTariff : asset.electricityRunningTariff) || 0;

      acc.totalLoad += curr.load;
      acc.subsidyIncome += Math.abs(curr.load) * (asset.subsidyIncome || 0);
      acc.genCost += Math.abs(curr.load) * (asset.runningCost || 0);
      acc.netSupplyIncome += Math.abs(curr.load) * netSupplyIncome;
      acc.carbonIntensity += round(curr.load * (asset.carbonIntensity || 0), 2);

      return acc;
    },
    {
      marketID: market.marketID,
      ...loadTotalAcc
    }
  );

  return result;
};

/** Load totals from assets within a market, round and scenario */
export const getLoadTotalsFromAssets = (
  assets: IAsset[],
  market: Market,
  roundID: IGameProgress['roundID'],
  scenarioID: IGameProgress['scenarioID']
) => {
  return assets.reduce(
    (acc, asset) => {
      const loadsToInspect = asset.load.filter(l => l.roundID === roundID);
      const loadTotalsForMarket = getAssetMarketLoadTotals(loadsToInspect, market, asset);

      acc.subsidyIncome += loadTotalsForMarket.subsidyIncome; // abs(units) * subsidyIncome
      acc.genCost += loadTotalsForMarket.genCost; // abs(units) * runningCost + fixedCost
      acc.netSupplyIncome += loadTotalsForMarket.netSupplyIncome; // units * electricityRunningTariff - fixedCost
      acc.totalLoad += loadTotalsForMarket.totalLoad; // units
      acc.loadDemand += getMaxScenarioMarketLoad(asset, market.marketID, scenarioID);

      if (asset.electricityLoadType === 'generator') acc.carbonIntensity += loadTotalsForMarket.carbonIntensity;
      if (asset.electricityLoadType === 'customer')
        acc.carbonIntensity += (asset.carbonIntensity || 0) * getMaxScenarioMarketLoad(asset, market.marketID, scenarioID);

      return acc;
    },
    {
      market,
      ...loadTotalAcc,
      loadDemand: 0
    }
  );
};

interface IGetMarketTotals {
  markets: Market[];
  assets: IAsset[];
  scenarioID: number;
  roundID: number;
}
/** Array of asset load totals for each market */
export const getMarketTotals = ({ markets, assets, scenarioID, roundID }: IGetMarketTotals) => {
  const result = markets.map(market => getLoadTotalsFromAssets(assets, market, roundID, scenarioID));

  return result;
};

export const getMarket = (markets: Market[], typeOrName: Market['type'] | Market['name']) => {
  const result = markets.find(market => market.type === typeOrName || market.name === typeOrName);
  if (result) return result;

  socket.emit('errorLog', {
    type: 'calculation',
    message: `getMarket - cannot get market for type/name ${typeOrName}`
  });
  throw Error(`getMarket - cannot get market for type/name ${typeOrName}`);
};

export interface IGetColorToDisplay {
  value: number;
  greenLimit: number;
  redLimit: number;
}
export const getColorToDisplay = (props: IGetColorToDisplay) => {
  const { value, greenLimit, redLimit } = props;
  const red = '#d40000';
  const orange = 'orange';
  const green = 'rgb(0, 126, 0)';

  const defaultColor = {
    backgroundColor: 'green',
    color: 'white'
  };
  if (greenLimit < redLimit) {
    if (value >= redLimit) return { ...defaultColor, backgroundColor: red };
    if (value > greenLimit)
      return {
        backgroundColor: orange,
        color: 'black'
      };
    return { ...defaultColor, backgroundColor: green }; // green
  }
  if (value <= redLimit) return { ...defaultColor, backgroundColor: red };
  if (value < greenLimit)
    return {
      backgroundColor: orange,
      color: 'black'
    };
  return { ...defaultColor, backgroundColor: green }; // green
};
