import { IAsset, IAssetType, IGameProgress, Market } from '@powertrader/schema';
import { memo, useMemo } from 'react';
import { getMaxScenarioLoad } from '../../calculations/getFunctions';
import styles from './Asset.module.css';
import { AssetLoadSelect } from './AssetLoadSelect';

interface IAssetControls {
  asset: IAsset | IAssetType;
  markets: Market[];
  demo: boolean;
  scenarioID: IGameProgress['scenarioID'];

  displayMaxMinLoad: boolean;
  disabled: boolean | undefined;
}
export const AssetControls = memo(({ asset, markets, demo, scenarioID, disabled, displayMaxMinLoad }: IAssetControls) => {
  const assetID = 'assetID' in asset ? asset.assetID : undefined;

  const scenarioTypeLimit = useMemo(() => getMaxScenarioLoad(asset, scenarioID), [asset, scenarioID]);

  const hydrogenOnly = asset.electricityLoadType === null && asset.hydrogenLoadType;
  return (
    <div className={styles.controls}>
      <table>
        {markets.map(market => {
          if (hydrogenOnly && market.type !== 'hydrogenMarket') return null;

          return (
            <tbody key={`${assetID}${market.marketID}`}>
              <AssetLoadSelect
                market={market}
                asset={asset}
                demo={demo}
                scenarioTypeLimit={scenarioTypeLimit}
                scenarioID={scenarioID}
                disabled={disabled}
              />
            </tbody>
          );
        })}
      </table>
      {displayMaxMinLoad && !hydrogenOnly && (
        <div className={styles.maxCombinedLoad}>
          <h5>
            {asset.electricityLoadType === 'generator' ? scenarioTypeLimit : scenarioTypeLimit - (asset.electricityInterruptible ?? 0)}
            GWh
          </h5>
          <p>{asset.electricityLoadType === 'generator' ? 'Max' : 'Min'} Daily Load</p>
        </div>
      )}
    </div>
  );
});
