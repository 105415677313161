import { useContext, memo } from 'react';

import { round } from 'lodash';
import { ITeamScore, ISharePriceFactors } from '@powertrader/schema';
import { setupDataContext } from '../../context';

import styles from './SharePriceTable.module.css';

interface ISharePriceTable {
  teamScores: ITeamScore[];
  sharePriceFactors: ISharePriceFactors[];
}

export const SharePriceTable = memo(({ teamScores, sharePriceFactors }: ISharePriceTable) => {
  const { teams } = useContext(setupDataContext);

  const sortedTeams = teams.filter(t => t.type === 'PLAYER');

  return (
    <div className={styles.reviewPageContainer}>
      <h3>Share Price Percentage Growth Breakdown</h3>
      <table className={styles.balanceTable}>
        <thead>
          <tr>
            <th />
            {sortedTeams.map(t => (
              <th>{t.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sharePriceFactors.map(factor => (
            <tr>
              <td style={{ backgroundColor: factor.color }}>{factor.displayLabel}</td>
              {sortedTeams.map(t => {
                const value = round((teamScores.find(ts => ts.teamID === t.teamID)?.sharePrice?.[factor.variableName] || 0) * 100) || 0;
                return (
                  <td
                    key={t.teamID}
                    style={value > 0 ? { color: 'green', fontWeight: 'bold' } : value < 0 ? { color: 'red', fontWeight: 'bold' } : {}}>
                    {value}
                  </td>
                );
              })}
            </tr>
          ))}
          <tr>
            <td style={{ backgroundColor: 'green' }}>Shareholders Sentiment</td>
            {teamScores.map((t, i) => {
              const value = round((teamScores.find(ts => ts.teamID === t.teamID)?.sharePrice?.shareholderSentiment || 0) * 100) || 0;
              return (
                <td key={i} style={value > 0 ? { color: 'green', fontWeight: 'bold' } : value < 0 ? { color: 'red', fontWeight: 'bold' } : {}}>
                  {value}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
});
